@import '../../../../../style/variables.scss';
@import '../../../../../style/mixin/panel-bg-img.scss';

.webhooks {
  &__table {
    margin-bottom: 0px;

    tr {
      max-height: 100%;

      td {
        padding: $spacing-medium;
        &:last-child {
          justify-content: flex-start;
        }
      }
    }
  }

  &__test-panel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      color: $billsby-black;
      font-size: $font-size-regular;
    }

    &__button {
      width: 90px;
      font-size: $font-size-medium-regular;
   
      &--sent, &--sent:focus {
        background-color: #caf3bc;
        pointer-events: none;
        color: $billsby-green;
        border-color: $billsby-green;
      }
    }
  }

}