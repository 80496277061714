@import '../../../../style/variables.scss';
@import '../../../../style/mixin/panel-bg-img.scss';

.currencies {
  &__heading {
    @include panel-bg-img("../../../../images/currencies.svg", right);
    background-size: 204px;
    background-position-x: 102%;
    background-position-y: center;
    & > .cc-text {
      width: 84%;
    }
  }

  &__banner-ach {
    margin-top: -#{$spacing-medium};
    margin-left: -#{$spacing-default};
    margin-right: -#{$spacing-default};
  }

  &__base {
    &__content {
      display: flex;
      align-items: center;
      .cc-text {
        margin-right: $spacing-default;
        width: 50px;
      }

      .label {
        &__cc {
          width: 180px;
        }
        &__ach {
          width: 180px;
          padding-left: $spacing-medium;
        }
      }
      .dropdown__container {
        width: 100%;
      }

    }

    #save-gateway-link {
      width: 80%;
      margin-right: $spacing-small;
    }
    #cancel-gateway-link {
      width: calc(20% - #{$spacing-small});
    }
  }



  &__additional {
    position: relative;
    
    &--background {
      @include panel-bg-img("../../../../images/currencies-additional.svg", bottom);
      height: 500px;
      background-size: 550px !important;
    }

    &-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 92px;
      background-color: $billsby-super-light-gray;
      padding: $spacing-default;
      .cc-text {
        &:nth-child(1) {
          
          font-size: $font-size-large;
          letter-spacing: -0.5px;
          margin-bottom: $spacing-small;
          color: $billsby-black; 
        }
      }
    }

    #save-gateway-links {
      width: 80%;
      margin-right: $spacing-small;
    }
    #cancel-gateway-links {
      width: calc(20% - #{$spacing-small});
    }

  }
}