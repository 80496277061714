@import '../../style/variables.scss';
@import '../../style/mixin/breakpoint.scss';
@import '../../style/mixin/border-radius.scss';
@import '../../style/mixin/transition.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: $HEADER_HEIGHT;
  background: $billsby-black;
  font-size: $font-size-small;
  color: $white;
  display: flex;
  align-items: center;

  justify-content: space-between;

  &__logo {
    display: flex;
    align-items: center;
    img {
      width: 90px;
      height: 28px;
      margin-left: $spacing-default;
      cursor: pointer;
    }
  }

  &__info {
    margin-right: $spacing-default;
    display: flex;

    div.header__box {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 36px;
      height: 36px;
      @include border-radius($border-blue);
      border-style: solid;
      border-width: 1px;
      background-color: $bg-blue;
      font-size: 15px;
      padding: 0 $spacing-small;
      cursor: pointer;
      margin-right: $spacing-small;
      color: $white;
      opacity: .6;

      @include breakpoint($mobile-width) {
        min-width: 164px;
        height: 36px;
        i {
          margin-right: $spacing-tiny;
        }
      }

      span {
        // hide text in mobile
        display: none;
        @include breakpoint($mobile-width) {
          display: block;
        }
      }

      &:last-child {
        margin-right: 0;
      }

    }

  }

  &__hamburger {
    &--closed {
      &::before {
        position: static;
        content: '\f0c9';
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        color: $white;
        font-size: $font-size-large;
        margin-left: $spacing-small;
        cursor: pointer;
      }
    }
    &--open {
      &::before {
        position: static;
        content: '\f00d';
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        color: $white;
        font-size: $font-size-huge;
        margin-left: $spacing-small;
        cursor: pointer;
      }
    }
  
    @include breakpoint($mobile-width) {
      display: none;
    }
  }

  $HEADER: &;
  %btn-placeholder {
    height: 36px;
    font-family: $billsby-font-family;
    font-size: 15px;
    letter-spacing: normal;
    text-transform: initial;
    opacity: 0.6;
    @include border-radius($border-blue);
    background-color: $bg-blue;
    margin-right: $spacing-default;
  }

  &__logout.btn-basic {
    @extend %btn-placeholder;
  }

  &__signup.btn-basic {
    @extend %btn-placeholder;
    
    @include breakpoint(0, $mobile-width) {
      width: 200px;
    }
    @include breakpoint($mobile-width) {
      width: 256px;
    }

    &:hover {
      background-color: lighten($billsby-black, 3%);
    }
    &::after {
      content: '\f061';
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      margin-left: $spacing-small;
    }
  }
}


