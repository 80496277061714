@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';
@import '../../style/mixin/box-shadow.scss';

.transactions-welcome {
  &__greeting {
    height: 500px;
    @include panel-bg-img('../../images/customers/card_details_1.svg', center);
    background-size: 687px 430px !important;
    background-position: center;
    display: flex;
    flex-direction: column;

    &-question {
      margin: auto -20px -20px -20px;
      background-color: $billsby-super-light-gray;
      border-top-left-radius: 0%;
      border-top-right-radius: 0%;
      @include box-shadow(none);
    }
  }
}