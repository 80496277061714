@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';

.refund__title {
  &__panel {
    @include panel-bg-img("../../images/customers/billsbot_and_man_credit_note.svg", right);
    background-size: 255px;
    background-position-y: -15px;
    background-position-x: 107%;
    
    &__text {
      width: 75%;
    }
  }
}