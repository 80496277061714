@import '../../../../../style/variables.scss';

.edit-retention-step {
  &__container {
    background-color: $modal-overlay;
    display: flex;
    justify-content: center;
    margin-bottom: $spacing-default;

    .checkout-preview-new__panel {
      display: block;
    }
  }

  &__reasons-to-stay {
    &__list {
      flex-direction: column;
      justify-content: flex-start;

      &__item {
        display: flex;
        align-items: center;
        padding: $spacing-tiny;

        i {
          margin-right: $spacing-medium;
          font-size: 35px;
        }
      }
    }
  }

  &__time-remaining {
    &__preview {
      display: flex !important;
      align-items: center;

      .content-panel {
        border: 1px solid;
        margin-right: $spacing-medium;
        padding: 0px;
        width: 45px;
        height: 45px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__plan-change {
    &__list {
      &__item {
        min-height: 104px;
        display: flex !important;
        align-items: center;
      }
    }

    .dropdown__container {
      pointer-events: none;
      .custom-select {
        &__control {
          border-color: $billsby-black;
          height: 30px;
          min-height: 30px;

          svg {
            fill: $billsby-black;
          }
        }

        &__indicators {
          height: 100%;
        }
      }
    }
  }

  &__help-interrupt {
    &__contents {
      text-align: center;
    }
  }

  &__offer-discount {
    &__input-label.cc-text {
      color: $billsby-black;
      margin-bottom: $spacing-small;
    }
    &__dropdown {
      text-transform: capitalize;
    }

    &__product-name.cc-text {
      font-size: $font-size-tiny;
      color: $billsby-muted-gray;
    }

    &__plan-name.cc-text {
      font-size: $font-size-regular;
      color: $billsby-orange;
    }

    &__previous-price.cc-text {
      text-decoration: line-through;
      display: inline;
    }

    &__new-price.cc-text {
      display: inline;
    }

    &__description.cc-text {
      font-size: $font-size-small;
      color: $billsby-green;
    }

    &__percentage-symbol {
      font-size: $font-size-regular;
      padding-top: $spacing-small;
    }

    .content-panel {
      text-align: center;
      min-height: 285px;

      img {
        padding-top: 30px;
        max-width: 200px;
        width: auto;
      }
    }

    &__config {
      .file-upload__logo {
        &::before {
          content: '\f1c5';
        }
      }

      .switch__container {
        input:checked + .switch__slider {
          background-color: $billsby-black;
        }

        input:not(:checked) + .switch__slider {
          background-color: $billsby-muted-gray;
        }
      }
    }
  }

  &__switch {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-default;

    .switch__container input:not(:checked) + .switch__slider {
      background-color: $billsby-muted-gray;
    }
  }

  &__phone {
    position: relative;
  }

  .billsby-phone-input {
    margin-bottom: $spacing-default;
  }

  .textarea {
    height: 80px;
    margin-bottom: $spacing-default;
    text-overflow: ellipsis;
  }

  .cc-input {
    margin-bottom: $spacing-default;

    &.edit-retention-step__offer-discount__input {
      margin-bottom: 0;
    }

    &__field {
      text-overflow: ellipsis;
    }
  }
}
