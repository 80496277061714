@import '../../../../style/variables';

.taxjar-complete-integration {
  &__titlepanel {
    position: relative;
  }

  &__content {
    margin-bottom: $spacing-medium;
  }

  &__start-free-trial {
    position: absolute;
    top: $spacing-default;
    right: $spacing-default;
    width: 140px;
    height: 36px;
    line-height: 0px;
  }
}