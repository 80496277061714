@import '../../../../style/variables.scss';
@import '../../../../style/mixin/panel-bg-img.scss';

.api-keys {
  &__panel {
    @include panel-bg-img('../../../../images/api-race.svg', right);
    background-size: 204px;
    background-position-x: 102%;
    background-position-y: center;
    
    .cc-text {
      max-width: 80%;
    }
  }

  .tabbed-container {
    box-shadow: inset 0px -10px 0px -8px rgb(216, 216, 216);
    margin-bottom: $spacing-default;
    padding-left: 0px;
  }

  &_description {
    color: $billsby-muted-gray;
  }

  &_display_inline {
    display: inline;
  }

  &_display_bold {
    font-weight: bold;
  }

  &_add_top_margin {
    margin-top: 20px;
  }



}

