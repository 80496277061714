@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.cpanel-arrow {
  &-title {
    cursor: pointer;
    i {
      margin-right: $spacing-small;
    }
  }
  
  .content-panel-title {
    margin-bottom: $spacing-small;
  }

  &--collapsed {
    background-image: none;
    
    .content-panel-title {
      margin-bottom: 0;
    }

    .cpanel-arrow-content {
      margin: 0;
      padding: 0;
      max-height: 0px;
      visibility: hidden;
    }

    .cpanel-arrow-subtitle {
      margin: 0;
      margin-top: $spacing-small;
    }
  }
}