@import '../../../style/variables.scss';

.title-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //height: 150px;
  padding: 30px 0;

  & > *:nth-child(1) {
    
    font-weight: bold;
    letter-spacing: 0;
    font-size: 36px;
    color: $billsby-black;
    text-align: center;
  }
  & > *:nth-child(2) {
    font-size: 17px;
    letter-spacing: 0;
    text-align: center;
  }

  &--margin-top {
    margin-top: -#{$spacing-default};
  }
  &--margin-bottom {
    margin-bottom: #{$spacing-default};
  }
}