@import '../../../../../style/variables.scss';

.dunning-failed-payments {
  .content-panel:nth-child(2) {
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > .cc-text:first-of-type {
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.5px;
        color: $billsby-black;
        font-size: $font-size-large;
        
        margin: 0 0 15px 0;
      }
    }
  }
  #initial-payment-failure-customize-email {
    height: auto;
    padding: 8px 10px;
  }
}