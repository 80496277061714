@import '../../../style/variables';
@import '../../../style/mixin/panel-bg-img';

.one-time-charge {
  &__titlepanel {
    .cc-text {
      width: 70%;
    }
    @include panel-bg-img("../../../images/customers/woman_one_time_charge.svg", right);
    background-size: 255px;
    background-position-y: -15px;
    background-position-x: 107%;
  }

  &__notice-panel {
    border-radius: 4px;
    box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);

    a {
      color: $billsby-black;
    }
  }
}