@import '../../../style/variables';

.element-modifiers {
  display: inline-flex;

  &__edit {
    color: $billsby-black;
    cursor: pointer;
  }

  &__delete {
    margin: 0 0 0 $spacing-small;
    color: $billsby-red;
    cursor: pointer;
  }

  &__lock {
    margin: 0 0 0 $spacing-small;
    color: $billsby-red;
  }
}