@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';

.reports-checkout-self-service {
  &__heading {
    @include panel-bg-img('../../images/reports/reports.svg', right);
    background-size: 185px;
    background-position-y: -17px;
    background-position-x: 100%;
    .cc-text {
      max-width: 70%;
    }
  }
}