@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/panel-bg-img.scss';
@import '../../../style/mixin/breakpoint';

.subscription-add-discount {
  &__title {
    &-panel {
      @include panel-bg-img('../../../images/discounts/discount_giveaway.svg', right);
      background-size: 236px;
      background-position-y: -29px;
      background-position-x: 103%;
    }

    &-text {
      width: 75%;
    }
  }

  &__discount-info {
    margin-top: $spacing-small;
  }

  &__summary, &__summary-loader {
    margin-top: $spacing-small;
  }
}