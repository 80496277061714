@import '../../../style/variables.scss';

.subscription-custom-fields {
  &__table {
    &.cc-table {
      box-shadow: $box-shadow;

      th,
      td {

        &:last-child {
          justify-content: flex-start;
          flex: 0 0 65%;
        }
      }

      th {
        height: 40px;
        .cc-text {
          
          font-size: 11px;
          color: $billsby-black;
        }
        .subscription-custom-fields__button {
          margin-left: auto;
          margin-right: -$spacing-small;
          padding: 3px 18px;
          height: 28px;
        }
      }

      tr {
        max-height: 100%;
      }

      td {
        padding: $spacing-medium $spacing-default;
      }
    }
  }

  &__label {
    align-self: flex-start;
  }

  &__emails {
    display: table-cell !important;

    &__item {
      text-decoration: underline;
      margin-right: 4px;
      white-space: nowrap;
    }

    &__see-more {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .notice-panel {
    border-radius: 4px;
    box-shadow: $box-shadow;
    a {
      color: $billsby-black;
    }
  }
}
