@import '../../style/variables.scss';

.invoices-table {
  display: table;
  width: 100%;
  border-spacing: 0px;

  thead {
    pointer-events: none;
  }

  thead,
  tbody {
    th,
    td {
      text-align: left;
      padding: $spacing-medium;

      &:last-of-type {
        justify-content: flex-start;
        flex-grow: 2.5;
      }

      &:nth-child(1) {
        flex: 0 1 120px;
      }

      &:nth-child(3) {
        flex: 0 1 120px;
      }
    }
  }

  tr {
    td:first-of-type {
      &::before {
        display: none;
      }
    }

    &.expanded {
      padding: 0px;

      & > td {
        flex: 1 1;
      }

      &--smooth {
        min-height: 0px !important;

        tr {
          border-bottom: 0px;
        }
      }
    }

    &:last-child {
      td {
        border-color: $white;
      }
    }

    &.cc-table--expandable__rowExpanded {
      border-bottom-color: $white;

      .invoices-table {
        &__collapsible-transactions {
          &::before {
            content: '\F106';
          }
        }
      }
    }
  }

  &__status-btn-wrapper {
    width: 100%;

    .invoices-table__btn-wrapper {
      margin-left: auto;

      button {
        margin-left: $spacing-small;
      }
    }
  }

  &__info {
    font-size: 9px;
    color: rgb(120, 117, 110);
  }

  &__view-btn {
    margin-left: auto;
  }

  &__status-btn-wrapper {
    display: flex;
    align-items: center;

    a {
      margin-left: $spacing-small;
    }
  }

  &__collapsible-transactions {
    cursor: pointer;

    &::before {
      content: '\F107';
      font-family: 'Font Awesome 5 Pro';
      font-size: $font-size-regular;
      line-height: 1.19;
      color: $billsby-black;
      margin-right: $spacing-tiny;
      cursor: pointer;
    }
  }
}
