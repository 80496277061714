@import '../../../style/mixin/border-radius';
@import '../../../style/variables.scss';

.status-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-medium;
  height: 24px;
  text-transform: uppercase;
  @include border-radius($radius: 14px);
  font-size: $font-size-medium;
  font-weight: $font-weight-medium;
  white-space: nowrap;
  

  i.left {
    margin-right: 5px;
  }

  i.right {
    margin-left: 5px;
  }

  &--red {
    background-color: $billsby-red;
    color: $white;
  }
  &--orange {
    background-color: $billsby-orange;
    color: $white;
  }
  &--light-orange {
    background-color: $white;
    color: $billsby-orange;
    border: 1px solid $billsby-orange;
  }
  &--green {
    background-color: lighten($color: $billsby-green, $amount: 0%);
    color: $white;
  }
  &--light-green {
    background-color: $white;
    color: $billsby-green;
    border: 1px solid $billsby-green;
  }
  &--light-red {
    background-color: $white;
    color: $billsby-red;
    border: 1px solid $billsby-red;
  }
  &--blue {
    background-color: $billsby-black;
    color: $white;
    border: 1px solid $billsby-black;
  }
  &--light-blue {
    background-color: $white;
    color: $billsby-black;
    border: 1px solid $billsby-black;;
  }
}
