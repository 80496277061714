@import "../../../style/variables.scss";
@import "../../../style/mixin/border-radius.scss";
@import "../../../style/mixin/scrollbar.scss";
.log_panel {
  position: relative;
  width: 100%;

  i {
    font-size: $font-size-regular;
  }

  &__title {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-items: flex-end;
    margin: 0 0 12px 0;
    width: 100%;
    align-items: center;

    &__text {
      .cc-text {
        display: inline;
        font-size: 15px;
        align-items: left;
        color: $billsby-black;
        font-weight: $font-weight-bold;

        &.large-icon-text {
          line-height: 25px;
        }

        & > .text-hightlight {
          color: $billsby-black;
        }
      }
    }

    &__icon {
      font-size: $font-size-regular;
      font-weight: $font-weight-bold;
      padding-right: 8px;
      color: $billsby-black;
      .circle-icon {
        margin-right: 0px;
        &.icon-red {
          background-color: $billsby-red;        
        }
        &.icon-green {
          background-color: $billsby-green;        
        }
      }
    }
  }

  &__subtitle {
    font-family: "Roboto";
    color: $billsby-light-gray;
    text-align: left;
    font-size: 13px;

    & > .text-hightlight {
      color: $billsby-black;
    }
  }
}
