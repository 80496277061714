@import "../../../style/variables.scss";
@import "../../../style/mixin/panel-bg-img.scss";

.sandbox_prelive {
  &_panel {
    @include panel-bg-img("../../../images/billsby-rocket2.svg", right);
    background-position-y: bottom;
    background-size: contain;
    background-position-x: 133%;

    &_content {
      max-width: 492px;
    }
  }
}
