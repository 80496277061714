@import '../../../../style/variables';
@import '../../../../style/mixin/border-radius';

.checkout-dropdown {
  .custom-select {
    &__option {
      font-family: $billsby-font-family;
      font-size: $font-size-small;
      background-color: $billsby-super-light-gray;
      color: $billsby-black;
    
      &--is-focused {
        background-color: $billsby-orange;
        color: #fffefc;
      }
    }

    &__single-value {
      font-family: $billsby-font-family;
      font-size: $font-size-small;
      color: $billsby-black;
    }

    &__menu {
      margin-top: 0;
      border-radius: initial;

      &-list {
        padding-top: 0;
        padding-bottom: 0;
      }
    }


    &__control {
      border-radius: initial;
      box-shadow: 0 0 1px -1px rgba(0, 0, 0, 0.5);
      border-color: $billsby-black;
      min-height: 30px;
      &:hover {
        border-color: $billsby-black;
      }

      &--is-focused {
        //border: none;
        border-color: $billsby-black; 
      }

      .custom-select__dropdown-indicator {
        svg {
          display: none;
        }
        &::after {
          content: "\f078";
          color: $billsby-black;
          cursor: pointer;
          position: absolute;
          right: 12px;
          top: 9px;
          font-family: 'Font Awesome 5 Pro';
          font-size: $font-size-small;
        }
      }

      &--menu {
        &-is-open {
          .custom-select__dropdown-indicator {
            &::after {
              content: "\f077";
              cursor: pointer;
              color: $billsby-black;
              position: absolute;
              right: 12px;
              top: 9px;
              font-family: 'Font Awesome 5 Pro';
              font-size: $font-size-small;
              //color: $billsby-super-light-gray;
            }
          }
        }
      }

    }


    &__indicator-separator {
      display: none;
    }
  }
}