@import "../../../style/variables.scss";
@import "../../../style/mixin/panel-bg-img.scss";

.customer_info {
  width: 100%;
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  
  &__details {
    float: left;
    min-width: 200px;
    margin-left: $spacing-medium;
    & > .name {
      & > .cc-text {
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.5px;
        color: $billsby-orange;
        font-size: $font-size-large;
        font-family: Roboto Slab;
        margin: 0 0 13px 0;
      }
    }

    & > .info {
      margin-bottom: 11px;
      & > i {
        width: 13px;
        height: 13px;
        color: $billsby-black;
        font-size: 13px;
        font-weight: solid;
        letter-spacing: 0px;
        margin-right: 13px;
      }
      & > .cc-text {
        display: inline;
        color: rgb(37, 36, 34);
        font-size: $font-size-medium;
        font-weight: normal;
        letter-spacing: 0px;
        
        &.email {
          text-decoration: underline;
        }

        &.sub-text {
          margin-left: 25px;
          display:block;
          color: $billsby-muted-gray;
          font-size: 9px;
          font-family: $billsby-font-family;
          font-weight: normal;
          letter-spacing: 0px;
        }
      }
    }

    .info {
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &__address {

    & > .address-title.cc-text {
      height: 18px;
      color: $billsby-black;
      font-size: $font-size-medium;
      font-family: $billsby-font-family;
      font-weight: normal;
      letter-spacing: 0px;
      margin: 0 0 13px 0;
    }

    & > .address.cc-text {
      color: rgb(37, 36, 34);
      font-size: $font-size-medium;
      font-weight: normal;
      letter-spacing: 0px;
      line-height: 18px;
    }
  }
}
