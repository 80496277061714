@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Slab:wght@400;700&display=swap');
@import './variables.scss';
@import './stripe.scss';
@import './craftyClicks.scss';
@import './recharts.scss';
@import '../style/mixin/breakpoint.scss';
@import '../style/mixin/font-styles.scss';

html, body {
  margin: 0;
  padding: 0;

  @include breakpoint(0, $mobile-width) {
    min-height: 100vh;
  }
  
  @include breakpoint($mobile-width) {
    height: 100%;
  }
}

body {
  font-size: $font-size-medium;
  font-weight: $font-weight-regular;
  color: $billsby-black;
  background-color: $billsby-super-light-gray;
  font-family: $billsby-font-family;
}

#root,
#root > div {
  height: inherit;
}

* {
  box-sizing: border-box;
}

a {
  font-size: $font-size-medium;
  color: $billsby-black;
  outline: none;
}

button {
  outline: none;
}

div {
  outline: none;
}

/*
  Use the atomic css philosophy of libraries such as Tachyons to create universal override styles. For example, when creating a prop which removes a component's bottom margin, 
  instead of creating a style declaration in every component, give them the same style.
*/
.mb0 {
  margin-bottom: 0;
}

/*
  hide arrows when using an input number
*/
input[type='number'] {
  -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

span.divider {
  height: 1px;
  display: block;
  background-color: $billsby-super-light-gray;

  &--vertical {
    height: $HEADER_HEIGHT;
    width: 1px;
  }
}

.text-blue {
  color: $billsby-black !important;
}

.text-orange {
  color: $billsby-orange;
}

.capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: $font-weight-bold;
}

.hidden {
  display: none;
}

h6 {
  @include heading-font-style();
}

@font-face {
  font-family: "Chantal Medium";
  src: local("Chantal Medium"), url("./fonts/chantal_medium.ttf") format("truetype");
  font-weight: normal;
}

