@import '../../../style/variables';

.add-tax-rates {
  position: relative;

  .cc-input {
    display: block;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 46px;
    padding: $spacing-medium $spacing-default;
    margin-top: $spacing-medium;

    i {
      margin-left: auto;
      color: $billsby-red;
      cursor: pointer;
    }

    .divider {
      background-color: $billsby-orange;
      margin: 0px 15px;
       height: 30px;
    }
  }

  &__floating-button {
    position: absolute;
    top: $spacing-default;
    right: $spacing-default
  }
}