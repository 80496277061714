@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/panel-bg-img.scss';
@import '../../../style/mixin/breakpoint';

.ecself-service {

  &__welcome {
    background-color: $white;
    @include breakpoint(1040px) {
      @include panel-bg-img('../../../images/embed_codes/account_management.svg', right);
      background-size: 185px;
      background-position-y: -17px;
      background-position-x: 100%;
    }
  }
  
  &__change-plan {
    background-color: $white;
    @include breakpoint(1040px) {
      @include panel-bg-img('../../../images/embed_codes/man_change_plan.svg', right);
      background-size: 185px;
      background-position-y: -17px;
      background-position-x: 100%;
    }
  }

  &__basic {        
    &__subtitle {      
      font-size: 15px;
      color: $billsby-black;
    }

    &__code-main {
      color: $background-grey;
      font-size: 13px;
      letter-spacing: normal;      
      width: 90%;
    }

    &__variable {
      color: $billsby-orange;
    }

    &__dropdown {
      margin-bottom: $spacing-default;
    }

    &__dropdown-options {
      display:inline;
    }
  }

  #basic-copy-8, #basic-copy-9 {
    .eccheckout__basic__code-main {
      div:not(:first-of-type), div:not(:last-of-type) {
        margin-left: calc(2 *#{$spacing-default});
      }
      div:nth-child(2), div:nth-last-child(2) {
        margin-left: $spacing-default;
      }
      div:first-of-type, div:last-of-type {
        margin-left: 0;
      }
    }
  }
}