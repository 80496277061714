@import "../../../../style/variables.scss";

.sca {
  &__warning_panel {
    padding-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    & .notice-panel.notice-panel--warning.notice-panel--modal {
      margin-bottom: 0;
    }
  }

  &__setup-success {
    height: 90px;
    background: $billsby-green;

    & > div.navigation-alert__wrapper > #navigation-alert-btn {
      text-transform: initial;
      padding: $spacing-small;
      color: $milk-white;
      letter-spacing: normal;
      background-color: rgba(255, 254, 252, 0.1);
    }
  }

  &__error {
    & > div.notice-panel__icon > i.fas.fa-exclamation-circle {
      font-size: $font-size-huge;
    }
    & > p {
      font-weight: bold;
      color: $billsby-red;
    }
  }

  &__help-text {
    &.cc-text {
      &.text-inline {
        display: inline;
      }

      &.link-text {
        text-decoration: underline;
        cursor: pointer;
      }

      &.text-title {
        color: $billsby-black;
        margin-bottom: 0;
      }
    }

    &__container {
      margin-top: $spacing-default;
    }
  }

  &__payment-gateway {
    &__container {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }

    &__title {
      font-size: 15px;
      color: $billsby-black;
    }

    &__subTitle {
      font-size: $font-size-medium;
      color: $billsby-black;
    }

    i {
      font-size: $font-size-huge;
      cursor: pointer;
    }

    & > *:nth-child(1),
    & > *:nth-child(2) {
      display: flex;
      align-items: center;
      & > *:first-child {
        margin-right: $spacing-default;
      }
    }
  }

  &__gateway {
    &.cpanel--collapsed {
      max-height: 77px;
    }

    &.cpanel--collapsed,
    &.initiate-transaction {
      & > div > div.step-cpanel__title > div.step-cpanel__title__text {
        &
          > div.cc-text.mb0.step-cpanel__title__text--fullwidth.cc-text--weight-normal {
          width: 100%;
        }
      }
    }
  }

  &__gateway.initiate-transaction.step-cpanel {
    & div > div.step-cpanel__helpmenu > div.step-cpanel__helpmenu__title {
      & > div {
        display: inline-block;
        & > i {
          display: inline-block;
          margin-right: $spacing-small;
        }
      }
    }
  }

  &_payment-gateways__dunning-navigation {
    margin-bottom: $spacing-default;
  }

  &__payment-gateways__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

    #sca-continue-btn {
      width: 83%;
    }

    #sca-cancel-btn {
      width: 16.1%;
    }
  }

  &__text {
    &.text-inline {
      display: inline;
    }

    &.text-link {
      color: $billsby-black;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &-container {
    position: relative;
    &__overlay {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 15;
      justify-content: center;
      align-items: center;
    }

    &__modal {
      max-width: 700px;
      overflow: hidden;

      .notice-panel__content {
        font-size: $font-size-large;
    
        color: $billsby-orange;
        padding-top: 0px;
      }

      .content-panel-title {
        color: $billsby-orange;
      }
      .cc-checkbox {
        justify-content: center;
      }
    }

    &__footer {
      display: flex;
      #sca-cancel-btn-modal {
        width: 20%;
        margin-left: $spacing-small;
      }

    }

    &__checkbox {
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }
}
