@import '../../../style/variables';

.cc-text {
  margin: 0 0 $spacing-default 0;
  color: $billsby-black;
  line-height: 1.38;
  font-weight: $font-weight-regular;

  &--xsmall {
    font-size: $font-size-tiny;
  }
  &--small {
    font-size: $font-size-small;
  }
  &--normal {
    font-size: $font-size-medium;
  }
  &--large {
    font-size: $font-size-regular;
  }
  &--xlarge {
    font-size: $font-size-huge;
  }

  &--weight {
    &-bold {
      font-weight: bold;
    }

    &-normal {
      font-weight: normal;
    }
  }
}