@import '../../../style/variables';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/box-shadow.scss';

.content-panel {
  @include border-radius(null);
  @include box-shadow($box-shadow);
  background-color: $white;
  padding: $spacing-default;
  margin-bottom: $spacing-default;
  position: relative;

  &__ribbon {
    background-color: $billsby-orange;
    display: block;
    position: absolute;
    color: $white;
    padding: $spacing-tiny;

    font-size: $font-size-small;
    right: -$spacing-medium;
    top: $spacing-small;
    z-index: 1;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: $spacing-medium $spacing-medium 0 0;
      bottom: -$spacing-medium;
      right: 0px;
      border-color: $light-orange transparent transparent transparent;
      z-index: -1;
    }
  }

  &--dotted {
    @include border-radius($billsby-super-light-gray, 4px, true);
  }

  &--noborder {
    border: none;
  }

  &--clear {
    background-color: transparent;
  }

  &--warning {
    @include border-radius($billsby-red);
    background-color: $white;
    padding: $spacing-default;
    margin-bottom: $spacing-default;
    overflow: hidden;

    .content-panel-title {
      color: $billsby-red;
      margin-bottom: $spacing-small;
    }
  }

  &--success {
    @include border-radius($billsby-green);
    background-color: lighten($color: $billsby-green, $amount: 50%);
    padding: $spacing-default;
    margin-bottom: $spacing-default;
    overflow: hidden;

    .content-panel-title {
      color: $billsby-green;
    }
  }

  &-title {
    font-style: normal;
    font-weight: $font-weight-medium;
    letter-spacing: -0.5px;
    color: $billsby-black;
    font-size: $font-size-large;

    margin: 0 0 15px 0;
  }
}
