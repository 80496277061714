@import "../../../style/variables";
@import "../../../style/mixin/panel-bg-img.scss";
@import "../../../style/mixin/box-shadow.scss";

.create-coupon {
  &__heading {
    @include panel-bg-img("../../../images/discounts/giving_woman_presents _1.svg", right);
    background-size: 255px;
    background-position-y: -25px;
    background-position-x: 111%;
    
    @include breakpoint(1450px, 1549px) {
      background-position-x: 110.5%;
    }

    @include breakpoint(1550px, 1649px) {
      background-position-x: 110%;
    }

    @include breakpoint(1650px) {
      background-position-x: 109%;
    }

    &-text {
      width: 80%;
    }
  }

  &__select-panel {
    &--single-use {
      @include panel-bg-img("../../../images/discounts/single_use_coupon.svg", right);
      background-size: 280px;
      background-position-x: calc(100% + 70px);
    }

    &--multi-use {
      @include panel-bg-img("../../../images/discounts/multi_use_coupon.svg", right);
      background-size: 260px;
      background-position-x: calc(100% + 30px);
    }
    display: flex;
    justify-content: space-between;
    .content-panel {
      width: calc(50% - #{$spacing-small});
      .cc-text {
        margin-right: 35%;
      }  
    }
  }

  &__text-disabled.cc-form-label label {
    color: $billsby-muted-gray;
  }

  &__generate-more {
    width: 167px;
  }

  &__generate-input {
    .cc-input__error-container  {
      top: 8px;
    }
  }

  &__warning {
    text-align: center;
    margin-top: $spacing-default;
    font-size: $font-size-medium-regular;
  }

  &__nav-title.cc-text  {
    display: inline;
  }

  &__nav-title-bold.cc-text  {
    display: inline;
    font-weight: bold;
  }

  &__basic-form {
    margin-left: 38px;

    &:last-child.cc-form-group {
      margin-bottom: 0;
    }

    &__switch .switch__off, .switch__container input:not(:checked) + .switch__slider {
      background-color: $billsby-muted-gray;
    }
  }

  &__select-coupon-type__panel {
    & .cc-checkbox .cc-checkbox-general__icon {
      font-size: 24px;
    }
  }  

  &__coupon-type {
    &-title {
      display: flex;
      align-items: center;
      & > *:nth-child(1) {
        margin-right: $spacing-default;
      }
      & > *:nth-child(2) {
        display: flex;
        align-items: center;
        .border {
          border: 1px solid $billsby-orange;
          height: 30px;
          margin-right: $spacing-small;
        }
        .cc-text {
          font-family: $billsby-font-family;
          font-size: $font-size-medium-regular;
          letter-spacing: 0px;
        }
      }
    }
  }
}
