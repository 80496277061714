@import '../../style/variables';
@import '../../style/mixin/border-radius';
@import '../../style/mixin/panel-bg-img';
@import '../../style/mixin/breakpoint.scss';

.quiz {
  .title-section {
    p:nth-child(2) {
      @include breakpoint($mobile-width) {
        white-space: nowrap;
      }
    }
  }

  .content-panel-title {
    color: $billsby-black;
    font-weight: $font-weight-bold;
  }

  button {
    background: $white;
    color: $billsby-black;
    text-transform: initial;
    margin-bottom: $spacing-small;
    letter-spacing: normal;
    font-family: $billsby-font-family;
    @include border-radius($billsby-black);
  }

  &__link {
    font-size: $font-size-medium-regular;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }

  .custom-select__indicators {
    //hide select arrow
    display: none !important;
  }

  &-step1 {

  }
  

  &-step2 {
    position: relative;
    .content-panel {
      height: 400px;
      @include panel-bg-img("../../images/existing-provider.svg", bottom);
      background-size: auto 280px !important;
    }

    &__other {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 267px;
      height: 30px;
      border-radius: 15px;
      box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
      background-color: $white;
      & .cc-text:first-child {
        margin-right: $spacing-small;
      }
    }
  }

  &-step3 {

  }
  &-step4 {
    
  }
  &-step5 {
    /*&__slider {
      .rc-slider-dot, .rc-slider-mark-text {
        &:nth-child(1) {
          left: 20% !important;
        }
        &:nth-child(2) {
          left: 40% !important;
        }
        &:nth-child(3) {
          left: 60% !important;
        }
        &:nth-child(4) {
          left: 80% !important;
        }
      }
      .rc-slider-handle {
        left: 20% !important;
      }
      .rc-slider-track {
        width: 20% !important;
      }
    }*/
  }

  &-step6 {

  }

  &-step7 {
    
  }

  &-step8 {
    .content-panel {
      height: 400px;
      @include panel-bg-img("../../images/industry.svg", bottom);
      background-position-y: 160px;
      background-size: auto 390px !important;
    }

    &__select {
      .custom-select__menu {
        //width: 176px;
      }
    }
  }

  &-step9 {
    .content-panel {
      height: 400px;
      @include panel-bg-img("../../images/tax-handshake.svg", bottom);
      background-position-y: 75px;
      background-size: auto 450px !important;
    }
  }

}