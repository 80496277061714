@import "../../../../../style/variables.scss";


.invoice-preview {
  &-container {
    width: 100%;
    background-color: $tab-background-grey;
    padding-top: $spacing-default;
    padding-bottom: $spacing-default;
    
    &__content {
      position: relative;
      width: 800px;
      border-radius: 4px;
      overflow: hidden;
      background-clip: padding-box;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__container {
    height: auto;
  }

}