@import "../../../../../style/variables.scss";

.base-email-template {
  &__button {
    border-radius: 0px;
    & > span {
      margin-left: $spacing-default;
      margin-right: $spacing-default;
    }

    &-left {
      text-align: left;
    }

    :after {
      font-family: "Font Awesome 5 Pro";
      content: " \f061";
    }
  }

  &__body-1 {
    display: inline;
    & > a {
      display: inline;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__panel {
    margin-right: $spacing-small;
    background-color: $white;
    height: 100%;
    padding: 0px;

    &-header-orange > .content-panel-title {
      color: $billsby-orange;
    }

    &-header-green > .content-panel-title {
      color: $billsby-green;
    }

    & > .email-preview__container {
      & > .email-preview__content {
        width: 550px;
        & > .email-preview__details {
          padding-bottom: 0px;
        }
      }
    }
  }

  &__footer {
    &__text {
      padding: $spacing-default;
      font-size: $font-size-medium-regular;

      & > span {
        padding: 0;
        font-size: $font-size-medium-regular;
      }
      & > span > a {
        font-size: $font-size-medium-regular;
        text-decoration: underline;
        cursor: pointer;
        display: inline;
      }
    }

    &__text-container {
      margin-top: $spacing-default;
      background: $billsby-grey;
      border-radius: 0px;
      height: 74px;
      width: 100%;
    }

    &__ads {
      max-width: 100%;
    }

    &__ads-container {
      margin-top: $spacing-small;
      text-align: center;

      &__placeholder {
        width: 100%;
        height: 150px;
        border-style: dashed;
        border-width: thin;

        &__title {
          padding-top: 25px;
          font-family: "Roboto";
          font-weight: bold;
          font-size: 20px;
          margin-bottom: 0px;
        }
        
        &__sub-title {
          margin-top: 5px;
        }
      }
    }
  }
}
