@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.custom-colorpicker {
  display: flex;
  width: 120px;
  background-color: $input-bg;

  cursor: pointer;
  position: relative;

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include border-radius($input-bg);
    overflow: hidden;
    border: 1px solid rgba(36, 47, 54, 0.14);
    box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.5);
  }

  &--disabled {
    pointer-events: none;
  }

  &__text {
    color: $billsby-black;
    font-size: $font-size-medium;
    margin-left: $spacing-default;
    margin-right: $spacing-default;
  }

  &__color-edit {
    position: absolute;
    width: 100px;
    height: 28px;
    font-weight: bold;
    left: $spacing-small;
    bottom: $spacing-small;
    padding-left: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    box-shadow: 0px 0px 4px #00000080;
  
    @include border-radius();

    &::after {
      font-family: 'Font Awesome 5 Pro';
      font-weight: bold;
      content: '\f303';
      cursor: pointer;
      position: absolute;
      left: 80px;
    }
  }

  .rc-color-picker {
    &-trigger {
      display: block;
      background-color: unset !important;
      height: 100px;
      width: 120px;
      box-shadow: initial;
      padding: 0;
      border: none;
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  &__content {
    display: flex;
    height: 100px;
    width: 120px;

    & > div {
      display: flex;
      width: 50%;
      height: 100%;
      overflow: hidden;
      align-items: center;
      justify-content: center;

      .cc-text {
        position: relative;
        font-size: 20px;
        // color: $white !important;
        top: -$spacing-medium;
      }
    }

    &__left-side {
      .cc-text {
        right: -50%;
      }
    }
    &__right-side {
      .cc-text {
        left: -50%;
      }
    }
  }
}
