@import "../../../../../style/variables.scss";

.brand-customization-tabs {
  width: 100%;

  &__content {
    padding-top: $spacing-default;
  }

  & > .tabbed-container:first-of-type {
    padding: 0px;
    box-shadow: inset 0px -10px 0px -8px rgb(216, 216, 216);
    a {
      min-width: unset;
      padding: 0px $spacing-medium;
    }
  }

}
