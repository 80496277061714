@import '../variables.scss';

@mixin scrollbar($width: 2px, $color: $billsby-orange, $height: 2px, $color-thumb: $billsby-orange) {
  &::-webkit-scrollbar {
    width: $width !important;
    height: $height !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-thumb !important;
  }

  &::-webkit-scrollbar-track {
    background-color: $color !important;
  }
}
