@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';

.two-factor-auth {
  &-disable {
    &__turnoff {
      @include panel-bg-img('../../images/2fa.svg', right);
      background-size: 220px;
      background-position-x: 105%;
      background-position-y: center;
    }
    .cc-form-label {
      width: 100px;
    }

    &__sixdigits {
      display: flex;
      align-items: center;
      & > div {
        display: flex;
        align-items: center;
        .cc-input__field, .cc-input__field::placeholder {
          font-size: 20px;
          letter-spacing: 4px;
          font-family: $billsby-font-family; 
        }
        .cc-input {
          max-width: 83px;
          margin-right: $spacing-small;
        }
        .cc-input__field {
          &::placeholder {
            text-align: center;
            color:  $billsby-light-grey;
          }
        }
      }
    }

    &__password {
      display: flex;
      align-items: center;
      .cc-input {
        width: 360px;
      }
    }
  }

  &-enable {
    .cc-form-label {
      width: 100px;
    }

    &__heading {
      @include panel-bg-img('../../images/security-2fa.svg', right);
      background-size: 220px;
      background-position-x: 105%;
      background-position-y: center;
      & > .cc-text:first-of-type {
        max-width: 75%;
      }
    }

    &__phone {
      display: flex;
      align-items: center;
      position: relative;
      .billsby-phone-input {
        width: calc(100% - 100px);
      }
    }

    &__sixdigits {
      @extend .two-factor-auth-disable__sixdigits;
    }

    &__password {
      @extend .two-factor-auth-disable__password;
      .cc-input {
        width: calc(100% - 100px);
      }
    }
    
    &__qrcode {
      a {
        text-decoration: underline;
        cursor: pointer;
        margin: 0 3px;
      }
    }

    &__footer {
      text-align: center;
    }
  }
}