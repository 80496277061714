@import '../../style/variables.scss';

.getting-started {
  margin-top: calc(#{$HEADER_HEIGHT} + #{$spacing-default});
  height: 100%;
  //position: relative;

  &-panel {
    background-color: $billsby-green;
    margin-left: $spacing-small;
    margin-right: $spacing-small;
    margin-bottom: -60px;

    &__title:first-of-type {
      color: $white;
      font-size: $font-size-medium-regular;
    }

    .cc-text {
      margin-bottom: $spacing-small;
    }
  }

  #skip-getting-started-btn {
    position: absolute;
    bottom: 150px;
    left: $spacing-small;
    font-weight: 400;
    width: calc(100% - 2*#{$spacing-small});
  }

  .navbar__content-body {
    height: calc(100% - 500px);
  }
  
  .navbar__content-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: calc(100% - 2*#{$spacing-small});
  }

  &-step {
    &.cc-text {
      color: $white;
      font-size: $font-size-medium-regular;
      margin-bottom: $spacing-small;
    }

    &__noMargin {
      &.cc-text {
        margin-bottom: 0;
      }
    }
    
    &__icon {
      font-family: "Font Awesome 5 Pro";
      font-weight: bold;
      color: $white;
      font-size: $font-size-medium-regular;
      margin-right: $spacing-small;
    }

    &--done {
      &::before {
        content: '\f058';
        // inline-block override the text-decoration prop
        display: inline-block;
        @extend .getting-started-step__icon;
      }
      text-decoration: line-through;
      &::before {
        text-decoration: none;
      }
    }
    &--current {
      &::before {
        content: '\f0a9';
        @extend .getting-started-step__icon;
      }
    }
    &--locked {
      opacity: .2;
      &::before {
        content: '\f023';
        @extend .getting-started-step__icon;
      }
    }
  }



}