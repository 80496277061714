@import '../../../style/variables.scss';

.number-input {
  $size: 40px;
  &__container {
    display: flex;
  }

  &__field {
    text-align: center;
    outline: none;
    width: 50px;
    border: none;
    box-shadow: 0px 0px 6px #00000029;
  }

  &__btn {
    width: $size;
    height: $size;
    margin: 0;
    padding: 0;
    border: 0;
    background: #555555;
    color: #fff;
    cursor: pointer;
    &:focus {
      outline: none;
    }

    &.disabled {
      color: #A8A8A8;
    }

    &.lock {
      background: $billsby-red;
      color: $white;
      cursor: auto;
    }
  }

  &__btn-minus {
    border-radius: 6px 0px 0px 6px;
  }

  &__btn-plus {
    border-radius: 0px 6px 6px 0px;
  }

}