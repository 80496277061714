@import '../../../style/variables';

.tax-rate-panel {
  display: flex;
  align-items: center;
  height: 46px;
  padding: $spacing-medium $spacing-default;
  margin-top: $spacing-medium;

  .cc-text {
    color: $billsby-black;
  }

  &__name {
    @at-root #{&}--fixed {
      width: 90px;

      .cc-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__actions {
    margin-left: auto;

    i {
      cursor: pointer;

      &:last-child {
        margin-left: $spacing-medium;
      }

      &.edit {
        color: $billsby-black;
      }

      &.delete {
        color: $billsby-red;
      }
    }
  }

  &__input {
    width: 130px;
    margin: 0 $spacing-small;
    display: block;


    &__suffix {
      margin-left: $spacing-tiny;
    }

    &__tax-reg-num {
      margin-left: $spacing-medium;
      display: flex;
      align-items: center;

      .cc-input {
        width: 150px;
      }
    }
  }



  &__input__container {
    display: flex;
    align-items: center;
  }

  .divider {
    background-color: $billsby-orange;
    margin: 0px 15px;
    height: 30px;
  }
}
