@import '../../style/variables.scss';
@import '../../style/mixin/breakpoint.scss';
@import '../../style/mixin/box-shadow.scss';
@import '../../style/mixin/border-radius.scss';

.container {

  &__logged {
    display: flex;
    flex-direction: row;
    height: inherit;

    .container__content {

      /*overflow-x: scroll;
      @include breakpoint(1140px) {
        overflow: hidden;
        width: 100%;
      }*/
    }

    .container__content-wrapper {
      width: calc(#{$max-wrapper-width} - #{$NAVBAR_WIDTH});
      margin-left: $NAVBAR_WIDTH;

      @include breakpoint($minimum-supported-width) {
        width: calc(100% - #{$NAVBAR_WIDTH});
      }

      &--minimised {
        width: 100%;
        margin-left: 80px;
      }
    }
  }

  &__not-logged {
    margin: 0px auto 0px;
    padding-top: calc(#{$HEADER_HEIGHT} + #{$spacing-default});
    max-width: 800px;

    .container__content {
      @include breakpoint(0, $mobile-width) {
        height: calc(100vh - 100px);
        overflow-y: auto;
      }
    }
  }

  &__sidebar {
    background-color: $background-grey;
    @include box-shadow(0 0 8px -1px rgba(0, 0, 0, 0.5));
    transition: width 100ms ease-in-out;
    position: fixed;
    width: $NAVBAR_WIDTH;
    height: 100%;
    z-index: 10;

    &--minimised {
      width: 80px;
      background-color: $billsby-black;
    }
  }

  &__warning {
    &.error-panel {
      display: flex;
      border-radius: 0px;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
      background-color: $billsby-orange;
      padding-top: $spacing-small;
      padding-bottom: $spacing-small;
      margin-bottom: 0;

      & .error-panel__titlecontainer {
        display: flex;
        align-items: center;

        .cc-text {
          color: $milk-white;

          &:nth-child(1) {
            font-weight: $font-weight-bold;
          }

          font-size: $font-size-medium;
          line-height: 1.29;
        }

        i {
          font-size: $font-size-large;
          color: $milk-white;
          margin-right: $spacing-default;
        }
      }

      & .error-panel__btn {
        text-transform: initial;
        font-family: $billsby-font-family;
        padding: $spacing-small $spacing-default;
        font-size: $font-size-medium;
        color: $milk-white;
        letter-spacing: normal;
        background-color: rgba(255, 254, 252, 0.1);
        @include border-radius($milk-white);
      }
    }
  }
}