@import '../../style/variables';

.invite-teammate {
  table {
    thead,
    tbody {

        th,
        td {
          //flex: 1 1;
          &:nth-child(1) {
            flex: 1 1 1%;
          }
          &:nth-last-child(1) {
            justify-content: flex-start;
            flex: 1 1 60%;
          }
        }
        tr {
          max-height: unset;
        }
      }
  }
  .cc-input {
    &--error {
      input {
        border-color: $billsby-muted-gray;
      }
    }

    &__error-container {
      width: 177px;
      height: 24px;
      line-height: 24px;
      display: block;
      background-color: $white;
      border: 0px solid $billsby-red;
      border-radius: 4px;
      text-align: center;
      top: 8px;
      right: 10px;
    }
  }

  &__success-option {
    text-align: center;
    margin-top: $spacing-medium;
  }

  button {
    &.button {
      
      letter-spacing: 0px;
      font-size: 16px;
    }
    &.btn {
      &-success {
        color: $white;
        background-color: $billsby-green;
        border: 1px solid $billsby-green;
      }
    }
  }
}
