@import '../../../../../style/variables.scss';
@import '../../../../../style/mixin/panel-bg-img.scss';

.checkout-account-management-checkout {
  &__sub-info {
    margin-top: $spacing-tiny;
  }

  &__textarea-label {
    margin-top: $spacing-default;
  }

  &__redirect-url {
    display: flex;
    align-items: center;

    .cc-input {
      max-width: 70%;
      margin-right: $spacing-small;
    }

    & > *:nth-child(3), & > *:nth-child(5) {
      color: $billsby-orange;
    }
  }

  &__js-tracking {
    position: relative;
    padding-top: calc(64px + #{$spacing-default});

    .notice-panel {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}