@import "../../style/variables.scss";

.plan-price-card {
  position: relative;
  min-height: 526px;
  border-radius: 4px;
  padding: $spacing-default;
  box-shadow: 0px 0px 4px #00000080;
  transition: all 200ms;

  //box-shadow: 0px 20px 80px -20px rgba(30, 36, 50, 0.19);
  &:hover {
    box-shadow: 0px 1px 5px #00000080;
    transform: translateY(-2px);
  }

  & > *:not(.button) {
    color: $billsby-black;
  }

  & > *:nth-child(1) {
    font-size: 18px;
    margin-bottom: $spacing-small;
  }

  & > *:nth-child(2) {
    position: absolute;
    top: 15px;
    right: 15px;

    font-size: $font-size-small;
    background: $billsby-orange;
    border-radius: 14px;
    color: $white;
    text-transform: uppercase;
    padding: 5px 10px;
  }

  & > *:nth-child(3) {
    display: inline-block;
    font-size: 18px;
  }

  & > *:nth-child(4) {
    display: inline-block;
    font-size: 14px;
  }

  & > *:nth-child(5) {
    margin: $spacing-small 0;
  }

  & > *:nth-child(6) {
    font-size: 13px;
    color: #78756E;
    max-width: 60%;
  }

  & > *:nth-child(8) {
    display: flex;
    align-items: center;
    margin: $spacing-small 0;
    &::before {
      font-size: 20px;
      color: $billsby-black;
      font-family: 'Font Awesome 5 Pro', serif;
      font-weight: bold;
      content: '\f2bd';
      margin-right: $spacing-small;
    }
    
  }

  & > hr:nth-child(9) {
    margin-bottom: $spacing-default;
  }

  &__features {
    margin-bottom: $spacing-default;
    & > * {
      display: flex;
      align-items: center;
      margin-bottom: $spacing-small;
      
      i {
        display: inline-block;
        font-size: 20px;
        color: $billsby-black;
        margin-right: $spacing-small;
      }
    }
  }

  hr {
    border: 1px solid #C1BFBF;
  }

  button.btn-success {
    background-color: $billsby-green;
    color: $white;
    border: $billsby-green;
    letter-spacing: normal;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  } 
}