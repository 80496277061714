@import '../../style/variables.scss';

.invoice-sidebar-preview {
  &__panel {
    h6 {
      font-family: $billsby-font-family;
      font-weight: $font-weight-bold;
      margin-bottom: $spacing-tiny;
      font-size: 16px;
      color: $billsby-orange;
    }

    &--success {
      background-color: $billsby-green;
      h6, .cc-text {
        color: $white;
      }
    }

    &--pending {
      background-color: $billsby-orange;
      h6, .cc-text {
        color: $white;
      }
    }

    &--error {
      background-color: $billsby-red;
      h6, .cc-text {
        color: $white;
      }
    }

    border-radius: 0px;
  }

  &__arrow-button {
    align-items: center;
    font-size: 14px;
    font-family: "Roboto";
    color: $white;
    text-transform: uppercase;
    border-radius: 0px;
    margin-bottom: $spacing-default;
    background-color: $billsby-orange;

    &::after {
      font-family: "Font Awesome 5 Pro";
      content: " \F061";
      display: inline;
      padding-left: $spacing-tiny;
    }
  }

  &__link-advert {
    display: block;
    text-align: center;
  }

  &__advert {
    width: 320px;
    display: inline-block;
    margin-bottom: $spacing-default;
  }
  

}
