@import '../../../../style/variables.scss';
@import '../../../../style/mixin/panel-bg-img.scss';

.billsby-pages {
  &__heading {
    @include panel-bg-img("../../../../images/website_builders/billsby_pages_bg.png", right);
    background-size: 275px;
    background-position-x: 102%;
    background-position-y: 5px;
    background-color: #252422;

    &__text-group {
      width: 81%;
      & > .cc-text {
        color:$billsby-super-light-gray;
  
        &.billsby-pages__header1 {
          margin-top: $spacing-tiny;
          font-weight: $font-weight-bold;    
          font-size: $font-size-small;
          color: $white;
        }
      }      
    }

    &__create-page-btn {
      position: absolute;
      right: 20px;
      bottom: 13px;
      letter-spacing: 0;
      font-size: 13px;
      color: $white;
      width: 194px;
      background: $billsby-orange;
    }
  }

  &__api-key {
    & > .code-panel {
      display: block;
    }

    &-copy {
      color: $billsby-super-light-gray;
      font-size: $font-size-medium;
    }
    &-created {
      color: $small-text-grey;
      font-size: $font-size-small;
    }
  }

  &__content {
    padding: 0;
    display: flex;
    flex-direction: row;

    &__image-container {
      flex: 0 0 75%;
    }

    &__image {
      width: 100%;
    }

    &__text {
      margin-top: $spacing-default;
      margin-right: $spacing-default;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__title {
        font-size: 25px;
        margin-bottom: $spacing-tiny;
        font-family: $billsby-font-family
      }

      &-group:first-child {
        flex: 1 1 auto;
      }
      
      &-group:nth-child(2) {
        flex: 2.1 1 auto;
      }

      &-group:nth-child(3) {
        flex: 1.8 1 auto;
      }

      &-group:nth-child(4) {
        flex: 3.2 1 auto;
      }

      &-group:nth-child(5) {
        flex: 2.5 1 auto;
      }
      
      &-group:nth-child(6) {
        flex: 0.5 1 auto;
      }
    }
  }
}