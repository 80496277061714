@import '../../../style/variables.scss';

.step-addon-counter {
  .cc-input i{
    color: $billsby-red;
    opacity: .5;
  }
  #counter-id {
    background-color: $billsby-black;
    color: $white;
    cursor: default;
  }
}