@import '../../style/variables.scss';

.payment-details-form {
  &__spreedly-input {
    padding-top: $spacing-small !important;
    position: relative;

    &--error {
      border-color: $billsby-orange !important;
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
      
      &::after {
        content: '\f30d';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        right: $spacing-small;
        top: 12px;
        position: absolute;
        color: $billsby-red;
      }
    }
  }

  .cc-input__error-container--warning {
    display: none;
  }
}
