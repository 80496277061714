@import "../../../style/variables.scss";

.subscription-event-log {
  text-align: center;

  &__icon-red {
    color: $billsby-red;
  }

  &__discount-icon {
    padding-top: 4px;
  }

  &__controls {
    display: flex;
    align-items: right;
    padding-bottom: $spacing-default;

    &__pagination {
      margin-left: auto;
    }
  }

  &__load-more-btn {
    border-radius: 15px;
    height: 35px;
    width: 267px;

    & > span {
      color: rgb(255, 255, 255);
      font-family: $billsby-font-family;
      font-size: $font-size-medium-regular;
      font-weight: normal;
      letter-spacing: 0px;
      text-align: center;
      text-decoration: underline;
    }
  }
}
