@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';
@import '../../style/mixin/box-shadow.scss';

.customers-welcome {
  &__greeting {
    height: 660px;
    @include panel-bg-img('../../images/customers/customers_welcome.svg', center);
    background-size: 800px 491px !important;
    background-position: center !important;
    display: flex;
    flex-direction: column;

    &-instructions {
      text-align: left;
      margin: auto -20px -20px -20px;
      background-color: $billsby-super-light-gray;
      border-top-left-radius: 0%;
      border-top-right-radius: 0%;
      @include box-shadow(none);
    }

    &-instruction {      
      margin-bottom: 10px;
    }

    &-icon {
      width: 36px;
      height: 36px;
    }
    &-button {      
      height: 36px;
      background-color: $billsby-super-light-gray;
      padding: 0px 10px;
    }
  }
}