@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';
@import '../../style/mixin/box-shadow.scss';

.credit-notes {
  &__greeting {
    height: 500px;
    @include panel-bg-img('../../images/credit-notes.svg', center);
    background-position-y: 53px;
    display: flex;
    flex-direction: column;

    &-question {
      margin: auto -20px -20px -20px;
      background-color: $billsby-super-light-gray;
      border-top-left-radius: 0%;
      border-top-right-radius: 0%;
      @include box-shadow(none);
    }
  }
}