@import '../../../../../../style/variables.scss';

.successful-reattempt-email-template {
  &__button {
    width: 100%
  }

  &__panel {
    margin-right: $spacing-small;
    background-color: $white;
    height: 100%;
    padding: 0px;

    &-header-orange > .content-panel-title {
      color: $billsby-orange;
    }
    
    & > .email-preview__container {
      & > .email-preview__content {
        width: 550px;
        & > .email-preview__details {
          padding-bottom: 0px;
        }
      }
    }
  }
}