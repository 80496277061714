@import '../../../style/variables';

.taxjar-integration {
  &__titlepanel {
    position: relative;
  }

  &__content {
    padding-top: $spacing-small
  }

  &__start-free-trial {
    position: absolute;
    top: $spacing-default;
    right: $spacing-default;
    width: 140px;
    height: 36px;
    line-height: 0px;
  }
}