@import '../../../../../style/variables.scss';
@import '../../../../../style/mixin/panel-bg-img.scss';

$red-light: #fae8e9;
.dunning-declined-payments {

  .switch__container input:not(:checked) + .switch__slider {
    background-color: $billsby-muted-gray;
  }

  &__dropdown {
    .css-14ojxsb-control {
      opacity: 0.5;
      background-color: $white;

      &::after {
        content: '\f30d';
        color: $billsby-red;
        position: absolute;
        right: 12px;
        top: 12px;
        font-family: 'Font Awesome 5 Pro';
        font-weight: $font-weight-bold;
      }
    }
  }

  &__panel {
    position: relative;

    .content-panel-title {
      margin-bottom: 25px;
    }

    &--stretched {
      height: 100%;
      margin-bottom: 0px;
    }
  }

  &__floating-button {
    position: absolute;
    top: $spacing-default;
    right: $spacing-default;
    height: auto;
    padding: 8px $spacing-small;
  }


  &__row {
    &--stretched {
      align-items: stretch;
    }
  }
}
