@import '../../style/variables.scss';

.transaction-logs {
  &__header {
    background-color: $white;
  }
  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-default;
    max-width: $max-wrapper-width;

    .dropdown__container {
      width: 229px;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    padding: $spacing-default;
    max-width: $max-wrapper-width;

    & > .cc-text:first-of-type {
      font-size: 15px;
      white-space: nowrap;
      margin-right: $spacing-default;
    }
    .search {
      i {
        margin-right: $spacing-default;
        top: 10px;
      }
      input,
      input::placeholder {
        font-size: 15px;
        letter-spacing: 0;
      }
      input {
        max-height: 36px;
      }
    }

    &__pagination:first-of-type {
      margin-left: $spacing-default;
    }


  }

  &__table {
    &__item {
      cursor: pointer;
    }
    &.cc-table {
      tr {
        max-height: 100%;
        min-height: 52px;
        td,
        th {
          .status-label {
            white-space: nowrap;
            min-width: 96px;
          }
          &:nth-child(1) {
            flex: 1 1 7%
          }
          &:nth-child(2) {
            flex: 1 1 10%
          }
        }
      }

    }

    &-tooltip-icon {
      color: $billsby-orange;
      font-size: $font-size-huge;
      margin-right: 30px;
    }
  }
}