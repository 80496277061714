@import '../../style/variables.scss';
@import '../../style/mixin/border-radius.scss';

.search {
  position: relative;
  input {
    @include border-radius($billsby-black);
    font-family: $billsby-font-family;
    font-size: $font-size-regular;
    padding-left: $spacing-default;
    color: $billsby-black;

    &::placeholder{
      color: $billsby-placeholder-color;
    }
    &:hover {
      background-color: $billsby-super-light-gray;
    }
  }

  i {
    position: absolute;
    left: auto;
    right: 20px;
    top: 11px;
    font-size: $font-size-large;
  }
}