@import '../../style/variables.scss';
.delete-customer {
  &-table {
    &__row {
      width: 100%;
      &__sub-text {
        font-size: 9px;
        color: $billsby-muted-gray;
      }
    }
    tr.no-max-height {
      max-height: none;
    }
    &__label {
      
      letter-spacing: 1.7px;
      color: $billsby-black;
    }
  }

  &__text {
    &--bold {
      font-weight: $font-weight-bold;
    }
  }
}
