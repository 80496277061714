@import "../../../style/variables";
@import "../../../style/mixin/panel-bg-img.scss";
@import "../../../style/mixin/box-shadow.scss";

.create-discount {
  &__heading {
    @include panel-bg-img("../../../images/discounts/discount_giveaway.svg", right);    
    background-size: 255px;
    background-position-y: -25px;
    background-position-x: 105.5%;
    
    @include breakpoint(1450px, 1549px) {
      background-position-x: 105%;
    }

    @include breakpoint(1550px, 1649px) {
      background-position-x: 104.5%;
    }

    @include breakpoint(1650px) {
      background-position-x: 104%;
    }

    &-text {
      width: 80%;
    }
  }

  &__warning {
    text-align: center;
    margin-top: $spacing-default;
    font-size: $font-size-medium-regular;
  }

  &__basic-form {

    &:last-child.cc-form-group {
      margin-bottom: 0;
    }

    &__switch .switch__off, .switch__container input:not(:checked) + .switch__slider {
      background-color: $billsby-muted-gray;
    }

    &.no-margin {
      margin-left: 0;
    }
  }

  &__discount-type {
    &-title {
      display: flex;
      align-items: center;
      & > *:nth-child(1) {
        margin-right: $spacing-default;
      }
      & > *:nth-child(2) {
        display: flex;
        align-items: center;
        .border {
          border: 1px solid $billsby-orange;
          height: 30px;
          margin-right: $spacing-small;
        }
        .cc-text {
          font-family: $billsby-font-family;
          font-size: $font-size-medium-regular;
          letter-spacing: 0px;
        }
      }
    }
  }

  &__pick-discount__type__panel {
    & .cc-checkbox .cc-checkbox-general__icon {
      font-size: 24px;
    }
  }

  &__discount-deduction__panel {
    & .cc-checkbox .cc-checkbox-general__icon {
      font-size: 24px;
    }
  }

  &__select-discount-type__btn {
    margin-top: $spacing-small;
  }

  &__select_discount_plan {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0px;
    max-width: 1360px;

    &__btn {
      margin-top: $spacing-small;
    }

    &__pagination {
      margin-left: $spacing-default;
      margin-top: 8px;
    }
  }

  &__select-plans {
    &__container {
      padding-left: $spacing-tiny;
      padding-right: $spacing-tiny;
      margin-top: $spacing-default;
    }

    &__panel {
      & .cc-checkbox .cc-checkbox-general__icon {
        font-size: 24px;
      }
    }

    &__attach-later.cc-text {
      cursor: pointer;
      text-decoration: underline;
      color: $billsby-black;
      display: block;
      text-align: center;
      margin-top: $spacing-default;
      margin-bottom: 0px;
    }

    &__list {
      &:last-child {
        margin-bottom: 0;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      i {
        cursor: pointer;
      }

      &__commands {
        display: inline-flex;
        &-force {
          &-label.cc-text {
            padding-top: $spacing-tiny;
            margin-right: $spacing-default;
          }

          &.switch__container input:not(:checked) + span.switch__slider {
            background-color: $billsby-muted-gray;
          }
        }
        & i:nth-child(1) {
          padding-top: $spacing-tiny;
          margin: 0;
          color: $billsby-red;
          cursor: pointer;
        }
      }
    }

    &__listitem {
      display: flex;
      align-items: center;
      flex-grow: 1;
      width: 300px;
      & > *:nth-child(1) {
        margin-right: $spacing-small;
      }
    }

    &__listitem-plan {
      display: flex;
      flex-grow: 5;
      border-left: 1px solid #F87847;
      padding-left: 15px;
      align-items: center;
      & > *:nth-child(1) {
        margin-right: $spacing-small;
      }
    }
  }

  &__add-feature-tags {
    display: block;
    margin-bottom: $spacing-default;
  }
  
}
