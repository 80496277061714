@import '../../../style/variables.scss';

.address-validator {
  &__shipping-checkbox {
    .cc-text:first-of-type {
      margin: 0;
      font-size: $font-size-medium;
      color: $billsby-black;
    }

    .cc-text {
      margin-bottom: 0;
      font-size: $font-size-small;
      color: $billsby-light-grey;
    }
  }

  &__body {
    position: relative;
  }

  &__premiumlabel {
    position: absolute;
    top: 19px;
    left: 210px;
  }

  &__permittedcountries {
    position: relative;
  }
  &__permittedcountry {
    position: absolute;
    right: 50px;
    top: 3px;
    display: flex;
    align-items: center;
    .cc-checkbox:first-of-type {
      margin-right: $spacing-default;
    }
  }

  &__country-panel {
    display: flex;
    align-items: center;
    height: 46px;
    padding: 0 $spacing-medium;
    margin-top: $spacing-default;

    &__controls {
      margin-left: auto;
      display: flex;
      align-items: center;

      .cc-checkbox {
        margin-right: $spacing-default;
      }

      &__delete {
        cursor: pointer;
       i {
        color: $billsby-red;
       } 
      }
    }
  }
}