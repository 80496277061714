@import '../../../../style/variables.scss';

.webex-faq {
    &__panel {
      &__title {
        color: $billsby-black !important;
        font-size: $font-size-large;
      }
    }

    &__link {
        &::before {
            content: '\f57d';
            display: inline-block;
            font-family: 'Font Awesome 5 Pro', sans-serif;
            top: 2px;
            margin-right: $spacing-tiny;
            font-weight: $font-weight-bold;
        }
    }
}