@import "../../../../../style/variables.scss";

.checkout-preview-container {
  width: 100%;
  background-color: $tab-background-grey;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 4px;;

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 420px;
    border-radius: 4px;
    background-clip: padding-box;
    margin-left: auto;
    margin-right: auto;
  }
}