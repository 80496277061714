@import "../../../style/variables.scss";
@import "../../../style/mixin/panel-bg-img.scss";

.customer_status_bar {
  width: 100%;
  height: 84px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.5);
  border-radius: 4px;  
  content: "";
  display: table;
  clear: both;
  text-align: center;

  &__item {
    &__label {
      font-size: 15px;
      font-family: $billsby-font-family;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0px;
    }

    &__value {
      font-size: 20px;
      font-family: $billsby-font-family;
      font-weight: $font-weight-bold;
      text-align: center;
      color: $billsby-black;
      letter-spacing: 0px;
    }
  }
}