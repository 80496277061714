@import '../../../style/variables.scss';

$red-light: #fae8e9;
$orange-light: #ffe4d2;
$green-light: #d8ebd1;

.notice-panel {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding: $spacing-default;
  margin-bottom: $spacing-default;

  &--modal {
    margin-top: -$spacing-default;
    margin-right: 0px;
    margin-bottom: $spacing-default;
    margin-left: -$spacing-default;
    width: calc(100% + 40px);
  }

  &--warning {
    background-color: $billsby-light-orange;

    .notice-panel {
      &__icon {
        color: $billsby-orange;
      }
    }
  }

  &--error {
    background-color: $billsby-light-red;
    .cc-text, a {
      color: $billsby-red;
      font-weight: $font-weight-medium;
    }

    .notice-panel {
      &__icon {
        color: $billsby-red;
      }
    }
  }

  &--success {
    background-color: $billsby-light-green;

    .cc-text, a {
      color: $billsby-green;
      font-weight: $font-weight-medium;
    }

    .notice-panel {
      &__icon {
        color: $billsby-green;
      }
    }
  }

  &__icon {
    font-size: 20px;
    margin-right: $spacing-default;
  }

  &__content {
    padding-top: $spacing-tiny;
  }
}
