@import '../../../style/variables.scss';

.stats-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-default 40px;
  
  &__el {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
    &--link {
      cursor: pointer;
    }

    &:last-of-type {
      margin-right: $spacing-default;
    }

    &-hoverable {
      position: absolute;
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      top: -20px;
      left: 0;
      z-index: 0;
    }
    &-top {
      display: flex;
      align-items: center;
      * { font-size:  15px;}
      i {
        color: $billsby-black;
        margin-left: 5px;
        position: absolute;
        right: -20px;
        z-index: 1;
        font-size: 16px;
      }
    }
    &-bottom {
      display: flex;
      align-items: baseline;
      .amount-formatted {
        &__base {
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.5px;
          color: $billsby-black;
          font-size: $font-size-large;
          
          margin: 0 0 15px 0;
          margin-bottom: 0;
          font-size: 20px;
        }
        &__decimal {
          font-size: 11px;
        }
      }

      & > *:nth-child(2) {
        margin-left: $spacing-small;
      }
    }

  }
}