@import '../../../../style/variables.scss';
.quick-books-overview {
  &__container {
    display: flex;
  }
  &__image-container {
    width: 70%;
    padding: 0;
    margin-right: $spacing-default;
  }
  &__image {
    width: 100%;
  }
  &__text {
    width: 30%;
  }
}