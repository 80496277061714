@import "../../../../style/variables.scss";
@import "../../../../style/mixin/panel-bg-img.scss";
@import "../../../../style/mixin/border-radius.scss";

.billsby-cancellation {
  &__title-panel {
    @include panel-bg-img('../../../../images/cancel_account_bg.svg', right);
    background-size: 420px;
    background-position-y: 15px;
    background-position-x: 145%;

    &-text {
      width: 70%;
    }
  }

  &__info-table {
    &__row {
      width: 100%;
  
      &__sub-text {
        font-size: 9px;
        color: $billsby-muted-gray;
      }
    }
  
    tr.no-max-height {
      max-height: none;
    }
  
    &__label {
      
      letter-spacing: 1.7px;
      color: $billsby-black;
    }

  }
}