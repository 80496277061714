@import '../../../../style/mixin/panel-bg-img.scss';

.ach-settings {
	&__panel1 {
		@include panel-bg-img("../../../../images/billsbot-woman-invoice.svg", right);
		background-size: 255px;
		background-position-y: -6px;
    background-position-x: 101%;

		.cc-text {
			max-width: 80%;
		}
	}

	&__locked {
		opacity: .5;
		pointer-events: none;
	}
}