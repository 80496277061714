@import "../../../style/variables.scss";
@import "../../../style/mixin/panel-bg-img.scss";

.free-trial-ending {
  &__title-panel {
    @include panel-bg-img("../../../images/giving-woman-presents-2.svg", right);
    background-size: 285px;
    background-position-y: 0;
    background-position-x: 120%;
    
    &__text {
      width: 80%;

      &--blue {
        color: $billsby-black !important;
      }
    }
  }

  &__trial-balance.cc-text {
    font-size: $font-size-large;
    color: $billsby-orange;
    display: inline;
  }

  &__title.cc-text {
    font-size: $font-size-large;
    color: $billsby-orange;
    display: block;
  }

  &__trial-limit.cc-text {
    font-size: $font-size-medium-regular;
    display: inline;
  }

  &__title-text.cc-text {
    margin-top: $spacing-small;
    &.free-trial-ending__title-text {
      &-inline {
        display: inline;
      }

      &-bold {
        font-weight: $font-weight-bold;
      }
    }
  }

  &__pro-feature-panel {
    background: url('../../../images/Shape.svg');
    height: 70px;
  }

  &__pro-feature-image {
    float: left;
  }

  &__pro-feature-button {
    width: 230px;
    height: 36px;
    background: #FFFEFC1A 0% 0% no-repeat padding-box;
    border: 1px solid #FFFEFC;
    border-radius: 4px;
    opacity: 1;
    float: right;
    & > span {
      font-size: $font-size-medium-regular;
      color:$white;
    }
  }

  &__item-label.cc-text {
    color:$billsby-black;
  }

  &__item-value.cc-text {
    color:$billsby-black;
    font-size: $font-size-large;
  }

  &__list {
    & > section > div > div > p.interactive-list__element__title {
      color:$billsby-black;
    }
  }

  &__table {
    &.cc-table {
      tr {
        &:last-of-type {
          margin-bottom: $spacing-small;
        }

        th,
        td {
          &:nth-child(1) {
            flex: 1 1 60%;
          }
        }

        td:first-of-type {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.fix-features {
  margin-bottom: 0px !important;
  position: relative;
  z-index: 1;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  box-shadow: $box-shadow;

  &__navbar {
    & > div {
      margin-bottom: $spacing-medium;
    }
  }
}

