@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';
@import '../../../style/mixin/box-shadow.scss';
@import '../../../style/mixin/breakpoint.scss';

.allowances-welcome {
  &__greeting {
    @include breakpoint(1088px) {
      @include panel-bg-img('../../../images/allowances/allowances.svg', right);
      background-position-y: center;
      background-size: 560px;
      background-position-x: 126%;
    }
    @include breakpoint(1420px) {
      background-position-x: 109%;
    }
  }
  &__container-text {
    width: 58%;
  }
}