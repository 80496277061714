@import '../../../style/variables.scss';
@import '../../../style/mixin/breakpoint.scss';

.page-wrapper {
  padding: $spacing-default;
  width: calc(#{$max-wrapper-width} - #{$NAVBAR_WIDTH});

  @include breakpoint($minimum-supported-width) {
    max-width: $max-wrapper-width;
    width: auto;
    overflow: visible;
  }
}