@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';

.export-data {
  &__welcome {
    @include panel-bg-img("../../images/export-data.svg", right);
    background-position-x: 104%;
    .cc-text {
      max-width: 70%;
    }
  }
  &__datefilter {
    & > div:first-child {
      display: flex;
      align-items: center;

      & > .cc-text {
        letter-spacing: -0.5px;
        color: $billsby-black;
        font-size: $font-size-large;
    
        margin-right: $spacing-default;
      }
    }
  }

  .switch__off, .switch__container input:not(:checked) + .switch__slider {
    background-color: $billsby-muted-gray;
  }
}