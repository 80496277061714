@import "../../../../../../style/variables.scss";

.email-advertisement-input {
  &-submit {
    width: 100%;
    margin-top: 20px;
    height: 36px;
    font-size: 14px;
  }

  &-no-padding-bottom {
    padding-bottom: 0px;
  }

  &-description.cc-text {
    font-size: 13px;
  }

  &__link__label {
    padding-top: 13px;
    margin-bottom: 0px;

    &-blue {
      padding-top: 13px;
      font-size: $font-size-medium-regular;
      color: $billsby-black;
    }
  }

  &__switch {
    margin-bottom: $spacing-small;
    &__label {
      padding-top: 5px;
      font-size: $font-size-medium-regular;
      color: $billsby-black;
    }

    &-control {
      &.switch__container input:not(:checked) + .switch__slider {
        background-color: $billsby-muted-gray;
      }
    }
  }

  &__checkbox {
    &__title {
      font-size: $font-size-medium;
    }

    &__subtitle {
      font-size: $font-size-medium;
      color: $billsby-muted-gray;
    }
  }

  &__file-uploader {
    &__browse {
      text-decoration: underline;
      cursor: pointer;
    }

    flex-grow: 1;
    margin-bottom: $spacing-default;
    & .file-upload {
      margin-bottom: 0;
      & .file-upload__container .file-upload__logo {
        background-color: $white;
        border-width: 1px;
        border-style: dotted;
        background-clip: padding-box;
        border-radius: 4px;
        margin-right: $spacing-default;
        &:before {
          color: $billsby-black;
          content: "\f1c5";
          font-size: 36px;
        }
      }
    }
  }
}
