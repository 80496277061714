@import '../../../../../style/variables.scss';

.checkout-account-management-self-service {

  &__table {
    margin-bottom: 0px;

    tr {
      display: flex;
      align-items: center;
      justify-content: space-between;

      td {

        i {
          font-size: 17px;
          margin-right: $spacing-small;
          color: $billsby-black;

          &.cancel {
            color: $billsby-red
          }
        }

        .switch__container {
          margin-right: 0px;
        }

        &:last-child {
          flex: 0 0 50px;
        }
      }
    }
  }
}