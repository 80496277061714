@import '../../../../../style/variables.scss';

.tax-rates {
  position: relative;
  padding-bottom: 100px;

  &__item {
    display: flex;
    align-items: center;
    height: 46px;
    padding: $spacing-medium $spacing-default;
    margin-top: $spacing-medium;

    &__actions {
      margin-left: auto;
      cursor: pointer;
      color: $billsby-red;
      i:first-child {
        color: $billsby-black;
        margin-right: $spacing-medium;
      }
    }

    &__rates {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 530px;
    }

    .divider {
      background-color: $billsby-orange;
      margin: 0px 15px;
      height: 30px;
    }
  }

  &__floating-button {
    position: absolute;
    top: $spacing-default;
    right: $spacing-default;
  }

  &__other-countries {
    &.disabled {
      #other-countries {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
