@import '../../../style/variables.scss';

.invoice-payments-wrapper {
  padding: $spacing-default;
  background-color: $body-bg;
  border-radius: 2px;
  box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  width: 100%;

  .spinner {
    div {
      background-color: $billsby-black;
    }
  }
}

.invoice-payments-table {
  width: 100%;
  margin-bottom: 0px;
  border-spacing: 0;

  thead,
  tbody {

    tr {
      th,
      td {
        flex: 1;
        text-align: left;
        padding: $spacing-medium;
        border-bottom: 1px solid $billsby-light-grey;

        &:last-of-type {
          justify-content: flex-start;
          flex-grow: 1;
        }

        &:nth-child(1) {
          flex: 0 1 120px;
        }

        &:nth-child(2) {
          flex: 0 1 15%;
        }

        &:nth-child(3) {
          flex: 0 1 25%;
        }

        &:nth-child(4) {
          flex: 0 1 20%;
        }

        &.view-arrow {
          text-align: right;
          & > a {
            width: 100%;
            margin-right: $spacing-default;
            cursor: pointer;
          }
        }
      }
    }
  }
}
