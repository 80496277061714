@import '../../../../../style/variables.scss';
@import '../../../../../style/mixin/panel-bg-img.scss';

.confirmation-screen-panel {
  &__form {
    margin-top: 20px;
  }

  &__redirect-url {
    display: flex;
    align-items: center;

    .cc-input {
      max-width: 87%;
      margin-right: $spacing-small;
    }

    & > *:nth-child(3), & > *:nth-child(5) {
      color: $billsby-orange;
    }
  }
}