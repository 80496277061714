@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/panel-bg-img.scss';
@import '../../../style/mixin/breakpoint';


.subscription-add-ons {
  &t__greeting-alert-blue {
    &.navigation-alert--warning {
      background-color: $billsby-black;
    }
    & > .navigation-alert__btn--warning {
      background-color: $billsby-black;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    padding: $spacing-default;
    max-width: $max-wrapper-width;

    & > .cc-text:first-of-type {
      font-size: 15px;
      white-space: nowrap;
      margin-right: $spacing-default;
    }
    .search {
      i {
        margin-right: $spacing-default;
        top: 10px;
      }
      input,
      input::placeholder {
        font-size: 15px;
        letter-spacing: 0;
      }
      input {
        max-height: 36px;
      }
    }
  }

  &__pagination.pagination {
    margin-left: $spacing-default;
  }

  &__header {
    background-color: $white;
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-default;
    max-width: $max-wrapper-width;

    .dropdown__container {
      width: 229px;
    }
  }

  // &__greeting-alert {
  //   max-width: $max-wrapper-width;
  // }

  &__panel {
    max-height: 100% !important;
  }

  .cpanel--collapsed {
    max-height: 80px !important;
  }

  &__product-container {
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    margin-bottom: 10px;

    &--padding {
      padding: 30px 20px;
    }

    &-body {
      border-top: $billsby-black solid 1px;
      padding-top: 20px;
      margin-top: 2px;

      &--collapsed {
        padding-top: 20px;
        margin-top: 2px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      justify-items: end;
      width: 100%;
      position: relative;
      margin-bottom: $spacing-medium;
      margin-top: $spacing-medium;

      &-chevron {
        margin-right: $spacing-medium;
      }

      &__button-container {
        position: absolute;
        top: -18%;
        right: 0;
        display: flex;
      }
    }

    &-button {
      margin-left: $spacing-small;
    }

    &-row {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      //margin: $spacing-default 0 $spacing-small 0;

      &__icon-container {
        position: absolute;
        top: 18%;
        right: 0;

        &-icon {
          margin-left: $spacing-small;
        }

        &--red {
          color: $billsby-red;
        }
      }
    }

    &-name {
      margin-right: $spacing-small;
      display: inline-flex;
    }

    &-description {
      margin-left: $spacing-small;
      padding-left: $spacing-small;
      border-left: $billsby-orange solid 1px;
      height: 100%;
      font-size: 15px;
    }
  }

  &__table {
    &__label {
      display: block;
    }

    &__sub-label {
      color: $small-text-grey;
      font-size: $font-size-tiny;
    }

    &.cc-table {
      thead {
        pointer-events: none;
      }

      tr {
        &:last-of-type {
          border-bottom: 1px solid $billsby-light-grey;
        }

        th,
        td {
          &:nth-child(6) {
            flex: 1 1 26%;
          }
        }

        button {
          height: 28px;
          width: 115px;
          margin-left: $spacing-medium;
        }

        .button-holder {
          width: 115px;
          margin-left: $spacing-medium;

          button {
            margin-left: 0;
          }
        }

        td:first-of-type {
          &::before {
            display: none;
          }
        }

        &.expanded {
          padding: 0px;
          max-height: none;

          &--smooth {
            min-height: 0px !important;
          }
        }

        &.cc-table--expandable__rowExpanded {
          border-bottom-color: $white;
        }
      }

      #update-usage-allowance, #update-usage-save-changes {
        width: 156px;
      }
      #delete-allowance, #cancel-update-usage {
        width: 113px;
      }
    }

    &__collapsible {
      cursor: pointer;
      color: $billsby-black;

      $this: &;

      &-label {
        text-decoration-line: underline;
      }

      &-arrow {
        &::before {
          content: '\F107';
          font-family: 'Font Awesome 5 Pro';
          font-size: $font-size-regular;
          line-height: 1.19;
          color: $billsby-black;
          margin-right: $spacing-tiny;
          cursor: pointer;
          text-decoration-line: none;
        }

        &.expanded {
          &::before {
            content: '\F106';
          }
        }
      }

      &--no-data {
        cursor: default;
        pointer-events: none;
        #{$this}-label {
          text-decoration: none;
        }

        #{$this}-arrow {
          display: none;
        }
      }
    }

    &__overage-perunit {
      & > *:nth-child(1) {
        line-height: 1;
      }
      & > *:nth-child(2) {
        font-size: $spacing-small;
      }
    }

    &__actions {
      button {
        height: 24px;
        padding: 3px 0px;
        margin: 0px $spacing-tiny;
      }
    }
  }

  &__content {
    padding-right: 267px;
    margin-bottom: $spacing-default;
  }

  &__tooltip-text {
    span {
      color: $billsby-black;
      text-decoration-style: dashed;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  &__button {
    &--highlight {
      box-shadow: $box-shadow-orange;
    }
  }

  .tooltip--blue {
    max-width: 460px;
  }

  &__other-companies {
    &.cpanel-arrow--collapsed {
      .content-panel-title {
        margin-bottom: 0px;
      }
    }
    .content-panel-title {
      margin-bottom: $spacing-default;
    }
  }

  &__inner-table {
    margin-bottom: 0px;
    
    tr {
      th, td {
        &:first-of-type {
          flex: .3 1;
        }
        &:last-of-type {
          justify-content: flex-start;
        }
      }
    }
  }

  &__expanded-wrapper {
    padding: $spacing-default;
    background-color: $body-bg;
    border-radius: 2px;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    width: 100%;
    margin-top: -$spacing-default;


    .progress-indicator {
      padding: 0;
    }
  }
}
