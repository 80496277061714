@import '../../style/mixin/panel-bg-img.scss';

.zapier {
  &__heading {
    position: relative;
    @include panel-bg-img('../../images/zapier-switchboard.svg', right);
    background-size: 393px;
    background-position-y: -62px !important;
    background-position: right;
    background-position-x: 90%;

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: $spacing-small;
      .cc-text {
        font-weight: 400;
        letter-spacing: -0.5px;
        color: $billsby-black;
        font-size: $font-size-large;
        
      }
    }

    &__integrateBtn {
      position: absolute;
      right: 20px;
      bottom: 13px;
      width: 141px;
      letter-spacing: 0;
      font-size: 13px;
    }

    &__logo {
      @include panel-bg-img('../../images/zapier-logo.png', center);
      width: 30px;
      height: 30px;
      margin-right: $spacing-small;
    }

    .cc-text {
      max-width: 53%;
    }
  }


  &__template {
    padding-bottom: $spacing-small;
  }
  
}