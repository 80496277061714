@import '../../../style/variables.scss';

.cc-alert {  
  display: flex;
  width: 50%;
  padding: $spacing-small $spacing-default;
  color: $white;
  top: 0; 
  left: 25%;
  position: absolute;
  z-index: 801;
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 36px;
  transition: height 200ms ease-in-out, padding 200ms ease-in-out;

  &--closed {
    height: 0;
    padding: 0;
  }

  &--isModal { /* Adjust for modals */
    width: 96%;
    left: 2%;
    height: 51px;
  }

  &-container {
    display: flex;
    width: 100%;

    &--closed {
      display: none;
    }
  }
  
  &__icon {
    margin-right: 5px;
    font-size: 15px;
    margin-left: auto;
    align-self: center;

    .svg-inline--fa {
      background-color: transparent !important;
      border: none !important;
    }
  }
  
  &__text {
    font-size: $font-size-medium;
    font-weight: $font-weight-regular;
    align-self: center;
    color: $white;
    margin-right: auto;
  }

  &__close {
    cursor: pointer;

    .svg-inline--fa {
      background-color: transparent !important;
      border: none !important;
    }
  }

  &-warning {
    background-color: $billsby-red;
  }
  
  /*&-info {
    background-color: $ocean-blue;
  }*/
  
  &-success {
    background-color: $billsby-green;
  }
  
  &-support {
    background-color: $billsby-orange;
  }

  /*********************************** 
  Animations 
  ************************************/

  /*&-enter {
    opacity: 0.09;
    transform: translate3d(0, -100%, 0);
  }

  &-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: all 300ms ease-out;
  }

  &-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &-exit-active {
    opacity: 0.09;
    transform: translate3d(0, -100%, 0);
    transition: all 300ms ease-out;
  }*/
}
