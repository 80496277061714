@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/panel-bg-img.scss';
@import '../../../style/mixin/breakpoint';

.ppc-list {
  &t__greeting-alert-blue {
    &.navigation-alert--warning {
      background-color: $billsby-black;
    }
    & > .navigation-alert__btn--warning {
      background-color: $billsby-black;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    padding: $spacing-default;
    max-width: $max-wrapper-width;

    & > .cc-text:first-of-type {
      font-size: 15px;
      white-space: nowrap;
      margin-right: $spacing-default;
    }
    .search {
      i {
        margin-right: $spacing-default;
        top: 10px;
      }
      input,
      input::placeholder {
        font-size: 15px;
        letter-spacing: 0;
      }
      input {
        max-height: 36px;
      }
    }
  }

  &__pagination.pagination {
    margin-left: $spacing-default;
  }

  &__header {
    background-color: $white;
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-default;
    max-width: $max-wrapper-width;

    .dropdown__container {
      width: 229px;
    }
  }

  // &__greeting-alert {
  //   max-width: $max-wrapper-width;
  // }

  &__panel {
    max-height: 100% !important;
  }

  .cpanel--collapsed {
    max-height: 80px !important;
  }

  &__product-container {
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    margin-bottom: 10px;

    &--padding {
      padding: 30px 20px;
    }

    &-body {
      border-top: $billsby-black solid 1px;
      padding-top: 20px;
      margin-top: 2px;

      &--collapsed {
        padding-top: 20px;
        margin-top: 2px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      justify-items: end;
      width: 100%;
      position: relative;
      margin-bottom: $spacing-medium;
      margin-top: $spacing-medium;

      &-chevron {
        margin-right: $spacing-medium;
      }

      &__button-container {
        position: absolute;
        top: -18%;
        right: 0;
        display: flex;
      }
    }

    &-button {
      margin-left: $spacing-small;
    }

    &-row {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      //margin: $spacing-default 0 $spacing-small 0;

      &__icon-container {
        position: absolute;
        top: 18%;
        right: 0;

        &-icon {
          margin-left: $spacing-small;
        }

        &--red {
          color: $billsby-red;
        }
      }
    }

    &-name {
      margin-right: $spacing-small;
      display: inline-flex;
    }

    &-description {
      margin-left: $spacing-small;
      padding-left: $spacing-small;
      border-left: $billsby-orange solid 1px;
      height: 100%;
      font-size: 15px;
    }
  }

  &__table {
    &.cc-table {
      thead {
        pointer-events: none;
      }

      tr {
        th,
        td {
          &:nth-child(1) {
            flex: 1 1 10%;
          }
          &:nth-child(2),
          &:nth-child(3) {
            flex: 1 1 5%;
          }
          &:nth-child(4) {
            flex: 1 1 49%;
            @include breakpoint(1405px) {
              flex: 1 1 40%;
            }
          }
        }



        td:first-of-type {
          &::before {
            display: none;
          }
        }

        &.expanded {
          padding: 0px;

          &--smooth {
            min-height: 0px !important;
          }
        }

        &.cc-table--expandable__rowExpanded {
          border-bottom-color: $white;

          .ppc-list__table__collapsible-arrow {
            &::before {
              content: '\F106';
            }
          }
        }
      }
    }

    &__collapsible {
      cursor: pointer;
      color: $billsby-black;

      $this: &;

      &-label {
        text-decoration-line: underline;
      }

      &-arrow {
        &::before {
          content: '\F107';
          font-family: 'Font Awesome 5 Pro';
          font-size: $font-size-regular;
          line-height: 1.19;
          color: $billsby-black;
          margin-right: $spacing-tiny;
          cursor: pointer;
          text-decoration-line: none;
        }
      }

      &--no-plans {
        #{$this}-label {
          text-decoration: none;
        }

        #{$this}-arrow {
          display: none;
        }
      }

      // &::before {
      //   content: '\F107';
      //   font-family: 'Font Awesome 5 Pro';
      //   font-size: $font-size-regular;
      //   line-height: 1.19;
      //   color: $billsby-black;
      //   margin-right: $spacing-tiny;
      //   cursor: pointer;
      //   text-decoration-line: none;

      // }
    }

    &__actions {
      button {
        padding: 3px 0px;
        margin: 0px $spacing-tiny;

        &.btn-add, &.btn-error {
          height: 28px;
        }

        &.btn-error {
          width: 300px;
        }
      }

    }
  }

  &__explain {
    @include breakpoint(1088px) {
      @include panel-bg-img('../../../images/products-explain.svg', right);
      background-position-y: center;
      background-size: 325px;
      background-position-x: 104%;
    }

    &.cpanel-arrow--collapsed {
      background-image: none;
    }
  }

  &__content {
    padding-right: 267px;
    margin-bottom: $spacing-default;
  }

  &__tooltip-text {
    span {
      color: $billsby-black;
      text-decoration-style: dashed;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  &__button {
    &--highlight {
      box-shadow: $box-shadow-orange;
    }
  }

  .tooltip--blue {
    max-width: 460px;
  }

  &__other-companies {
    .content-panel-title {
      margin-bottom: $spacing-default;
    }
  }
}

.error-panel.ppc-list__currency-warning {
  display: flex;
  border-radius: 0px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
  background-color: $billsby-orange;
  padding-top: $spacing-small;
  padding-bottom: $spacing-small;
  margin-bottom: 0;

  & .error-panel__titlecontainer {
    display: flex;
    align-items: center;

    .cc-text {
      color: $milk-white;
      &:nth-child(1) {
        font-weight: $font-weight-bold;
      }

      font-size: $font-size-medium;
      line-height: 1.29;
    }

    i {
      font-size: $font-size-large;
      color: $milk-white;
      margin-right: $spacing-default;
    }
  }

  & .error-panel__btn {
    text-transform: initial;
    font-family: $billsby-font-family;
    padding: $spacing-small $spacing-default;
    font-size: $font-size-medium;
    color: $milk-white;
    letter-spacing: normal;
    background-color: rgba(255, 254, 252, 0.1);
    @include border-radius($milk-white);
  }
}
