@import '../../../style/variables.scss';

.create-custom-field {
  margin-bottom: 0;

  &__title {
    display: flex;
    align-items: center;
    i {
      margin-right: $spacing-small;
      color: $billsby-orange;
      cursor: pointer;
    }
    .cc-text:first-of-type {
      font-size: $font-size-large;
      color: $billsby-orange;
    }

  }

  &__compulsoryfields {
    .cc-text { 
      &:nth-child(1) {
        margin-bottom: 5px;
      }
      &:nth-child(2) {
      font-size: $font-size-small;
      color: #c7c7c7;
      }
    }
  }

  &__field-type-value {
    position: relative;
    margin: 0 0 10px 0;
    &__lock-icon {
      position: absolute;
      right: 0;
      top: 0;
    }

  }
}