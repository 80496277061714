@import '../../../../style/variables';

.taxjar-edit-integration {
  &__titlepanel {
    position: relative;
  }

  &__content {
    padding-top: $spacing-small;
  }

  &__notice-panel {
    border-radius: 4px;
    box-shadow: $box-shadow;
  }

  &__start-free-trial {
    position: absolute;
    top: $spacing-default;
    right: $spacing-default;
    width: 140px;
    height: 36px;
    line-height: 0px;
  }

  .switch__container {
    margin-right: 0px;
  }

  .content-panel-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    width: 100%;

    p {
      margin-bottom: $spacing-small;
    }

    &__action-button-wrapper {
      button {
        margin-right: $spacing-medium;
        padding: $spacing-small $spacing-default;
        line-height: 0px;
        letter-spacing: 0px;
        height: 36px;
      }

      i.fas {
        margin-bottom: $spacing-default;
      }
    }
  }

  input {
    &:not(:checked) {
      & + .switch__slider {
        background-color: $billsby-muted-gray;
      }
    }

    &:checked {
      & + .switch__slider {
        background-color: $billsby-black;
      }
    }
  }
}
