@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.other-companies {
  &__container-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    align-items: center;
  }

  &__tabs {
    flex: 0 0 auto;
    margin-bottom: $spacing-default;
    margin-right: 10px;

    &__company {
      width: 200px;
      height: 130px;
      @include border-radius($billsby-black);
      background-color: $bg-add-btn;
      padding: $spacing-medium;
      //display: flex;
      //flex-direction: column;
      //align-items: center;
      cursor: pointer;        
      position: relative;
      margin-left: 5px;
      margin-right: 5px;

      div:first-child {
        filter: brightness(0.6);
      }

      &--active {
        background-color: $billsby-black;     
        
        div:first-child {
          filter: brightness(0) invert(1);
          fill: $billsby-black;
        }

        .other-companies__tabs__company-type {
          background-color: $white;
          border-radius: 15px;
        }

        &:focus {
          outline: none;
        }

        &::after {
          border-top: 10px solid $billsby-black;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          top: 128px;
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          left: calc(50% - 8px);
        }
      }

      &-type {
        font-size: $font-size-tiny;
        color: $billsby-black;
        margin-top: auto;
        position: relative;
        top: 10px;
        width: 170px;
        padding: 5px;
      }

      &-image {
        height: 60px;
        width: 170px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        &--netflix {
          background-image: url('../../../images/productPlanCycle/netflix.png');
        }

        &--dsc {
          background-image: url('../../../images/productPlanCycle/dsc.png');
        }

        &--equinox {
          background-image: url('../../../images/productPlanCycle/equinox.png');
        }      

        &--intercom {
          background-image: url('../../../images/productPlanCycle/intercom.png');
        }

        &--spotify {
          background-image: url('../../../images/productPlanCycle/spotify.png');
         }
          
        &--amazon {
          background-image: url('../../../images/productPlanCycle/amazon.png');
        }   
        
        &--six-flags {
          background-image: url('../../../images/productPlanCycle/six-flags.png');
        }

        &--the-gym {
          background-image: url('../../../images/productPlanCycle/the-gym.png');
        }

        &--we-work {
          background-image: url('../../../images/productPlanCycle/we-work.png');
        }

        &--quip {
          background-image: url('../../../images/productPlanCycle/quip.png');
        }

        &--audible {
          background-image: url('../../../images/productPlanCycle/audible.png');
        }

        &--att {
          background-image: url('../../../images/productPlanCycle/att.png');
        }

        &--digital-ocean {
          background-image: url('../../../images/productPlanCycle/digital-ocean.png');
        }
      }
    }
  }

  &__info {
    margin-top: $spacing-default;
    &-content {
      margin-bottom: $spacing-default;
      display: flex;

      div {
        align-self: center;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-icon {
      border-radius: 20px;
      height: 40px;
      width: 40px;
      display: inline-flex;
      background-color: $billsby-black;
      margin-right: $spacing-default;
    }

    i {
      border-radius: 50%;
      min-height: 40px;
      min-width: 40px;
      max-height: 40px;
      max-width: 40px;
      display: inline-flex;
      background-color: $billsby-black;
      margin-right: $spacing-default;
      color: $white;
      font-size: $font-size-regular;
    }

    .fa-box-full::before,
    .fa-repeat::before,
    .fa-ticket-alt::before {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      height: 18px;
    }

    .fa-box-full::before {
      left: 25%;
    }

    .fa-repeat::before {
      left: 29%;
    }

    .fa-ticket-alt::before {
      left: 27%;
    }

    &-title {
      margin-bottom: $spacing-tiny;
      font-weight: bold;
    }
  }
}