@import '../../style/variables';
@import '../../style/mixin/breakpoint.scss';
@import '../../style/mixin/panel-bg-img.scss';

.login {

  @include breakpoint(0, $mobile-width) {
    padding: 0 $spacing-default;
  }

  &-panel {
    @include breakpoint($mobile-width) {
      // in mobile we don't show the image for lack of space
      @include panel-bg-img('../../images/change-date.svg', right);
      background-size: 313px;
      background-position-x: 104%;
      background-position-y: -17px;
    }
   
   
    
    .cc-text {
      max-width: 490px;
    }
  }

  .cc-checkbox {
    margin: 0;

    &-general__label {
      padding-top: 0;
    }
  }

  &-info {
    &_input-id {
      align-self: center;
    }

    &_remember {
      .cc-text:first-of-type {
        margin: 0;
        font-size: $font-size-medium;
        color: $billsby-black;
      }

      .cc-text {
        margin-bottom: 0;
        font-size: $font-size-tiny;
        color: $billsby-light-grey;
      }
    }

    @media (max-width: $mobile-width) {
      .cc-form-label {
        margin-bottom: $spacing-tiny;
      }
    }

    .cc-checkbox-general__label {
      &::before {
        top: 6px;
      }
      &::after {
        top: 9px;
      }
    }

  }
  
  &-footer {
    display: flex;
    flex-direction: column;
  
    &_text {
      align-self: center;
      margin: $spacing-small 0 0 0 !important;
    }
  }

  #login-btn {
    span {
      letter-spacing: 0.5px;
    }
  }
}