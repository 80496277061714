@import "../../../../../../style/variables";
@import "../../../../../../style/mixin/border-radius.scss";

.rectangle-panel {
  position: relative;
  width: 100%;
  background-color: $white;
  @include border-radius();
  min-height: 100px;

  .rectangle-panel__closeBtn {
    position: absolute;
    border: 2px solid $white;
    right: -20px;
    top: -10px;
    z-index: 10;
    cursor: pointer;

    &::after {
      font-family: "Font Awesome 5 Pro";
      content: "\f00d";
      left: 5px;
      color: $white;
      font-size: $font-size-large;
    }

    &--faded:first-of-type {
      background-color: darken($color: $billsby-super-light-gray, $amount: 10%);
    }
  }
}
