@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

$red-light: #fae8e9;
$red-btn-bg: rgba(210, 38, 48, 0.2);
.error-notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
  background-color: $red-light;

  &__titlecontainer {
    display: flex;
    align-items: center;
    max-width: 80%;
    
    .cc-text {
      &:nth-child(1) {
        font-weight: bold;
        color: $billsby-red;
      }

      font-size: 13px;
      line-height: 1.29;
    }

    i {
      font-size: 20px;
      color: $billsby-red;
      margin-right: $spacing-default;
    }
  }

  &__close-btn {
    color: $billsby-red;
    cursor: pointer;
  }

  &__btn {
    text-transform: initial;
    font-family: $billsby-font-family;
    padding: $spacing-small $spacing-default;
    font-size: 13px;
    color: $billsby-red;
    letter-spacing: normal;
    background-color: $red-btn-bg;
    @include border-radius($billsby-red);
    margin-right: 10px;
  }

  &__modal {
    display: flex;
    width: 60%;
    color: $white;
    top: 50px;
    left: 25%;
    position: fixed;
    z-index: 801;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 36px;
    transition: height 200ms ease-in-out, padding 200ms ease-in-out;

    &--closed {
      height: 0;
      padding: 0;
    }

    &--isModal {
      width: 96%;
      left: 2%;
      height: 51px;
    }

    &-container {
      width: 100%;

      &--closed {
        display: none;
      }
    }

    &__close {
      cursor: pointer;

      .svg-inline--fa {
        background-color: transparent !important;
        border: none !important;
      }
    }
  }

  &--full-width {
    left: -$spacing-default;
    top: -$spacing-default;
    margin-bottom: 0px;
    position: relative;
    width: calc(100% + 40px);
    border-radius: 0px;
  }
}
