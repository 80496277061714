@import "../../../style/variables.scss";

.filter-sort {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &__arrows {
    display: flex;
    flex-direction: column;
    margin-left: $spacing-tiny;

    $div: &;

    div {
      height: 15px;
      width: 15px;
      position: relative;
      margin: 1px;
      i {
        font-size: $font-size-regular;
        position: absolute;
        left: 0px;
        line-height: 0;
      }
    }

    #{$div}__up {
      i {
        bottom: 0px;
      }
    }
    #{$div}__down {
      i {
        top: 0px;
      }
    }
  }
}
