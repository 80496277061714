@import '../../../style/variables';

.cc-form-label {
  display: inline-block;
  width: 100%;  
  margin: 0 0 $spacing-small 0;
  
  &-icon {
    margin-right: 5px;
    color: $white;
  }

  label { 
    font-weight: $font-weight-regular;
    color: $billsby-black;
    font-size: $font-size-medium-regular;
  }

  &--nomargin{
    margin-bottom: 0;
  }
}