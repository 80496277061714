@import '../../../style/variables.scss';
@import '../../../components/ui/text/Text';
@import '../../../style/mixin/breakpoint';
@import '../../../style/mixin/panel-bg-img.scss';
@import '../../../style/mixin/border-radius.scss';

.registration {
  
  @include breakpoint(0, $mobile-width) {
    padding: 0 $spacing-default;
  }
  
  pre {
    font-size: 15px;
  }

  &__sample-review {
    padding: 30px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;

    img {
      position: absolute;
      left: 50%;
      top: 0%;
      transform: translate(-50%,-50%);
      width: 117px;
      height: 38px;
    }
  }

  &__panel {
    padding-bottom: 0 !important;
  }

  &__notification {
    line-height: 1.8;
    & *:nth-child(1) {
      font-size: $font-size-medium;
      color: $billsby-black;
      margin-bottom: 5px;
    }
    & *:nth-child(2) {
      font-size: $font-size-tiny;
      //color: $billsby-super-light-gray;
    }
  }

  &__checkbox-error {
      color: $billsby-red;
  }

  &__questions {
    &__title {
      
      font-size: 15px !important;
      line-height: 1.2 !important;
    }
    &__heading {
      
      font-size: $font-size-large !important;
      letter-spacing: -0.5px;
      color: $billsby-black !important;
    }
  }

  &__passwordRequirements {
    i {
      margin-right: $spacing-small;
      display: inline-block;

      &.fa-check-circle {
        color: $billsby-green;
      }
      &.fa-times-circle {
        color: $billsby-red;
      }
    }

    .cc-text {
      display: inline-block;
    }
  }

  &__invite-panel {
    @include panel-bg-img('../../../images/door.png', right);
    background-position-y: 0px !important;
    background-position-x: 631px !important;
    background-size: 200px !important;
  }

  &__invite-code {
    margin-top: $spacing-medium;
  }

  .cc-checkbox {
    label {
      margin-bottom: 0;
    }
  }
  
  
  /** FIX SPACING ON MOBILE **/
  .cc-form-label {
    margin-bottom: $spacing-small
  }

  .cc-input {
    margin-bottom: 3px;
  }

  @include breakpoint($mobile-width) {
    .cc-form-label {
      margin-bottom: 0;
    }
    .cc-input {
      margin-bottom: 0;
    }
  }

  #register_btn {
    margin-bottom: $spacing-small;
  }

  .cc-checkbox-general__label {
    &::before {
      top: 10px;
    }
    &::after {
      top: 13px;
    }
  }

  &__beta {
    &-subtitle {
      //font-size: $font-size-regular;
      font-weight: $font-weight-bold;
      margin-bottom: $spacing-small;
      &:first-of-type {
        margin-top: $spacing-default;
      }
    }
    &-notice {
      @include border-radius();
      display: flex;
      align-items: center !important;
      height: 60px;
      padding: 0 20px !important;
      margin-bottom: 0 !important;
      .notice-panel__content {
        padding-top: 0;
      }
    }
  }

  &__faq {
    & > .cc-text:last-of-type {
      margin-bottom: 50px;
    }
  }
}