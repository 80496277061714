@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.error-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
  background-color: $white;

  &__titlecontainer {
    display: flex;
    align-items: center;

    .cc-text {
      &:nth-child(1) {
        font-weight: $font-weight-medium !important;
      }

      font-size: 14px;
      line-height: 1.29;
    }

    i {
      font-size: 36px;
      color: $billsby-black;
      margin-right: $spacing-default;
    }
  }

  &__close-btn {
    color: $billsby-black;
    font-size: $font-size-large;
    margin-left: $spacing-medium;
    cursor: pointer;
  }

  &__btn {
    text-transform: initial;
    font-family: $billsby-font-family;
    padding: $spacing-small $spacing-default;
    font-size: 14px;
    color: $billsby-black;
    letter-spacing: normal;
    background-color: $billsby-super-light-gray;
    @include border-radius($billsby-black);
  }
}