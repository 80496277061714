@import '../../../style/variables.scss';

.stats-panel {
  &__title {
    & > div {
      display: flex;
      align-items: center;
      & > *:first-child {
        font-style: normal;
        font-weight: $font-weight-medium;
        letter-spacing: -0.5px;
        color: $billsby-black;
        font-size: $font-size-large;
        
        margin: 0 0 15px 0;
        margin-bottom: 0;
        margin-right: $spacing-small;
      }
    }
    i.fa-info-circle {
      font-size: 16px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__values {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-block {
      // reusable mixin
      @mixin baseValue($color) {
        display: flex;
        align-items: baseline;
        * {
          color: $color;
        }
        font-size: 15px;
        font-weight: 500;
        & > .cc-text {
          margin-left: 5px;

        }
        .decimal {
          font-size: 11px;
        }
      }

      &1 {
        @include baseValue($billsby-black);
      }
      &2 {
        @include baseValue(#78756e);
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}