@import '../../style/variables.scss';

.edit-responses {
  &__field {
    &-description {
      font-size: $font-size-tiny;
      margin-top: $spacing-medium;
      margin-bottom: 0px;
    }
  }

  &__checkbox {
    &-label {
      margin-bottom: 0px;
    }

    &-description {
      font-size: $font-size-tiny;
      margin: 0px;
      color: rgb(37, 36, 34);
    }
  }

  .cc-checkbox-general__input:checked + .cc-checkbox-general__icon {
    color: $billsby-orange;
  }

  .content-panel {
    .cc-form-group{
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }

  &__multiline {
    height: 120px;
  }
}