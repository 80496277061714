@import '../../../style/variables.scss';

.activity-summary {
  min-height: 500px;
  
  &__header {
    background-color: $white;
    box-shadow: $box-shadow;
  }
  
  &__filter {
    display: flex;
    align-items: center;
    max-width: $max-wrapper-width;
    padding: $spacing-small $spacing-default;

    .switch__container input:not(:checked) + .switch__slider {
      background-color: $billsby-light-gray;
    }
  

    .dropdown {
      width: 116px;
    }

    .divider {
      background-color: #dbdee4;
    }

    .button {
      margin-left: auto;
      font-family: inherit;
    }
  }
  

  .navigation-alert-wrapper {
    background-color: $billsby-black;

    .navigation-alert {
      max-width: $max-wrapper-width;
    }

    .navigation-alert__title {
      
      font-size: $font-size-regular;
      font-weight: normal;
    }
  
  }


  .with-side-margin {
    margin: 0 $spacing-medium;
  }

  .bold {
    font-weight: $font-weight-bold
  }
}
