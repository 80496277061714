@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.collect-payment-details {
  &__email {
    color: $billsby-black
  }

  &__title {
    &-panel {
      @include panel-bg-img("../../../images/card-details.svg", right);
      background-position-x: 112%;
      background-size: auto;
    }

    &-text {
      width: 75%;
    }
  }

  .cc-form-label {
    margin-bottom: 0px;
  }
}