@import "../../../style/variables.scss";
@import "../../../style/mixin/panel-bg-img.scss";

.billing_prelive {
  &_panel {
    @include panel-bg-img("../../../images/billsby-rocket2.svg", right);
    background-position-x: 110%;
    &_content {
      max-width: 467px;
    }
  }
}
