@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';

.account-access {
  button.button {
    width: 296px;

    &.btn-error {
      background-color: $billsby-red;
      color: $white;
    }
  }
}