@import "../../style/variables.scss";

.select-add-on-plan {
  &__search {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0px 0px $spacing-default 0px;
    max-width: 1360px;
    
    &__pagination {
      margin-left: $spacing-default;
    }
    
    & .cc-input .cc-input__icon {
      padding-right: $spacing-default;
    } 
  }

  &__title {
    color: $billsby-black;
    font-size: $font-size-large;
  }

  & .menu-panel__content {
    width: 100%;
  }
}
