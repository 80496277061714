@import "../../../../../style/variables.scss";

.positive-one-time-charge-successfully-captured-email-template {
  &__button {
    border-radius: 0px;
    & > span {
      margin-left: $spacing-default;
      margin-right: $spacing-default;
    }
  }

  &__panel {
    margin-right: $spacing-small;
    background-color: $white;
    height: 100%;
    padding: 0px;

    &-header-orange > .content-panel-title {
      color: $billsby-orange;
    }

    &-header-green > .content-panel-title {
      color: $billsby-green;
    }

    & > .email-preview__container {
      & > .email-preview__content {
        width: 550px;
        & > .email-preview__details {
          padding-bottom: 0px;
        }
      }
    }
  }

  &__footer {
    &__text {
      padding: $spacing-default;
      font-size: $font-size-medium-regular;
    }

    &__text-container {
      margin-top: $spacing-default;
      background: $billsby-grey;
      border-radius: 0px;
      height: 74px;
      width: 100%;
    }

    &__ads {
      max-width: 100%;
    }

    &__ads-container {
      margin-top: $spacing-small;
      text-align: center;
    }
  }
}
