@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.create-cycle {
  padding: $spacing-default;
  @include border-radius();
  box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
  background-color: rgba(34, 34, 32, 0.8);
  margin-bottom: $spacing-default;
  .content-panel {
    margin-bottom: 0;
  }

  &__price-form {
    .cc-input {
      display: block !important;
    }
  }

  &-tiered {
    .divider {
      background-color: $billsby-black;
      margin-bottom: $spacing-default;
    }

    &__panel {
      margin-bottom: $spacing-medium !important;
      padding: $spacing-medium $spacing-default;
      display: flex;
      align-items: center;
      height: 46px;

      @at-root #{&}--preview {
        background-color: $billsby-super-light-gray;
        display: block;
        height: auto;

        .cc-form-label {
          margin-bottom: $spacing-tiny;
        }

      }

      .divider {
        background-color: $billsby-orange;
        margin: 0px $spacing-medium;
        height: 30px;
      }

      i {
        color: $billsby-red;
        cursor: pointer;
        font-size: $font-size-regular;
        margin-left: auto;
      }
    }

    &__preview-content {
      display: flex;
      align-items: center;

      .cc-input {
        width: 75px;
        margin: 0 $spacing-small;
      }
    }
  }

  &__price {
    display: flex;
    align-items: center;
    .cc-text {
      margin-right: $spacing-small;
    }

    @at-root #{&}__every {
      width: 65%;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    i {
      cursor: pointer;
    }
  }
  &__listitem {
    display: flex;
    align-items: center;
    & > *:nth-child(1) {
      margin-right: $spacing-small;
    }
  }
  &__icons {
    & i:nth-child(1) {
      margin-right: $spacing-small;
    }
  }

  
  &__free-trial {
    display: flex;
    align-items: center;
    min-height: 41px;
    .cc-input {
      width: 69%;
    }
  }

  &__setup-fee {
    display: flex;
    align-items: center;
    min-height: 41px;
    div {
      display: flex;
      align-items: center;
      width: 100%;
      .cc-text {
        margin-right: $spacing-small;
      }
    }
  }

  &__prorate {
    .cc-text {
      &:nth-child(1) {
        
        font-size: $font-size-regular;
        color: $billsby-black;
        margin-bottom: $spacing-small;
      }
    }
  }

  &__contract-term {
    display: flex;
    align-items: center;
    min-height: 41px;

    & > div {
      display: flex;
      align-items: center;
      width: 100%;
      
      .cc-input {
        width: 77.6%;
        margin-right: $spacing-small;
      }
      .dropdown__container {
        width: 21%;
      }
    }
  }

  &__free-quantity {
    display: flex;
    align-items: center;
    min-height: 41px;
  }
}