@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';

.replace-payment-method {
  &__email {
    color: $billsby-black
  }

  &__title {
    &-panel {
      @include panel-bg-img("../../images/customers/update_payment_details.svg", right);
      background-size: 255px;
      background-position-y: -25px;
      background-position-x: 107%;
    }

    &-text {
      width: 75%;
    }
  }

  .cc-form-label {
    margin-bottom: 0px;
  }

}