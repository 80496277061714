@import '../../../style/variables.scss';

.generic-field-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-size: 15px;
    &--margin {
      margin-bottom: $spacing-small;
    }
    &--noMargin {
      margin-bottom: 0;
    }
  }

  &__edit {
    font-size: 15px;
    color: $billsby-black;
    font-weight: bold;
    cursor: pointer;
    margin-right: $spacing-small;
  }

  &__add {
    cursor: pointer;

    &--checked {
      background: $billsby-green;
      color: $white;
    }
    &--unchecked {
      background: $bg-add-btn;
      color: $billsby-black;
    }
  }
}