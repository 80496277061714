@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.menu-panel {
  position: relative;
  background-color: rgba(34, 34, 34, 0.8);
  &__modal {
    display: flex;
    @include border-radius();
    background-color: $white;
    padding: 0;
  }
  &__menu {
    position: relative;
    border-top-left-radius: 3px;
    background-clip: padding-box;
    border-bottom-left-radius: 3px;
    left: 0;
    top: 0;
    width: 230px;
    background-color: $billsby-black;
  }
  &__content {
    position: relative;
    padding: $spacing-default;
    width: calc(100% - 230px);
  }
}