/* Vertical rhythm (margin, padding and spacing) is as follows:
  Default: 20px
  Medium: 15px
  Small: 10px
  Tiny: 5px

  These are the exceptions:
  Spacing between form fields - 15px
  Button padding - 10px
  Spacing between headings and paragraphs below headings - 10px
  Spacing between list items - 10px

*/


//New branding 
$billsby-black: #1E2432;
$billsby-gray: #626670;
$billsby-light-gray: #a6a7ad;
$billsby-super-light-gray: #EFF4FA;
$billsby-red: #EA3A66;
$billsby-light-red: #F7E6F7;
$billsby-orange: #F87847;
$billsby-light-orange: #FAF5CA;
$billsby-green: #03B884;
$billsby-light-green: #CCF1AF;
$billsby-body-text-color: #252422;
$billsby-muted-gray: #B5BBC2;
$billsby-placeholder-color: #A8B1BC;

$billsby-button-height: 40px;
$billsby-font-family: 'IBM Plex Sans', sans-serif;

// TYPOGRAPHY
$font-size-huge: 24px;
$font-size-large: 18px;
$font-size-regular: 16px;
$font-size-medium-regular: 14px;
$font-size-medium: 13px;
$font-size-small: 12px;
$font-size-tiny: 10px;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$letter-spacing-default: 1px;
$letter-spacing-medium: 1.7px;


//SPACING
$spacing-default: 20px;
$spacing-medium: 15px;
$spacing-small: 10px;
$spacing-tiny: 5px;


// COLORS 
$white: #fff;
$milk-white: #f9f9f9;
$billsby-grey: #ebebeb;
$billsby-light-grey: #ccc5b9;
$light-orange: #FFCFB1;
$nav-btn: #597696;
$nav-hover: rgba(254, 127, 45, 0.2);
$nav-tip-hover: rgba(242, 246, 252, 0.2);
$modal-overlay: rgba(34, 34, 34, 0.8);
$modal-bg: #f2f6fc;
$tab-background-grey: rgba(37, 36, 34, 0.9);

$box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
$box-shadow-orange: 0 0 5px 2px $billsby-orange;
$border-blue: rgba(255, 255, 255, 0.15);
$bg-blue: rgba(255, 255, 255, 0.06);
$bg-add-btn: $billsby-super-light-gray;
$body-bg: #f2f6fc;
$background-grey: #fffefc;
$small-text-grey: #78756e;


$billsby-red-light: rgba(210, 38, 48, 0.2);
$input-bg: $white;
$input-border: #e1e0e0;

$background-grey2: #edeef0;

//breakpoint
$mobile-width: 768px;
$max-wrapper-width: 1360px;
$minimum-supported-width: 1280px;
$invoice-sidebar-width: 400px;

/* HEADER VARIABLES */
$HEADER_HEIGHT: 75px;
$NAVBAR_WIDTH: 250px;
