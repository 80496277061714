@import '../../../style/variables.scss';

.customer-details-tab {
  width: 100%;

  &__content {
    padding-top: $spacing-default;
  }

  .tabbed-container {
    padding: 0px;
    box-shadow: inset 0px -10px 0px -8px rgb(216, 216, 216);

    &--submenu {
      a {
        &.has-error {
          li {
            position: relative;
            &::after {
              content: '\f608';
              display: block;
              font-family: 'Font Awesome 5 Pro';
              position: absolute;
              top: 2px;
              right: -$spacing-medium;
              color: $billsby-red;
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }
  }
}
