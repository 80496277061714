@import '../../../style/variables.scss';
.image-slider {
  display: flex;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
 }
  & > .image-slider__item {
    padding: 0;
    height: 300px;
    width: auto;
    margin-right: $spacing-default;

    & > img {
      height: 100%;
    }
  }
}