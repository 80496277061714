@import '../../style/variables.scss';

.sca-setup {

  #challenge-modal, #device-fingerprint {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1000;
  }

  #challenge {
    iframe {
      height: 320px;
      width: 255px;
    }
  }

  .hidden {
    display: none;
  }

}

.acs .container {
  height: auto !important;
}