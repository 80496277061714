@import "../../../../style/variables.scss";
@import "../../../../style/mixin/panel-bg-img.scss";
@import "../../../../style/mixin/border-radius.scss";
.allowance-config {
  &__heading {
    @include panel-bg-img('../../../../images/allowances/allowances.svg', right);
    background-size: 204px;
    background-position-x: 105%;
    background-position-y: center;
    
  }
  &__heading-text.cc-text {
    width: 75%;
  }
}