@import "../../../../../style/variables.scss";
@import "../../../../../style/mixin/panel-bg-img.scss";
@import "../../../../../style/mixin/border-radius.scss";

.branding-email-preview {
  &__container {
    width: 100%;
    background-color: #252422E6;
    padding-top: $spacing-default;
    padding-bottom: $spacing-default;
    // margin-top: $spacing-default;
    
    &__panel {
      position: relative;
      border-radius: 4px;
      background-clip: padding-box;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__content {
    &.email-preview__container {
      width: 790px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
