@import '../../../style/variables.scss';
.dashboard-unpaid-invoice-warning {
  &__container {
    background-color: $billsby-red;
    background: url('../../../images/dashbord-unpaid-invoice-warning-bg.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    margin-left: $spacing-default;
  }
  &__message-panel {
    padding: $spacing-default;
  }
  &__title {
    color: $white;
    letter-spacing: -1px;
    font-size: 36px;
    font-weight: $font-weight-bold;
    width: 600px;
  }
  &__text {
    color: $white;
    font-size: $font-size-large;
    letter-spacing: -0.5px;
    width: 650px;
  }
  &__button {
    background-color: $billsby-red;
    border: 1px solid $white;
    border-radius: 4px;
    letter-spacing: 0px;
  }
}