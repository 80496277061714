@import '../../../style/variables.scss';

.fixed-sidebar {
  width: $invoice-sidebar-width;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.5);

  &__header {
    height: 60px;
    padding: 0 $spacing-default;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $billsby-black;
    & > div {
      display: flex;
      align-items: center;
    }
    
    img {
      object-fit: contain;
      width: 70px;
      max-height: 36px;
    }
    i {
      border: 1px solid $small-text-grey;
      color: $small-text-grey;
      font-size: $font-size-large;
      padding: 8px 10px;
      cursor: pointer;
      border-radius: 4px;
    
      &.fa-arrow-left {
        margin-right: $spacing-small;
      }

      &.light-icon  {
        border: 1px solid $white;
        color: $white;
      }
    }
  }
  &__body {
    background-color: $white;
    padding: 0 $spacing-default;
    padding-top: $spacing-default;
    height: calc(100% - 105px);
    overflow: auto;
  }

  &__footer {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(238, 240, 246);
    bottom: 0;
    left: 0;
    height: 45px;
    width: 100%;
    i {
      margin-right: $spacing-tiny;
      margin-bottom: 2px;
      font-size: 10px;
      opacity: .7;
    }
  
    .cc-text {
      font-size: 11px;
      margin-right: 5px;
      opacity: .7;
    }
  
    img {
      height: 14px;
      margin-top: 2px;
    }
  }
}