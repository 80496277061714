@import '../../../style/variables.scss';

.step-addon-cost {

  &__list {
    i.fa-map-marker-exclamation {
      color: $billsby-orange;
    }
    .step-cpanel__helpmenu--top {
      margin-bottom: $spacing-small;
    }
    .tabbed-container {
      padding-left: 0;
      margin-bottom: $spacing-small;
    }

    @mixin commonElStyle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px $spacing-default;
      margin-bottom: $spacing-small;
    }

    &-notConfiguredEl {
      @include commonElStyle();
      & > *:first-child {
        & > * { color: $billsby-orange }
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      & > button {
        height: 38px;
      }
    }

    &-configuredEl {
      @include commonElStyle();
      height: 52px;
      & > *:first-child {
        padding-right: $spacing-default;
        display: flex;
        align-items: center;
        & > *:nth-child(1) {
          color: $billsby-black;
          margin-right: 30px;
        }
        .border {
          border-right: 1px solid $billsby-orange;
          height: 29px;
          margin-right: $spacing-small;
        }
      }
      i.fa-edit {
        color: $billsby-black;
        cursor: pointer;
      }
    }

    &-pagination {
      width: fit-content;
      margin-left: auto;
    }
  }


  &__configurePanel {
    .menu-panel__content {
      padding: 0;
      width: 100%;
      & .content-panel:first-of-type {
        margin-bottom: 0;
      }
    }
    .price-label {
      display: flex;
      align-items: center;
      & > *:first-child {
        //margin-right: 8px;
      }
      & > *:last-child {
        text-align: right;
      }
    }

    .cc-input {
      display: block;
    }

    &-includedunits {
      height: 41px;
      margin-bottom: $spacing-small;
      .switch {
        padding-bottom: $spacing-small;
      }
    }

  }

}

table.step-addon-cost__list {
  width: 100%;
  margin-bottom: 0px;

  tr {
    margin-bottom: 0px;
  }

  td {
    padding: 0px !important;
    justify-content: start !important;

    .btn-add {
      margin-left: auto;
      span, i {
        color: $billsby-black
      }

      &:hover {
        span, i {
          color: $white
        }
      }
    }

    i.fa-edit {
      margin-left: auto;
    }
  }
}