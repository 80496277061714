@import '../../../style/variables.scss';
@import '../../../style/mixin/breakpoint.scss';

.navigation-alert {
  @mixin btn-style($color: $white, $opacity: 0.1) {
    button#navigation-alert-btn {
      border: 0px;
      color: $color;
      outline-color: $color;
      background-color: rgba($color, $opacity);
  
      &:hover {
        color: $color;
        outline-color: $color;
      }
    }

  }

  @mixin backgrounds() {
    &--success {
      background-color: $billsby-green;
      @include btn-style();
    }
    &--warning {
      background-color: $billsby-orange;
      @include btn-style();

    }
    &--error {
      background-color: $billsby-red;
      @include btn-style();
    }

    &--info {
      background-color: $billsby-muted-gray;
      @include btn-style($billsby-black, 0);
      .cc-text {
        color: $billsby-black;
      }
    }

    &--default {
      background-color: $billsby-black;
      @include btn-style($white, 0);
    }
  }

  height: 66px;
  display: flex;
  align-items: center;
  padding: 0 $spacing-default 0 $spacing-default;
  //border is useful when we stack more navigation alerts one on top of the other
  border-bottom: 1px solid #f2f6fc;

  &__title {
    font-size: $font-size-medium;
    letter-spacing: 0.5px;
    color: $white;
    font-weight: $font-weight-medium;
  }

  &__subTitle {
    font-size: $font-size-medium;
    color: $white;
  }

  &__btn {
    font-family: $billsby-font-family;
    border: 1px solid $white;
    color: $white;
    min-width: 120px;
    font-size: $font-size-medium-regular;
    position: absolute;
    right: 0;
    @include backgrounds();
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: $max-wrapper-width;
    $spacing: calc(2* #{$spacing-default});
    @include breakpoint($minimum-supported-width) {
      width: calc(#{$max-wrapper-width} - #{$spacing});
    }
  }

  @include backgrounds();
}