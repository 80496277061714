@mixin transition($type: left, $time: 200ms, $effect: ease){
  -webkit-transition: $type $time $effect;
  -moz-transition: $type $time $effect;
  -o-transition: $type $time $effect;
  transition: $type $time $effect;
  
}

//enable HW acceleration for better to avoid sloppy transition
@mixin translate($percentage, $time, $type: ease, $axis: 'x'){
  @if($axis == 'x') {
    -webkit-transform: translateX($percentage);
    transform: translateX($percentage);
  }
  @else {
    -webkit-transform: translateY($percentage);
    transform: translateY($percentage);
  }
  
	transition: transform $time $type;
	will-change: transform;
}