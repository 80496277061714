@import '../../../style/variables';

.feature-tags {
  position: relative;
  border-radius: 1px;

  /* shared font styles */
  font-size: 13px;
  line-height: 1.2;

  &__items {    
    display: inline;

    &-tag {
      display: inline-block;
      box-sizing: border-box;
      margin: 0 6px 6px 0;
      padding: 6px 8px;
      border: 1px solid $billsby-orange;
      border-radius: 2px;
      background: rgba(254, 127, 45, 0.2);
      color: $billsby-orange;
    
      /* match the font styles */
      font-size: inherit;
      line-height: inherit;

      &-delete::after {
        content: '\2715';
        color: $billsby-orange;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}
