@import '../../../../../style/variables.scss';

.email-invoices-tabs {
  border-radius: 4px;
  overflow: hidden;
  & > .tabbed-container:first-of-type {
    padding: 0px;
    background-color: #252422e6;
    justify-content: center;
    padding-top: $spacing-medium;

    a {
      color: $white;
      min-width: unset;
      margin: 0px $spacing-small;
      &.active,
      &:hover {
        border-bottom: 2px solid $white;
        font-weight: bold;
      }
    }
  }
}
