@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';
@import '../../../style/mixin/border-radius.scss';

.create-plan {
  &__heading {
    @include panel-bg-img('../../../images/productPlanCycle/create_plan_billsbot.svg');
    background-size: 236px;
    background-position-y: -38px;
    background-position-x: 104%;
  }

  &__description {
    margin-top: $spacing-default;
  }

  &__basic-form {

    &__switch.switch__container input:not(:checked) + .switch__slider, .switch__off {
      background-color: $billsby-muted-gray !important;
    }

    &__switch.switch__container input:checked + .switch__slider, .switch__on {
      background-color: $billsby-black !important;
    }
  }

  &__file-uploader {
    &__browse {
      text-decoration: underline;
      cursor: pointer;
    }

    flex-grow: 1;
    margin-bottom: $spacing-default;
    & .file-upload {
      margin-bottom: 0;
      padding: 0;
      & .file-upload__container {
        & > .file-upload__text {
          margin: $spacing-default;
          margin-left: 0;
        }

        & > .file-upload__progress_indicator {
          padding-right: 120px;
          width: calc(100% - 120px);
        }
      }
      & .file-upload__container .file-upload__logo {
        background-color: $white;
        border-width: 1px;
        border-style: dotted;
        background-clip: padding-box;
        border-radius: 0;
        margin-right: $spacing-default;
        width: 100px;
        height: 104px;
        &:before {
          color: $billsby-black;
          content: "\f1c5";
          font-size: 50px;
        }
      }
    }
  }

  &__error-contianer {
    margin-top: $spacing-default;
  }

  &__coming-soon {
    .step-cpanel {
      &__body {
        width: 100%;
      }

      &__helpmenu {
        display: none;

        &__collapsed-icon {
          display: none;
        }
      }
    }

    &__placeholder {
      @include panel-bg-img('../../../images/billing-welcome.png', center);
      // background-size: 800px 491px !important;
      height: 350px;
      width: 100%;
      margin-bottom: -$spacing-default;
    }
  }

  &__pricingmodel {
    &-group {
      display: inline-flex;
      min-width: 295px;
      button {
        position: absolute;
        right: 100px;
        bottom: 13px;
        height: 36px;
        width: 100px;
        z-index: 99;
      }
    }

    &.step-cpanel__locked {
      // if the pricing model panel is locked we set everything opaque a part from the undo button
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.4);
      box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.3);

      
      .step-cpanel__completed, .step-cpanel__title__text--fullwidth,
      .step-cpanel__subtitle::before, .element-modifiers, .circle-icon, 
      .step-cpanel__title__text--expanded {
        opacity: .4 !important;
      } 
      
      .create-plan__pricingmodel-group {
         .cc-text, &.cc-text {
          opacity: .4;
        }
      }
    }
  }
}

.dusty-orange {
  color: $billsby-orange;
}
