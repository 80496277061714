@import '../../../style/variables.scss';

.currency-input-tab {
  width: 100%;

  &__currency-error {
    font-size: $font-size-regular;
    margin-right: 5px;
    color: $billsby-red;

    &-label {
      color: $billsby-orange;
    }
  }

  &__content {
    padding-top: $spacing-default;
  }

  &__symbol.cc-form-label {
    text-align: right;
  }

  .tabbed-container {
    padding: 0px;
    box-shadow: inset 0px -10px 0px -8px rgb(216, 216, 216);
  }
}
