@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';
@import '../../../style/mixin/box-shadow.scss';

.addons-welcome {
  &__greeting {
    @include panel-bg-img('../../../images/allowances/add-ons.svg', right);
    background-size: 510px;
    background-position-y: -20px !important;
    background-position: right;
    background-position-x: 116%;

    @include breakpoint(1580px) {
      background-position-x: 106%;
      background-position-y: -31px !important;
    }

    display: flex;
    flex-direction: column;

    &__text {
      width: 58%;

      &-inline.cc-text{
        display: inline;
      }

      &-link.cc-text{
        cursor: pointer;
        text-decoration: underline;
        color: $billsby-black;
      }
    }

    &-btn {
      display: block;
      margin-top: $spacing-default;
      width: 346px;
    }
  }
}