@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';


.add-customer {
  &__email {
    color: $billsby-black;
  }

  &__title {
    &-panel {
      @include panel-bg-img("../../images/customers/741691_billsbot_make_up_artist_illustration.svg", right);
      background-size: 255px;
      background-position-y: -15px;
      background-position-x: 107%;
    }

    &-text {
      width: 80%;
    }
  }

  .custom-select__placeholder {
    font-size: $font-size-medium;
  }
  
  .custom-select__menu-list {
    max-height: 200px;
  }
}
