@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.two-factor-auth-login {
  &__heading {
    @include panel-bg-img('../../../images/2fa.svg', right);
    background-size: 332px;
    background-position-x: 104%;
    background-position-y: center;

    &-link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__six-digits {
    display: flex;
    align-items: center;
    .cc-text {
      margin-right: $spacing-default;
    }

    div {
      display: flex;
      align-items: center;
      .cc-input__field, .cc-input__field::placeholder {
        font-size: 20px;
        letter-spacing: 4px;
        
        
      }
      .cc-input {
        max-width: 83px;
        margin-right: $spacing-small;
      }
      .cc-input__field {
        &::placeholder {
          text-align: center;
          color:  $billsby-light-grey;
        }
      }
    }

  }
}