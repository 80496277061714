@import '../../../../style/variables.scss';
@import '../../../../style/mixin/scrollbar.scss';

.dropdown-options {

  &__list {
    max-height: 300px;
    overflow: hidden auto;
    @include scrollbar();
  }

  &__draggable-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-small;

    div {
      display: flex;
      align-items: center;

      i {
        margin-right: $spacing-small;
        cursor: pointer;
        font-size: 15px;
        color: $billsby-black;
        &.element-modifiers__delete {
          color: $billsby-red;
        }
      }
      *:nth-child(2) {
        font-size: 15px;
        color: $billsby-black;
      }
    }
  }

}