@import '../../style/variables';
@import '../../style/mixin/panel-bg-img.scss';

.invalid-role {
  max-width: $max-wrapper-width;
  padding: $spacing-default;

  &__panel {
    @include panel-bg-img('../../images/billsbot-cancel.svg', right);
    background-position-y: center;
    background-size: 325px;
    background-position-x: 104%;
  }
}

.modal__body {
  .invalid-role {
    padding: 0px;

    &__panel {
      background-size: 250px;
      background-position-x: 115%;

      p {
        width: 80%;
      }
    }
  }
}