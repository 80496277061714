@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.link-addons-allowances {
  .tabbed-container {
    padding: 0px;
    box-shadow: inset 0px -10px 0px -8px rgb(216, 216, 216);
    margin-bottom: $spacing-default;
  }

  .cpanel {
    max-height: 100%;

    &.cpanel--collapsed {
      max-height: 65px;
    }
  }

  &__empty-screen  {
    &-container > .link-addons-allowances__empty-screen--addons {
      @include panel-bg-img('../../../images/embed_codes/sweet_shop.svg');
    }
    &-container > .link-addons-allowances__empty-screen--allowances {
      @include panel-bg-img("../../../images/allowances/allowances.svg");   
      background-size: 260px;
      background-position-y: -6px;
      background-position-x: 101%;
    }
    padding-right: 250px;
    padding-bottom: $spacing-default;
    margin-bottom: -$spacing-default;
  }

  &__select_add_ons {
    display: flex;
    align-items: center;
    padding: 0px;
    max-width: $max-wrapper-width;

    &__pagination {
      margin-left: $spacing-default;
    }

    &__list {
      margin-top: $spacing-default;
      margin-bottom: 0px;
      padding: 12px $spacing-default;

      & > div {
        display: flex;
        align-items: center;
      }

      @at-root #{&}--new-price-models-configured {
        .link-addons-allowances__select_add_ons__name--not-configured {
          color: $billsby-black;
          border-bottom: 1px solid $billsby-black;

          .cc-text {
            color: $billsby-black;
          }
        }


      } 
    }

    &__table {
      width: 100%;
      margin-bottom: 0px;

      td {
        &:last-child {
          justify-content: flex-start !important;
          .btn-add {
            margin-left: auto;
          }
        }
      }
    }

    &__cycle {
      @at-root #{&} {
        &--not-configured {
          .cc-text {
            color: $billsby-orange
          }

          i.fa-map-marker-exclamation {
            color: $billsby-orange;
            font-size: $font-size-medium-regular;
            margin-right: $spacing-tiny;
          }
        }
      }
    }

    &__arrow {
      color: $billsby-orange;
      margin-right: $spacing-tiny;
      font-size: $font-size-medium-regular !important;
      cursor: pointer;
    }

    &__name {
      display: flex;
      align-items: center;

      @at-root #{&} {
        &--not-configured {
          color: $billsby-orange;
          border-bottom: 1px solid $billsby-orange;

          i {
            padding-right: $spacing-tiny;
            font-size: $font-size-medium-regular;
          }
          .cc-text {
            color: $billsby-orange;
          }
        }
      }
    }

    &__expanded-wrapper {
      padding: $spacing-default;
      background-color: $body-bg;
      border-radius: 2px;
      box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
      width: 100%;
      margin-top: $spacing-default;
    }

    &__delete {
      color: $billsby-red;
      cursor: pointer;
      margin-left: $spacing-default;
    }

    &__commands {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }
}
