@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/panel-bg-img.scss';
@import '../../../style/mixin/breakpoint';

.ppc-welcome {
  &__content {
    padding-right: 267px;
    margin-bottom: $spacing-default;
    width: 76%;
  }

  &__tooltip-text {
    span {
      color: $billsby-black;
      text-decoration-style: dashed;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  &__explain {
    @include breakpoint(1088px) {
      @include panel-bg-img('../../../images/products-explain.svg', right);
      background-size: 570px;
      background-position-y: center;
      background-position-x: 108%;
    }

    &.cpanel-arrow--collapsed {
      background-image: none;
    }
  }

  .accordion {
    max-width: 575px;

    &__body {
      background-color: #FFF;
    }
  }

  .tooltip--blue {
    max-width: 460px;
  }

  &__first-product {
    background-color: $billsby-super-light-gray;
    display: flex;
    justify-content: center;
  }
}