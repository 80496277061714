@import '../../../../style/variables.scss';
@import '../../../../style/mixin/panel-bg-img.scss';

.information-fields-config {

  &__header {
    @include panel-bg-img("../../../../images/information-fields.svg", right);
    background-size: 204px;
    background-position-x: 102%;
    background-position-y: center;
    
    .cc-text {
      max-width: 80%;
    }
  }
}