@import "../../../style/variables.scss";
@import "../../../style/mixin/panel-bg-img.scss";
@import "../../../style/mixin/scrollbar.scss";

.create-product {
  &__heading {
    @include panel-bg-img("../../../images/productPlanCycle/create_product_billsbot.svg");
    background-size: 236px;
    background-position-y: -45px;
    background-position-x: 104%;
  }
  // .step-cpanel--fullwidth:last-of-type .step-cpanel__helpmenu--right {
  //   overflow-y: hidden !important;
  // }

  &__description {
    margin-top: $spacing-default;
  }

  &__file-uploader {
    &__browse {
      text-decoration: underline;
      cursor: pointer;
    }

    flex-grow: 1;
    margin-bottom: $spacing-default;
    & .file-upload {
      margin-bottom: 0;
      padding: 0;
      & .file-upload__container {
        & > .file-upload__text {
          margin: $spacing-default;
          margin-left: 0;
        }
        & > .file-upload__progress_indicator {
          padding-right: 120px;
          width: calc(100% - 120px);
        }
      }
      & .file-upload__container .file-upload__logo {
        background-color: $white;
        border-width: 1px;
        border-style: dotted;
        background-clip: padding-box;
        border-radius: 0;
        margin-right: $spacing-default;
        width: 100px;
        height: 104px;
        &:before {
          color: $billsby-black;
          content: "\f1c5";
          font-size: 50px;
        }
      }
    }
  }

  &__error-contianer {
    margin-top: $spacing-default;
  }

  textarea {
    border-radius: 0px;
  }

  &__currency {
    position: relative;
    padding-top: $spacing-medium;

    .cc-text {
      font-size: 14px;
      color: $billsby-black;
    }

    &__locked {
      .cc-text {
        font-size: $font-size-medium;
        color: $billsby-black;
      }

      &::after {
        content: "\f023";
        font-family: "Font Awesome 5 Pro";
        font-size: 14px;
        font-weight: 600;
        color: $billsby-red;
        position: absolute;
        right: 0;
        top: 17px;
      }
    }
  }

  &__basic-form {

    &__switch.switch__container input:not(:checked) + .switch__slider, .switch__off {
      background-color: $billsby-muted-gray !important;
    }

    &__switch.switch__container input:checked + .switch__slider, .switch__on {
      background-color: $billsby-black !important;
    }
  }

  &__info {
    font-size: $font-size-medium;
    letter-spacing: 0;
    line-height: normal;
    color: $billsby-black;

    i {
      color: $billsby-red;
      font-size: 14px;
      margin: 0 $spacing-tiny;
    }
  }

  &__info-body {
    &:not(.cpanel--collapsed) {
      //@include scrollbar();
      //overflow-y: auto;
      // max-height: 1060px;
    }
    &__fields-container {
      //max-height: 10500px;
      margin-bottom: $spacing-default;
      padding: 4px;
      
      & > .step-cpanel--fullwidth:last-of-type .content-panel, & div[data-react-beautiful-dnd-draggable]:last-of-type .content-panel {
        margin-bottom: 0;
      }

    }

    &--opacity {
      opacity: 0.4;
    }

    &--without-opacity {
      opacity: 1;
    }

    &--hidden {
      display: none;
    }
    
    .step-cpanel__body .step-cpanel__title {
      .cc-text {
        font-size: $font-size-regular;
        font-family: "Roboto";
      }

      .step-cpanel__subtitle {
        font-size: $font-size-small;
        font-family: "Roboto";
      }
    }

    &__title {
      position: absolute;
      top: 22px;
      right: 16px;

      i {
        margin: 0 $spacing-tiny;
        font-size: 14px;
        color: $billsby-black;

        &:last-child {
          color: $billsby-red;
        }
      }
    }

  }

  &__draggable-panel {
    i.fa-grip-horizontal {
      position: absolute;
      right: 22px;
      bottom: 22px;
      cursor: pointer;
      font-size: 15px;
      color: $billsby-black;
    }
  }
}
