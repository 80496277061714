@import '../../../style//variables.scss';

.navigation-bar {
  background-color: $white;

  &__wrapper {
    max-width: $max-wrapper-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-small $spacing-default;
  }

  &__previouspage {
    display: flex;
    align-items: center;
    & *:nth-child(1) {
      font-size: 12px;
      margin-right: $spacing-small;
    }

    & *:nth-child(2) {
      text-decoration: underline;
      cursor: pointer;
    }

    i {
      color: $billsby-black;
    }
  }
}
