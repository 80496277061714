@import '../../style/variables.scss';

.payment-details-form-checkout {
  .cc-form-label > label {
    font-weight: bold;
    font-size: $font-size-small;
    color: $billsby-orange;
  }

  &__spreedly-input {
    font-family: $billsby-font-family;
    flex: 1;
    outline: none;
    color: $billsby-black;
    padding: $spacing-tiny $spacing-tiny $spacing-tiny $spacing-tiny;
    border: solid 1px $billsby-black;
    background-color: $white;
    height: 30px;
    font-size: $font-size-small;
    max-width: 100%;
    &--error {
      border: 1px solid $billsby-red;
    }
  }

  &__error {
    background-color: $billsby-red;
    & > .cc-text {
      color: $white;
    }

    & > .cc-text:first-of-type {
      font-size: 16px;
      font-weight: bold;
    }
  }
}