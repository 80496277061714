/* recharts overrides for billsby */

.recharts {
  &-rectangle.recharts-tooltip-cursor {
    display: none;
  }
  &-cartesian {
    &-grid {
      &-vertical {
        line {
          // hide horizontal lines
          display: none;
        }
      }

      &-horizontal {
        line {
          // remove dashes from horizontal lines
          color: #e5e7ea;
        }
      }
    }

    &-axis-tick-value {
      fill: $billsby-black;
    }
  }

}