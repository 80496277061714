@import '../../../style/variables.scss';

.checklist {
  &__element {
    &:last-of-type {
      margin-bottom: 0;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    height: 47px;

    & div:first-of-type {
      display: flex;
      align-items: center;
      margin-left: $spacing-default;
      .cc-text {
        font-size: 15px;
      }
      
      i {
        font-size: $font-size-regular;
        margin-right: $spacing-small; 
      }
      i.fa-check-circle {
        color: $billsby-green;
        & + .cc-text {
          color: $billsby-green;
        }
      }
      i.fa-times-circle {
        color: $billsby-red;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      color: $billsby-green;
      font-size: $font-size-medium-regular;
      background: rgba(59, 155, 27, 0.2);
      border: 1px solid rgb(59, 155, 27);
      letter-spacing: 0;
      width: 120px;
      margin-right: $spacing-small;
      height: 36px;
      padding: 0;

      i {
        font-size: $font-size-medium-regular;
        color: $billsby-green 
      }

    }
  }
}