@import '../../../../style/variables.scss';
@import '../../../../style/mixin/panel-bg-img.scss';
@import '../../../../style/mixin/font-styles.scss';

.pause-cancel-subscription {
  .tabbed-container {
    box-shadow: inset 0px -10px 0px -8px rgb(216, 216, 216);
    margin-bottom: $spacing-default;
    padding-left: 0px;
  }

  &__welcome {
    @include panel-bg-img("../../../../images/billsbot-cancel.svg", right);
    background-size: 204px;
    background-position-x: 102%;
    background-position-y: center;
    .cc-text {
      max-width: 80%;
    }
  }

  &__country-back {
    cursor: pointer;
    display: flex;
    align-items: center;

    h6 {
      margin-bottom: 0px;
      @include heading-font-style();
    }

    i {
      font-size: $font-size-huge;
      margin-right: $spacing-small;
      color: $billsby-black;
    }
  }

  .interactive-list__element {
    padding: $spacing-medium $spacing-default;

    i {
      margin-right: 0px !important;
    }

    &__subTitle {
      font-size: $spacing-small
    }
  }

  
}
