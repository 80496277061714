@import '../../../../../style/variables.scss';

.cancel-subscription-config {
  &__draggable-container {
    .step-cpanel--fullwidth {
      padding-bottom: $spacing-default;
    }
  }

  &__draggable-panel {
    margin-bottom: 0px;
    padding: $spacing-medium $spacing-default;

    & > div {
      height: 100%;
      div {
        height: 100%;
      }
    }

    .step-cpanel__title {
      margin-bottom: 0px;
      &__text--fullwidth {
        width: 100%;
      }
    }

    &__content {
      display: flex;
      align-items: center;

      .cc-text {
        font-family: 'Roboto', 'Roboto Slab' !important;

        &.title {
          font-size: $font-size-regular;
        }

        &.sub-title {
          font-size: $font-size-small;
        }
      }

      .drag {
        margin-right: $spacing-default;
      }

      .delete {
        margin-left: auto;
        color: $billsby-red;
      }

      .switch__container {
        margin-right: 0px;

        input:not(:checked) + .switch__slider {
          background-color: $billsby-muted-gray;
        }
      }
    }

    &__action {
      display: flex;
      align-items: center;
      margin-left: auto; 
      i {
        margin-right: $spacing-medium;
      }
    }
  }
}
