@import "../../../../../style/variables.scss";
@import "../../../../../style/mixin/panel-bg-img.scss";
@import "../../../../../style/mixin/border-radius.scss";

$header-height: 60px;
$navigationbar-height: 28px;
$footer-height: 45px;

.checkout-preview {
  &__container {
    height: 540px;    
  }

  &__header {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: $header-height;
    background: $billsby-black;
    border-top-left-radius: 4px;

    &__logo {
      height: 27px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__navigation-bar {
    position: absolute;
    z-index: 2;
    top: calc(#{$header-height});
    display: flex;
    justify-content: center;
    align-items: center;
    height: $navigationbar-height;
    width: 100%;
    background-color: $billsby-orange;

    .navigation-bar__el {
      font-family: "Roboto";
      font-size: $font-size-small;
      color: $white;
      opacity: 0.5;
      margin-right: 12px;

      &::after {
        position: relative;
        font-family: "Font Awesome 5 Pro";
        content: "\f061";
        left: 6px;
        opacity: 0.5;
        font-size: 8px;
      }
      &:last-child::after {
        content: "";
      }

      &--current {
        opacity: 1;
      }
    }
  }

  &__payment-summary {
    position: absolute;
    left: 10px;
    width: calc(100% - 20px);
    margin-top: $spacing-default;
    margin-bottom: $spacing-default;
    top: calc(#{$header-height} + #{$navigationbar-height});

    &__text {
      &-small {
        font-size: $font-size-tiny;
        color: $billsby-muted-gray;
        display: inline;
        &.underlined {
          text-decoration: underline;
        }
      }

      &-title {
        font-family: "Roboto";
        font-weight: bold;
        font-size: $font-size-large;
        color: $billsby-orange;
      }
    }

    &__continue {
      width: 100%;
      margin-top: $spacing-default;
      padding-top: $spacing-default;
      text-align: center;
    }

    &__button {
      &-orange {
        background-color: $billsby-orange;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: "Roboto";
        color: #fff;
        text-transform: uppercase;
        box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
        min-width: 200px;
        min-height: 40px;
        border-width: 0;
        border-radius: 0;

        :after {
          font-family: "Font Awesome 5 Pro";
          content: " \f061";
        }
      }
    }

    .cc-checkbox > .cc-checkbox-general__icon {
      color: $billsby-orange;
    }
  }

  &__terms {
    & .cc-text {
      display: inline;
      &.underlined {
        text-decoration: underline;
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $footer-height;
    background-color: $billsby-super-light-gray;
    @include border-radius();
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    margin-top: $spacing-default;

    i {
      margin-right: $spacing-tiny;
      font-size: 10px;
      opacity: 0.7;
    }

    &__text {
      font-size: 11px;
      margin-right: 5px;
      opacity: 0.7;
    }

    &__logo {
      height: 14px;
      margin-top: 2px;
    }
  }
}
