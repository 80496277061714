@import '../../../style/variables.scss';

.cards-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 71px;
  background-color: $billsby-super-light-gray;

  i {
    font-size: 36px;
    margin-right: $spacing-small;
    color: #606161;
  }
}