@import '../../style/variables';
@import '../../style/mixin/breakpoint.scss';

.company-selector {

  @include breakpoint(0, $mobile-width) {
    padding: 0 $spacing-default;
  }

  .content-panel {
    margin-bottom: $spacing-small;
  }
  
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    i {
      font-size: $font-size-large;
      color: $billsby-black;
    }
  }

  &__data {
    & *:nth-child(1) {
      
      font-size: $font-size-large;
      letter-spacing: -0.5px;
      color: $billsby-black;
      margin-bottom: $spacing-small;
    }
  }

  &__currency-mismatch {
    margin: -#{$spacing-default};
    margin-top: $spacing-default;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}