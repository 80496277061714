@import '../variables';

@mixin body-font-style {
  font-size: $font-size-medium;
  font-weight: $font-weight-regular;
  color: $billsby-black;
}

@mixin modal-font-style {
  font-size: 17px;
  font-weight: $font-weight-medium;
  color: $white;
}

@mixin heading-font-style {
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
  color: $billsby-black;
}

@mixin subheading-font-style {
  font-size: $font-size-regular;
  font-weight: $font-weight-bold;
  color: $billsby-black;
}

@mixin button-font-style($color: $white) {
  font-size: $font-size-medium;
  font-weight: $font-weight-medium;
  color: $color;
}

@mixin dropdown-font-style($weight: $font-weight-regular, $color: $billsby-gray) {
  font-size: $font-size-small;
  font-weight: $weight;
  color: $color;
}

