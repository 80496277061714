@import '../../../../../style/variables.scss';

.interactive-menu-panel {
  section.interactive-list {
    i.far {
      margin-right: 0px !important;
    }
  }

  .interactive-list__element {
    &__title {
      color: $billsby-black;
      font-size: $font-size-regular;
    }

    &__subTitle {
      font-size: $font-size-tiny;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-medium;

    &__back {
      color: $billsby-black;
      font-size: $font-size-large;
      cursor: pointer;
    }

    &__title {
      &.cc-text {
        font-size: $font-size-large;
        color: $billsby-black;
    
        letter-spacing: -0.5px;
        margin-left: $spacing-small;
      }
    }

    .status-label {
      margin-left: $spacing-medium;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__content {
      width: 60%;
      margin-right: auto;

      &__title {
        font-size: $font-size-regular;
        color: $billsby-black;
      }

      &__subtitle {
        font-size: $spacing-small;
      }

    }

    button.button {
      margin-left: $spacing-medium;
      min-width: 165px;
    }
  }

  &__cards {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-default;
  }

  &__plus {
    border-radius: 50%;
    flex: 0 0 25px;
    height: 25px;
    margin: 0px $spacing-medium;
    color: $white;
    text-align: center;
    padding-top: 4px;
    background-color: $billsby-black;
  }

  &__card {
    flex: 0 0 285px;
    background-color: #F3F5FB;
    padding: $spacing-medium;
    height: 108px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content-panel-title {
      color: $billsby-black;
      font-size: 20px !important;
      letter-spacing: -1px;
      margin-bottom: $spacing-tiny;
    }

    .cc-text {
      font-size: $font-size-medium;
      color: $billsby-black;
    }
  }

  &__info {
    .status-label {
      margin-bottom: $spacing-tiny;
    }
    margin-left: $spacing-default;
    .cc-text {
      color: #8D8D8D;
    }
  }
}