@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/transition.scss';
@import '../../../style/mixin/scrollbar.scss';
@import '../../../style/mixin/fade-out.scss';

.cc-table {
  display: block;
  background: $white;
  @include border-radius($billsby-muted-gray);
  margin-bottom: $spacing-default;
  .btn-basic {
    height: 36px;
  }

  &--hover-effect {
    tbody {
      tr {
        &:not(.disabled) {
          transition: ease 300ms background-color;
          &:hover {
            background-color: $billsby-super-light-gray;
          }
        }
        &.disabled {
          cursor: default !important;
        }
      }
    }
  }

  thead,
  tbody {
    display: grid;
  }
  tbody {
    //show the scroll bar for responsiveness
    //overflow-x: auto;
    tr:last-of-type {
      border-bottom: none;
    }
  }

  tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $billsby-muted-gray;
    max-height: 52px;

    button,
    .button {
      padding: 3px $spacing-default;
    }

    &.disabled {
      td {
        &.view-arrow {
          visibility: hidden;
          pointer-events: none;
        }
      }
    }

    //common rules
    td,
    th {
      display: flex;
      flex: 1;
      //width: 80%;
      align-items: center;
      padding: $spacing-default;
      min-width: 100px;
      word-break: break-word;

      &.view-arrow {
        justify-content: center !important;
        min-width: unset;
        flex: 0 1 75px;
        padding-right: 0px !important;

        a {
          white-space: nowrap;
          font-size: $font-size-large ;
        }
      }
    }

    td {
      font-size: 13px;
      color: $billsby-black;
      //line-height: 1.46;
      &,
      & > * {
        word-break: break-word;
      }
    }

    //last data child align to the right
    th:last-child,
    td:last-child {
      justify-content: flex-end;
    }

    th,
    th .cc-text {
      font-weight: $font-weight-medium;
      font-size: 11px;
      color: $billsby-black;
      letter-spacing: $letter-spacing-medium;
      text-transform: uppercase;
    }
  }

  a {
    text-decoration: underline;
    // color: $billsby-black;
  }

  &-emptyset {
    margin: $spacing-default;
    min-width: 200px;
  }

  /* EXPANDABLE VERSION */
  &--expandable {
    tbody {
      //tbody width will fit the content -> needed in case of horizontal overflow
      display: contents;
    }

    // tr:nth-last-child(2) {
    //   border-bottom: none;
    // }

    tr {
      width: 100%;
      position: relative;

      td:first-of-type {
        &:before {
          content: '\f107';
          font-family: 'Font Awesome 5 Pro';
          font-size: 16px;
          line-height: 1.19;
          color: $billsby-black;
          margin-right: $spacing-default;
          cursor: pointer;
        }
      }

      &.expanded {
        //hide at the beginning
        @include transition(min-height, 200ms);
        //@include transition(opacity, 1250ms);
        //@include transition(opacity, 150ms);
        opacity: 0;
        //max-height: 0;
        height: 0;
        min-height: 0;
        td {
          overflow-x: auto;
          overflow-y: hidden;
          width: 100%;
          justify-content: flex-start;
          &::before {
            display: none;
          }
        }
      }

      &.not-expandable {
        td:first-of-type {
          &:before {
            content: '';
            margin-right: 0;
          }
        }
      }
    }

    &__rowExpanded {
      td:first-of-type {
        &:before {
          content: '\f106' !important;
        }
      }

      & + .expanded {
        opacity: 1 !important;
        padding: $spacing-default;
        background-color: $background-grey;
      }

      & + .expanded--smooth {
        min-height: 150px !important;
        height: auto !important;
        max-height: none;
        //max-height: 160px !important;
      }
    }
  }
}

.scrollable-table-container {
  border: 1px solid $billsby-muted-gray;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  background-color: $white;
  margin-bottom: $spacing-default;
  z-index: 0;

  &::after {
    content: '';
    display: block;
    width: 200px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    pointer-events: none;
    z-index: 1;
    @include fade-out($white);
    opacity: 0;
    transition: opacity 0.25s ease;
  }

  &.with-fade-out {
    &::after {
      opacity: 1;
    }
  }

  &__inner {
    overflow-x: auto;
    width: calc(100% - 200px);
    margin-left: 200px;
    @include scrollbar(2px, $billsby-super-light-gray, 4px, $billsby-black);

    .cc-table {
      margin-bottom: 0px;
      border: 0px;
      border-radius: 0px;
      width: 100%;
      display: table;
      table-layout: auto;
      border-collapse: collapse;

      thead {
        display: table-header-group;
      }
      tbody {
        display: table-row-group;
      }

      tr {
        max-height: 100%;
        display: table-row;

        td,
        th {
          display: table-cell;
          position: relative;
          text-align: left;
          z-index: 1;
          min-width: 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:last-child {
            justify-content: flex-start;
          }

          &.view-arrow {
            justify-content: center;
            min-width: unset;
            flex: 0 1 75px;
            padding-right: 0px;

            a {
              white-space: nowrap;
              font-size: $font-size-large ;
            }
          }

          // &:first-child {
          //   position: absolute;
          //   width: 200px;
          //   z-index: 2;
          //   left: 0px;
          //   border: inherit;
          //   background-color: $white;
          // }
        }
      }
    }
  }
}
