@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';


.golive {
  &__heading {
    @include panel-bg-img("../../images/billsby-rocket-moon.svg");
    background-size: 204px;
    background-position-x: 102%;
    background-position-y: center;

    .cc-text {
      width: 80%;
    }
  }

  &__sca-error {
    p {
      font-weight: bold;
      color: $billsby-red
    }
    margin-bottom: -$spacing-tiny;
  }

  &__checklist {
    background: $billsby-green;

    &__title {
      
      font-size: $font-size-large;
      color: $white;
    }
  }

  &__plans {
    & > *:nth-child(2) {
      margin-bottom: 5px;
    }
    & > *:nth-child(3) {
      color: #707070;
      margin-bottom: $spacing-default;
    }

    &__cards {
      display: flex;
      justify-content: center;

      & > .plan-price-card:first-child {
        margin-right: $spacing-default;
      }
    }
  }

  &__transactionfees {
    & > div:first-of-type {
      margin-bottom: $spacing-small;
    }

  }

  &__brand {
    & > div:nth-child(2), & > div:nth-child(3) {
      margin-bottom: $spacing-default;
    }
  }

  &__payment-details {
    &__form-element {
      display: flex;
      align-items: center;
      .dropdown__container {
        width: 100%;
      }

      .cc-form-label {
        max-width: 150px;
      }

      &--multiple {
        .cc-form-label {
          max-width: 60px;
        }
      }

      &--multipleinputs {
        & > .cc-input {
          max-width: 100px;
        }
        & > .cc-input:nth-child(2) {
          margin-right: $spacing-small;
        }
      }

    }
  }

  &__spreedly-card {
    position: relative;
    
    &-input {
      font-family: $billsby-font-family;
      flex: 1;
      outline:none;
      color: $billsby-black;
      padding: $spacing-small $spacing-medium $spacing-tiny $spacing-medium;
      border: solid 1px $input-border;
      background-color: $input-bg;
      height: 41px;
      width: 100%;
      font-size: $font-size-medium;
      
      &--icon {
        &.left {
          padding-left: $spacing-medium * 3;
        }
      }

      &::placeholder{
        color: rgba(33, 47, 55, 0.6);
      }

      &:hover {
        background-color: lighten($billsby-super-light-gray, 10%);
      }
    }

    &__error-border {
      border-color: $billsby-red;
    }

    &__error-message {
      position: absolute;
      right: $spacing-small;
      font-size: $font-size-small;
      color: $billsby-red;
    }
  }
  .bold {
    font-weight: bold;
  }

  .grey {
    color: $billsby-muted-gray;
  }

  &--opaque {
    position: relative;
    z-index: 2;
    opacity: .5;
    pointer-events: none;
  }

  &__success{
    &__headingpanel {
      @include panel-bg-img("../../images/billsby-rocket-moon.svg");
      background-size: 204px;
      background-position-x: 102%;
      background-position-y: center;
      
      & .cc-text {
        width: 80%;
      }
    }
    
    &__notice-panel {
      &.notice-panel {
        margin-bottom: 0px;

        .cc-text {
          color: $billsby-red;
          font-weight: $font-weight-bold;
        }
      }
    }

    &__headofproduct {
      display: flex;
      align-items: center;

      img {
        max-width: 40px;
        object-fit: contain;
        margin-right: $spacing-small;
      }
      & > div {
        display: flex;
        flex-direction: column;
        & > span:first-child {
          font-weight: bold;
        }
      }
    }

    &__conditions {
      color: $billsby-muted-gray
    }

    #golive-talk-to-us {
      width: 120px;
    }
  }

  p {
    //detect line breaks \n in counterpart inside en.json
    white-space: pre-line;
  }
}