@import '../../../style/variables.scss';
@import '../../../style/mixin/scrollbar.scss';

.side_navbar {
  height: 100%;
  min-width: 230px;
  padding: $spacing-default $spacing-small;

  &__content {
    display: flex;
    flex-direction: column;

    &-body {
      overflow-y: auto;
      height: 100%;
      @include scrollbar();

      &__item {
        margin: 0 0 $spacing-tiny 0;
        min-height: 27px;
        width: 100%;

        &--minimised {
          width: auto;
        }

        &--locked {
          * {
            cursor: default !important;
          }
          opacity: 0.2;
          pointer-events: none;
        }

        &:last-child {
          margin: 0;
        }

        a {
          text-decoration: none;
          font-size: 13px;
          color: $billsby-black;
          display: inline-block;
        }

        &-line {
          padding: 0 $spacing-small $spacing-tiny $spacing-small;
          width: 100%;
          border: none;
          background-color: transparent;
          text-align: left;

          &:hover {
            background-color: $billsby-super-light-gray;
            border-radius: 4px;
            cursor: pointer;
          }

          i {
            color: $billsby-black;
            width: $spacing-default;
            height: $spacing-medium;
            margin: 0 $spacing-tiny 0 0;
            text-align: center;
            font-size: 15px;
            padding-top: 6px;
          }
        }

        &-name {
          font-size: $font-size-medium;
          cursor: pointer;
          padding: $spacing-tiny;
          display: inline;
        }

        &-arrow {
          position: relative;
          width: 14px;
          height: 15px;
          float: right;
          margin-top: 6px;

          &::before,
          &::after {
            display: block;
            position: absolute;
            top: 50%;
            width: 10px;
            height: 2px;
            background-color: currentColor;
            content: '';
            transition: transform 0.25s ease;
          }

          &::before {
            left: 6px;
            transform: rotate(-45deg);
          }

          &::after {
            right: 4px;
            transform: rotate(45deg);
          }

          [aria-expanded='true'] &::before {
            transform: rotate(45deg);
          }

          [aria-expanded='true'] &::after {
            transform: rotate(-45deg);
          }
        }

        &-links {
          margin-top: $spacing-tiny;

          a {
            display: block;
            margin: 0;
            text-decoration: none;
            padding: $spacing-tiny;
            padding-left: 40px;
            color: $billsby-black;
          }

          a:hover {
            background-color: $billsby-super-light-gray;
            border-radius: 4px;
          }

          &--hidden {
            opacity: 0;
            display: none;
          }
        }

        a.link-locked {
          opacity: 0.2;
          cursor: default;
        }
      }
    }
  }
}
