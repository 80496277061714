@import '../../../style/variables';
@import '../../../style/mixin/panel-bg-img.scss';

.forgot-password {
  @include breakpoint(0, $mobile-width) {
    padding: 0 $spacing-default;
  }

  &-panel {
    @include panel-bg-img('../../../images/forgot-password.svg', right);
    background-position-y: -11px;
    background-size: 159px;
    background-position-x: 103%;


    @include breakpoint(0, $mobile-width) {
      background-position-y: -11px;
      background-size: 202px;
      background-position-x: 122%;
      .cc-text {
        max-width: 139px;
      }
    }
  }

  &-email {
    &_input-id {
      align-self: center;
    }

    @media (max-width: $mobile-width) {
      .cc-form-label {
        margin-bottom: $spacing-tiny;
      }
    }

    .password-check .cc-form-group:nth-child(2) {
      margin-bottom: 0;
    }
  }
  

  &-footer {
    display: flex;
    flex-direction: column;
  
    &_text {
      align-self: center;
      margin: $spacing-small 0 0 0 !important;
    }
  }
}