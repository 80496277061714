
@mixin breakpoint($sizeMin, $sizeMax: false){
  @if($sizeMin and $sizeMax) {
    @media screen and (min-width: $sizeMin) and (max-width: $sizeMax) {
      @content;
    }
  } 
  @else {
    @media screen and (min-width: $sizeMin){
      @content;
    }
  }
}
