@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';
@import '../../style/mixin/box-shadow.scss';

.coupons {
  &__greeting {
    height: 500px;
    @include panel-bg-img('../../images/coupons.png', center);
    background-size: 600px 340px !important;
    background-position: center !important;
    display: flex;
    flex-direction: column;

    &-btn {
      text-align: center;
      margin: auto -20px -20px -20px;
      background-color: $billsby-super-light-gray;
      border-top-left-radius: 0%;
      border-top-right-radius: 0%;
      @include box-shadow(none);
    }
  }
}