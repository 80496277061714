@import "../../../style/variables.scss";

.code-panel {
  display: flex;
  position: relative;
  background-color: $billsby-black;
  min-height: 75px;
  align-items: center;
  justify-items: left;

  &-button {
    position: absolute;
    top: 20px;
    right: 19px;
    display: flex;
    background-color: $billsby-black;
    color: $white;
    border-color: rgba($billsby-gray, 0.5);;
    font-size: 15px;
    font-family: $billsby-font-family;
    letter-spacing: normal;
    text-transform: none;
    padding: 7px 17px;

    span, i{
      opacity: 0.7;
    }
  }
}