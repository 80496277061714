@import '../../../../style/variables.scss';
@import '../../../../style/mixin/panel-bg-img.scss';

.company-details {
  &__subdomain {
    &-prefix {
      font-size: $font-size-medium-regular
    }
    &-change {
      text-decoration: underline;
      position: absolute;
      cursor: pointer;
      right: 0;
      right: -32px;
      top: 49%;
      transform: translate(-50%,-50%);
      color: $billsby-orange;
    }
    &-container {
      position: relative;
    }
  }


  &__heading {
    @include panel-bg-img('../../../../images/draw.svg', right);
    background-size: 204px;
    background-position-x: 103%;
    background-position-y: -16px;
  }

  &__address-lookup {
    &__label {
      margin-top: 13px;
    }
  }

  .custom-select__placeholder {
    font-size: $font-size-medium
  }

}