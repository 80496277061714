@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.info-label {
  position: relative;
  padding: 5px $spacing-default;
  @include border-radius();
  width: fit-content;
  
  &--error {
    color: $billsby-red;
    background-color: lighten($color: $billsby-red, $amount: 40%);
  }
  &--success {
    color: $billsby-green;
    background-color: lighten($color: $billsby-green, $amount: 40%);
    &::before {
      font-family: 'Font Awesome 5 Pro';
      content: "\f058";
      font-weight: bold;
      position: relative;
      left: -7px;
    }
  }
}