@import '../../../style/variables.scss';

.plans-list {
  &__wrapper {
    padding: $spacing-default;
    background-color: $body-bg;
    border-radius: 2px;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    width: 100%;
    margin-top: -$spacing-default;

    .spinner {
      div {
        background-color: $billsby-black;
      }
    }

    .button {
      .spinner {
        div {
          background-color: $white;
        }
      }
    }

    .step-cpanel--fullwidth {
      padding-bottom: $spacing-default;
    }
  }

  &__draggable-panel {
    margin-bottom: 0px;
    height: 46px;
    padding: 0px $spacing-default;

    & > div {
      height: 100%;
      div {
        height: 100%;
      }
    }

    .step-cpanel__title {
      margin-bottom: 0px;
      &__text--fullwidth {
        width: 100%;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cc-text {
        font-size: $font-size-regular !important;
      }
    }
  }

  &__table {
    width: 100%;
    margin-bottom: 0px;
    border-spacing: 0;

    &.cc-table {
      thead {
        tr {
          th {
            padding: $spacing-small $spacing-default !important;
          }
        }
      }

      thead,
      tbody {
        pointer-events: unset;
        tr {
          &:last-child {
            td {
              border-color: $white;
            }
          }

          max-height: 100%;
          border-bottom: 0px;
          th,
          td {
            flex: 1;
            text-align: left;
            padding: $spacing-small $spacing-default;
            border-bottom: 1px solid $billsby-light-grey;

            &:nth-child(1) {
              flex: 1 1 15%;

            }
            &:nth-child(2) {
              flex: 1 1 10%;
            }

            &:nth-child(3) {
              flex: 1 1 30%;
            }

            &:nth-child(4) {
              flex: 1 1 200px;
            }


            &:last-of-type {
              justify-content: flex-end;
              flex-grow: 1;
            }

            &.plans-list__table {
              &__cycles {
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
              }

              &__actions {
                font-size: $font-size-regular;

                span {
                  padding: 0 5px;

                  i {
                    cursor: pointer;
                  }
                }

                .plans-list {
                  &__edit {
                    color: $billsby-black;
                  }

                  &__lock,
                  &__delete {
                    color: $billsby-red;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
