@import '../../../style/variables';
@import '../../../style/mixin/border-radius';
@import '../../../style/mixin/box-shadow';


a.button {
  text-decoration: none !important;
}

.button {
  padding: $spacing-small;
  outline: none;
  cursor: pointer;
  font-weight: $font-weight-medium;

  span {
    font-weight: $font-weight-medium;
    font-family: $billsby-font-family;
    white-space: nowrap;
  }

  letter-spacing: $letter-spacing-default;

  &-icon {
    &.right {
      margin-left: 5px;
    }

    &.left {
      margin-right: 5px;
    }

    svg {
      font-size: 11px;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4 !important;
    pointer-events: all !important;

    &:hover {
      opacity: 0.4!important;
    }
  }
}

.btn {
  &-full {
    width: 100%;
  }

  &-basic {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-regular;
    height: 40px;
    background-color: $billsby-black;
    color: $white;
    @include border-radius(transparent);

    &:hover {
      opacity: .8;
    }
  }

  @mixin orangeBtn {
    color: $white;
    font-family: $billsby-font-family;
    font-weight: $font-weight-regular;
    font-size: $font-size-regular;
    background: $billsby-orange;
    border-radius: 0px;
    letter-spacing: 0px;
    box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.5);
    height: 40px;
  }

  &-orange {
    @include orangeBtn();
  }

  &-orangeBillsbyMarketing {
    @include orangeBtn();

    span {
      font-weight: $font-weight-regular;
    }

    font-size: $font-size-regular;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-disabled {
    opacity: .4 !important;
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &-custom {
    text-transform: uppercase;
    font-size: $font-size-small;
    @include border-radius($billsby-super-light-gray);
    color: $billsby-super-light-gray;
  }

  &-add {
    background-color: $white;
    color: $billsby-black;
    justify-content: center;
    outline: 1px solid $billsby-black;
    text-transform: none;
    font-size: 15px;
    font-family: $billsby-font-family;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    .spinner {
      div {
        background-color: $billsby-black;
      }
    }

    &:active:focus,
    &:active,
    &:active:hover,
    &:hover,
    &:focus:hover {
      outline: 2px solid $billsby-black;
      color: $billsby-black ;
      opacity: 1;

      .spinner {
        div {
          background-color: $billsby-black;
        }
      }
    }

    &:focus {
      color: $billsby-black;
    }
  }

  //light version of the button
  &-light {
    background-color: transparent;
    color: $billsby-black;
    @include border-radius($billsby-black);

    &:hover {
      outline: none;
    }

    &:active,
    &:active:hover,
    &:focus,
    &:active:focus {
      outline: none;
    }

    &[disabled] {
      cursor: not-allowed;
      pointer-events: all !important;
      //color: $billsby-super-light-gray;
      //background-color: $billsby-black;
      background-color: $billsby-gray;
      
    }
  }

  // inverted version of the button
  &-error {
    font-family: $billsby-font-family;
    outline: solid 1px $billsby-red;
    background-color: $white;
    color: $billsby-red;

    &:active:focus,
    &:active,
    &:active:hover,
    &:hover,
    &:focus:hover {
      outline: solid 2px $billsby-red;
      color: $billsby-red;
    }

    &:focus {
      color: $billsby-red;
    }
  }

  // when user action successfully completes
  &-success {
    background-color: lighten($color: $billsby-green, $amount: 55%);
    color: $billsby-green;
    font-family: $billsby-font-family;
    letter-spacing: normal;
    text-transform: none;
    outline: 1px solid $billsby-green;
  }

  &-with-shadow {
    background-color: $bg-add-btn;
    color: $billsby-black;
    justify-content: flex-start;
    outline: 1px solid $billsby-black;
    text-transform: none;
    font-size: $font-size-regular;
    font-family: $billsby-font-family;
    letter-spacing: normal;
    text-align: center;
    @include box-shadow ($box-shadow-orange);

    &:hover,
    &:focus:hover {
      background-color: $billsby-black;
      color: $white;
    }

    &:focus {
      color: $billsby-black;
    }

    &:active,
    &:active:hover {
      background-color: $billsby-black;
      color: $white;
    }

    &:active:focus {
      color: $white;
    }
  }
}