@import '../../../style/variables.scss';

.customer_subscriptions {
  &__header {
    background-color: $white;
    margin: -#{$spacing-default} -#{$spacing-default} $spacing-default -#{$spacing-default};
  }
  
  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-default;
    border-bottom: 1px solid $billsby-super-light-gray;

    .dropdown__container {
      width: 229px;
    }
  }
  &__search {
    display: flex;
    align-items: center;
    padding: $spacing-default;

    & > .cc-text:first-of-type {
      font-size: 15px;
      white-space: nowrap;
      margin-right: $spacing-default;
    }
    .search {
      i {
        margin-right: $spacing-default;
        top: 10px;
      }
      input, input::placeholder {
        font-size: 15px;
        letter-spacing: 0;
      }
      input {
        max-height: 36px;
      }
    }

    &__pagination:first-of-type {
      margin-left: $spacing-default;
    }
  }

  .cc-table {
    tr {
      max-height: 100%;
      td {
        padding: $spacing-small $spacing-medium;

        &.disabled {
          color: $billsby-muted-gray;
        }
      }
      
      th {
        padding: $spacing-medium;
      }
    }
  }
  
}