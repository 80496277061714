@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/panel-bg-img.scss';
@import '../../../style/mixin/breakpoint';

.discount-list {
  &t__greeting-alert-blue {
    &.navigation-alert--warning {
      background-color: $billsby-black;
    }
    & > .navigation-alert__btn--warning {
      background-color: $billsby-black;
    }
  }

  &__show-all {
    &.cc-text {    
      margin-top: $spacing-default;    
      color: $billsby-black;    
      text-align: center;    
      text-decoration: underline;
      background: rgb(255, 255, 255);
      border-radius: 15px;
      box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.5);
      height: 30px;    
      width: 143px;    
      padding-top: 6px;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
    }

    &__container {
      width: 100%;  
    }
  }

  &__delete-form {
    margin-top: $spacing-default;
  }

  &__inline-text.cc-text {
    display:inline;
  }

  &__inline-text.discount-list__text-blue.cc-text {
    color: $billsby-black;
  }

  &__search {
    display: flex;
    align-items: center;
    padding: $spacing-default;
    max-width: $max-wrapper-width;

    & > .cc-text:first-of-type {
      font-size: 15px;
      white-space: nowrap;
      margin-right: $spacing-default;
    }
    .search {
      i {
        margin-right: $spacing-default;
        top: 10px;
      }
      input,
      input::placeholder {
        font-size: 15px;
        letter-spacing: 0;
      }
      input {
        max-height: 36px;
      }
    }
  }

  &__pagination.pagination {
    margin-left: $spacing-default;
  }

  &__header {
    background-color: $white;
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-default;
    max-width: $max-wrapper-width;

    .dropdown__container {
      width: 229px;
    }
  }

  // &__greeting-alert {
  //   max-width: $max-wrapper-width;
  // }

  &__panel {
    max-height: 100% !important;
  }

  .cpanel--collapsed {
    max-height: 80px !important;
  }

  &__product-container {
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    margin-bottom: 10px;

    &--padding {
      padding: 30px 20px;
    }

    &-body {
      border-top: $billsby-black solid 1px;
      padding-top: 20px;
      margin-top: 2px;

      &--collapsed {
        padding-top: 20px;
        margin-top: 2px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      justify-items: end;
      width: 100%;
      position: relative;
      margin-bottom: $spacing-medium;
      margin-top: $spacing-medium;

      &-chevron {
        margin-right: $spacing-medium;
      }

      &__button-container {
        position: absolute;
        top: -18%;
        right: 0;
        display: flex;
      }
    }

    &-button {
      margin-left: $spacing-small;
    }

    &-row {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      //margin: $spacing-default 0 $spacing-small 0;

      &__icon-container {
        position: absolute;
        top: 18%;
        right: 0;

        &-icon {
          margin-left: $spacing-small;
        }

        &--red {
          color: $billsby-red;
        }
      }
    }

    &-name {
      margin-right: $spacing-small;
      display: inline-flex;
    }

    &-description {
      margin-left: $spacing-small;
      padding-left: $spacing-small;
      border-left: $billsby-orange solid 1px;
      height: 100%;
      font-size: 15px;
    }
  }

  &__table { 
    // margin-bottom: 0px;

    &.cc-table--expandable tr.discount-list__table__row-error td {
      color: $billsby-orange;

      & .cc-text {
        color: $billsby-orange;
      }
    }

    &__currency-error {
      font-size: $font-size-regular;
      margin-right: 5px;
      color: $billsby-red;
    }

    &.cc-table {
      thead {
        pointer-events: none;
      }

      tr {
        // &:last-of-type {
        //   border-bottom: 1px solid $billsby-light-grey;
        // }

        th,
        td {
          word-break: normal;
          &:nth-child(1) {
            min-width: 180px;
          }
          &:nth-child(5) {
            min-width: 80px;
            padding: 0;
          }   
          &:nth-child(6) {
            padding: 10px;
            min-width: 405px;
          }          
        }

        button.btn-add {
          height: 28px;
        }

        button.btn-error {
          height: 28px;
        }

        td:first-of-type {
          &::before {
            display: none;
          }
        }

        &.expanded {
          padding: 0px;
          max-height: none;
          height: auto;

          &--smooth {
            min-height: 0px !important;
          }
        }

        &.cc-table--expandable__rowExpanded {
          border-bottom-color: $white;
        }
      }
    }

    &__deduction-label.cc-text {
      display: block;
      $font-size-tiny: 10px;
      color: $billsby-muted-gray;
    }

    &__collapsible {
      cursor: pointer;
      color: $billsby-black;

      $this: &;

      &-label {
        text-decoration-line: underline;
      }

      &-arrow {
        &::before {
          content: '\F107';
          font-family: 'Font Awesome 5 Pro';
          font-size: $font-size-regular;
          line-height: 1.19;
          color: $billsby-black;
          margin-right: $spacing-tiny;
          cursor: pointer;
          text-decoration-line: none;
        }

        &.expanded {
          &::before {
            content: '\F106';
          }
        }
      }

      &--no-data {
        #{$this}-label {
          text-decoration: none;
        }

        #{$this}-arrow {
          display: none;
        }
      }

      // &::before {
      //   content: '\F107';
      //   font-family: 'Font Awesome 5 Pro';
      //   font-size: $font-size-regular;
      //   line-height: 1.19;
      //   color: $billsby-black;
      //   margin-right: $spacing-tiny;
      //   cursor: pointer;
      //   text-decoration-line: none;

      // }
    }

    &__actions {
      &-delete-btn {
        position: absolute;
        right: 5px;
      }

      &-edit-btn {
        position: absolute;
        right: 120px;
      }

      &-csv-btn {
        position: absolute;
        right: 217px;
      }

      button {
        height: 24px;
        padding: 3px 0px;
        margin: 0px $spacing-tiny;
      }
    }
  }

  &__coupons-table {
    margin-bottom: 0px;
    border-bottom: none;

    &.discount-list__table.cc-table {
      thead {
        pointer-events: none;
      }

      tr {
        &:last-of-type {
          border-bottom: 1px solid $billsby-light-grey;
        }

        th,
        td {
          &:nth-child(5) {
            flex: 1 1 20%;
          }          
        }

        button.btn-add {
          height: 28px;
        }

        button.btn-error {
          height: 28px;
        }

        td:first-of-type {
          &::before {
            display: none;
          }
        }

        &.expanded {
          padding: 0px;
          max-height: none;
          height: auto;

          &--smooth {
            min-height: 0px !important;
          }
        }

        &.cc-table--expandable__rowExpanded {
          border-bottom-color: $white;
        }
      }
    }
  }

  &__explain {
    @include breakpoint(1088px) {
      @include panel-bg-img('../../../images/products-explain.svg', right);
      background-position-y: center;
      background-size: 433px;
      background-position-x: 105%;
    }

    &.cpanel-arrow--collapsed {
      background-image: none;
    }
  }

  &__content {
    padding-right: 267px;
    margin-bottom: $spacing-default;
    width: 91%;
  }

  &__tooltip-text {
    span {
      color: $billsby-black;
      text-decoration-style: dashed;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  &__button {
    &--highlight {
      box-shadow: $box-shadow-orange;
    }
  }

  .tooltip--blue {
    max-width: 460px;
  }

  &__other-companies {
    &.cpanel-arrow--collapsed {
      .content-panel-title {
        margin-bottom: 0px;
      }
    }
    .content-panel-title {
      margin-bottom: $spacing-default;
    }
  }

  &__inner-table {
    &.cc-table {
      margin-bottom: 0px;

      thead {
        tr {
          border-bottom: 1px solid $billsby-light-grey;
        }
      }

      tr {
        th,
        td {
          &:last-of-type {
            justify-content: flex-start;
          }
        }
      }

    }
  }

  ///
  &__expanded-wrapper {
    padding: $spacing-default;
    background-color: $body-bg;
    border-radius: 2px;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    width: 100%;
    margin-top: -$spacing-default;


    .progress-indicator {
      padding: 0;
    }
  }
}

.error-panel.discount_list__coupon-success {
  display: flex;
  border-radius: 0px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
  background-color: $billsby-green;
  padding-top: $spacing-small;
  padding-bottom: $spacing-small;    
  margin-bottom: 0;

  & .error-panel__titlecontainer {
    display: flex;
    align-items: center;

    .cc-text {      
      color: $milk-white;
      &:nth-child(1) {
        font-weight: $font-weight-bold;
      }

      font-size: $font-size-medium;
      line-height: 1.29;
    }

    i {
      display: none;
    }
  }

  & .error-panel__btn {
    text-transform: initial;
    font-family: $billsby-font-family;
    padding: $spacing-small;
    font-size: $font-size-medium;
    color: $milk-white;
    letter-spacing: normal;
    background-color:rgba(255, 254, 252, 0.1);
    @include border-radius($milk-white);
  }
}