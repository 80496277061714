@import '../../../../../style/variables.scss';
.show-hide-checkout-feature-panel {
  & > .config-item {
    height: 100px;
    padding: 0px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    & > .image-container {
      width: 120px;
      height: 100px;
      background-color: #F2F6FC;

      &.progress-indicator {
        background-image: url('../../../../../images/checkout_customization/progress_indicator.png');
        background-size: cover;
      }

      &.summary-cart {
        background-image: url('../../../../../images/checkout_customization/summary_cart.png');
        background-repeat: no-repeat;
        background-position: -20px center;
        background-size: 189px 156px;
      }

      &.card-logos {
        background-image: url('../../../../../images/checkout_customization/card_logos.png');
        background-repeat: no-repeat;
        background-position: -65px -15px;
        background-size: 214px 160px;
      }

      &.how-was-this-calculated {
        background-image: url('../../../../../images/checkout_customization/how_was_this_calculated.png');
        background-size: cover;
      }

      &.apply-coupons {
        background-image: url('../../../../../images/checkout_customization/apply_coupons.png');
        background-repeat: no-repeat;
        background-position: -5px center;
        background-size: 133px 115px;
      }

      &.wix-compatibility {
        background-image: url('../../../../../images/checkout_customization/wix_compatibility.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 82px 32px;
      }

      &.payout-minimum-terms {
        background-image: url('../../../../../images/checkout_customization/payout_minimum_terms.png');
        background-repeat: no-repeat;
        background-position: -13px 0;
        background-size: 191px 120px;
      }

      &.plan-changes {
        background-image: url('../../../../../images/checkout_customization/plan_changes.png');
        background-repeat: no-repeat;
        background-position: -10px -5px;
        background-size: 161px 120px;
      }
    }

    & > .text-container {
      height: 100px;
      padding-left: 20px;
      padding-top: 35px;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;

      & > .title {
        color:$billsby-black;
        font-size: $font-size-regular;
      }

      & > .sub-title {
        font-size: $font-size-medium;
      }
    }

    & > .controls {
      padding-top: 35px;

      &.dropdown {
        width: 226px;
      }

      & > .cc-checkbox {
        & > i.cc-checkbox-general__icon {
          font-size: 21px;
        }
      }

      &.multiple {
        padding-top: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 220px;

        & > .cc-checkbox {
          margin-bottom: 0;
          width: 150px;

          &:nth-child(even) {
            width: 70px;
          }
        }
      }
    }
  }
}