@import '../../../style/variables';
@import '../../../style/mixin/breakpoint';

.cc-input {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  &__icon {
    position: relative;
    color: $billsby-black;
    width: 0;
    font-size: $font-size-medium;
    &.left {
      left: 15px;
    }
    &.right {
      right: 35px;
    }
  }

  &__field {
    font-family: $billsby-font-family;
    flex: 1;
    outline:none;
    color: $billsby-black;
    padding: $spacing-tiny $spacing-medium $spacing-tiny $spacing-medium;
    border: solid 1px $input-border;
    background-color: $white;
    height: 41px;
    width: 100%;
    font-size: $font-size-medium-regular;
    
    &--icon {
      &.left {
        padding-left: $spacing-medium * 3;
      }
    }

    &::placeholder{
      color: $billsby-placeholder-color;
    }

    &:hover {
      background-color: $white;
    }
  }

  &__error {
    &-container {
      color: $billsby-red;
      position: absolute;
      top: 42px;
      
      @include breakpoint($mobile-width) {
        top: 11px;
        right: 16px;
      }

      &--warning {
        color: $billsby-orange;
      }
    }
    &-msg {
      font-size: $font-size-small;
    }
    &-icon {
      margin-right: $spacing-tiny;
      cursor: pointer;
    }
  }

  &__locker {
    position: absolute;
    top: 13px;
    right: 11px;
  }
  
  &--error {
    input {
      border-color: $billsby-red;
    }

    .cc-input__icon {
      color: $billsby-red;
    }
  }
  &--warning {
    input {
      border-color: $billsby-orange;
    }

    .cc-input__icon {
      color: $billsby-orange;
    }
  }

  &--disabled {
    opacity: 0.6;
  }
}