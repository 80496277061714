@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/panel-bg-img.scss';
@import '../../../style/mixin/breakpoint';

.embed-codes {

  &__welcome-add-on {
    background-color: $white;
    @include breakpoint(1040px) {
      @include panel-bg-img('../../../images/embed_codes/sweet_shop.svg', right);
      background-size: 185px;
      background-position-y: -17px;
      background-position-x: 100%;
    }
  }

  &__welcome-allowance {
    background-color: $white;
    @include breakpoint(1040px) {
      @include panel-bg-img('../../../images/embed_codes/allowance_counters.svg', right);
      background-size: 185px;
      background-position-y: -17px;
      background-position-x: 100%;
    }
  }
  
  
  &__change-plan {
    background-color: $white;
    @include breakpoint(1040px) {
      @include panel-bg-img('../../../images/embed_codes/man_change_plan.svg', right);
      background-size: 185px;
      background-position-y: -17px;
      background-position-x: 100%;
    }
  }

  &__welcome-card-tokenizer {
    background-color: $white;
    .cc-text {
      width: 80%;
    }
    @include breakpoint(1040px) {
      @include panel-bg-img('../../../images/embed_codes/man_with_credit_card_security.svg', right);
      background-size: 185px;
      background-position-y: -10px;
      background-position-x: 100%;
    }
  }

  &__basic {        
    &__subtitle {      
      font-size: 15px;
      color: $billsby-black;
    }

    &__code-main {
      color: $background-grey;
      font-size: 13px;
      letter-spacing: normal; 
      width: 90%;     
    }

    &__variable {
      color: $billsby-orange;
    }

    &__dropdown {
      margin-bottom: $spacing-default;
    }

    &__dropdown-options {
      display:inline;
    }
  }

  #basic-copy-7 {
    margin-bottom: 0;
  }

  #basic-copy-8, #basic-copy-9 {
    .embed-codes__basic__code-main {
      div:not(:first-child):not(:last-child) {
        margin-left: calc(2 *#{$spacing-default});
      }
      div:nth-child(2), div:nth-last-child(2) {
        margin-left: $spacing-default;
      }
    }
  }

  #form-tokenizer, #init-tokenizer, #onready-tokenizer {
    .embed-codes__basic__code-main {
      div:not(:first-child):not(:last-child) {
        margin-left: $spacing-default;
      }
    }
  }

  #submit-form-tokenizer {
    .embed-codes__basic__code-main {
      div:not(:first-child):not(:last-child) {
        margin-left: calc(2 * #{$spacing-default});
      }
      div:nth-child(2), div:nth-last-child(2) {
        margin-left: $spacing-default !important;
      }
      div:nth-child(6) {
        color: $billsby-muted-gray;
      }
    }
  }

  #receive-tokenizer {
    .embed-codes__basic__code-main {
      div:not(:first-child):not(:last-child) {
        margin-left: calc(2 * #{$spacing-default});
      }
      div:nth-child(2), div:nth-last-child(2) {
        margin-left: $spacing-default !important;
      }
      div:nth-child(4), div:nth-child(8)  {
        color: $billsby-muted-gray;
      }
    }
  }

  #errors-tokenizer {
    .embed-codes__basic__code-main {
      div:not(:first-child):not(:last-child) {
        margin-left: calc(2 * #{$spacing-default});
      }
      div:nth-child(2), div:nth-last-child(2) {
        margin-left: $spacing-default !important;
      }
      div:nth-child(4), div:nth-child(5) {
        margin-left: calc(3 * #{$spacing-default}) !important;
      }
    }
  }

}