@import '../../style/variables.scss';

.cancelled-company {
  &__content {
    text-align: center;
    padding-top: 100px;

    &__header {
      font-size: 36px !important;
      
      font-weight: bold !important;
    }

    &__subtitle {
      padding-top: $spacing-default;
    }
  }
}