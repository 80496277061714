@import "../../../../../style/variables.scss";

.color-customization {
  & > div {
    display: flex;
    flex-wrap: wrap;
    margin-top: -$spacing-medium;

    & > div {
      margin-right: $spacing-medium;
      margin-top: $spacing-medium;
      width: 240px;
    }
  }
}