@import '../../style/variables.scss';

.receipt-details {
  box-shadow: $box-shadow;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  .cc-text {
    &.dusty-orange {
      color: $billsby-orange
    }
  }

  &__tax-rates {
    display: inline-block;
  }

  .gray {
    color: #78756e;
  }

  .bold {
    font-weight: bold;
  }

  .margin-small {
    margin-bottom: $spacing-small;
  }

  .display-block {
    display: block;
  }

  &__body {
    padding: $spacing-default;
    background-color: $white;
  }

  &__header {
    background-color: $billsby-black;
    padding: $spacing-default;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__logo {
      width: 74px;
    }

    &__text {
      font-size: $font-size-large;
      color: $small-text-grey;;

      &.light-text {
        color: $white;
      }
    }
  }

  &__general-info {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0px;
    }

    .general-info__section {
      margin-bottom: $spacing-small;
      .status-label {
        margin-top: $spacing-medium;
      }
    }
  }

  h1 {
    font-size: $font-size-large;
  }

  &__summary {
    display: flex;

    .summary-wrapper {
      margin-left: auto;

      div {
        box-sizing: border-box;
      }

      .summary-header {
        font-size: $font-size-large;
        margin-bottom: $spacing-default;
      }

      .summary-table {
        border-radius: 4px;
        border: 1px solid $billsby-light-grey;
        width: 310px;
        margin-bottom: $spacing-small;

        &__row {
          display: flex;
          align-items: center;
        }

        &__col {
          line-height: 32px;
          padding: 0px $spacing-medium;

          &:first-of-type {
            width: 174px;
            border-right: 1px solid $billsby-light-grey;
          }
        }

        &__sub-info {
          font-size: $font-size-tiny;
        }
      }
    }
  }

  &__plan-header {
    margin-bottom: $spacing-default;
  }

  &__footer {
    background-color: $billsby-black;
    margin-top: $spacing-medium;
    padding: $spacing-medium;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__company-sub {
      display: flex;
      align-items: center;
      flex-direction: row;

      &__logo {
        width: 45px;
        align-self: middle;
        margin-left: $spacing-tiny;
      }
    }

    &__company-details {
      flex: 0 0 70%;
    }

    span {
      color: $small-text-grey !important;
    }

    &.light-text {
      span {
        color: $white !important;
      }
  
    }
  }
}
