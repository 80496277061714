@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/scrollbar.scss';

$STEP_CPANEL_WIDTH: 990px;
$STEP_CPANEL_HEIGHT: 447px;
$HELPMENURIGHT_WIDTH: 315px;
$HELPMENURIGHT_CONTENT_WIDTH: 275px;
$HELPMENURIGHT_WIDTH_COLLAPSED: 120px;
$HELPMENURIGHT_FADE_OUT: rgba(233, 241, 252, 0);
$HELPMENURIGHT_TRANSITION: max-height 200ms ease-in-out, opacity 300ms ease-in-out, width 400ms ease-in-out;

$orange-light: #ffe4d2;

.step-cpanel {
  position: relative;

  &--fullwidth {
    width: 100%;
  }

  i {
    font-size: $font-size-large;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-items: flex-end;
    align-items: center;
    margin-bottom: $spacing-default;
    cursor: pointer;
    &__text {
      display: flex;
      flex-grow: 2;
      align-items: center;
      .cc-text {
        transition: width 200ms ease-in-out;
        font-weight: $font-weight-medium;
      }

      &--fullwidth {
        width: calc(100% - #{$HELPMENURIGHT_WIDTH});
      }
      &--expanded {
        width: calc(100% - #{$HELPMENURIGHT_WIDTH_COLLAPSED});
      }
    }

    .cc-text:first-of-type {
      
      font-size: $font-size-large;
      letter-spacing: -0.5px;
      color: $billsby-black;
    }
  }

  &__subtitle {
    font-family: $billsby-font-family;
    font-size: $font-size-medium-regular;
    color: $billsby-black;

    &::before {
      content: '';
      margin-right: $spacing-default;
      width: 2px;
      height: 30px;
      border: 1px solid $billsby-orange;
    }
  }

  &__helpmenu {
    font-family: $billsby-font-family;
    font-size: $font-size-medium;
    line-height: 1.38;
    padding: $spacing-default;
    background-color: $billsby-super-light-gray;
    opacity: 1;
    transition: $HELPMENURIGHT_TRANSITION;

    &__collapsed-icon {
      position: absolute;
      top: 23px;
      right: 20px;
      z-index: 11;
      color: $billsby-black;
    }

    &--collapsed {
      max-height: 65px !important;
      opacity: 0 !important;
      visibility: hidden;
    }

    &--warning {
      background-color: $billsby-light-orange;
      i {
        color: $billsby-orange;
      }
    }

    &--right {
      position: absolute;
      width: $HELPMENURIGHT_WIDTH;
      z-index: 9;
      height: 100%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      @include scrollbar(1px);
      overflow-x: hidden;

      max-height: 2000px;
      top: 0;
      right: 0;
      opacity: 1;
      transition: $HELPMENURIGHT_TRANSITION;

      &--hidden {
        width: $HELPMENURIGHT_WIDTH_COLLAPSED;
        overflow: hidden !important;
      }
    }

    &__contents {
      width: $HELPMENURIGHT_CONTENT_WIDTH;
      @include scrollbar(1px);
    }

    &--top {
      position: relative;
      display: flex;
      margin: $spacing-default 0;
      left: -#{$spacing-default};
      width: calc(100% + #{$spacing-default} * 2);

      // remove border-width from width calculation
      //width: calc(#{$STEP_CPANEL_WIDTH} - 2px);
    }

    &__title {
      display: flex;
      justify-content: space-between;
      color: $billsby-black !important;
      padding-bottom: $spacing-small;

      &__text {
        .cc-text {
          font-weight: $font-weight-medium !important;
        }
      }

      & *:first-child {
        display: flex;
        align-items: center;
        .cc-text {
          font-weight: $font-weight-medium;
          font-size: $font-size-large;
          letter-spacing: -0.5px;
          color: $billsby-black;
          margin-left: $spacing-small;
        }
      }
      & *:nth-child(2) {
        cursor: pointer;
      }
    }
  }

  &__gradient {
    position: absolute;
    pointer-events: none;
    transition: $HELPMENURIGHT_TRANSITION;
    z-index: 10;
    right: 0px;

    &--bottom {
      width: $HELPMENURIGHT_WIDTH;
      height: 100px;
      background: linear-gradient(180deg, $HELPMENURIGHT_FADE_OUT 0%, $billsby-super-light-gray 100%);
      bottom: 0px;

      &--collapsed {
        width: $HELPMENURIGHT_WIDTH_COLLAPSED;
      }
    }

    &--right {
      width: 100px;
      height: 100%;
      background: linear-gradient(90deg, $HELPMENURIGHT_FADE_OUT 0%, $billsby-super-light-gray 100%);
      top: 0px;
      opacity: 0;

      &--collapsed {
        opacity: 1;
      }
    }
  }

  &__body {
    width: calc(100% - #{$HELPMENURIGHT_WIDTH});
    transition: width 200ms ease-in-out;
    &--expanded {
      width: calc(100% - #{$HELPMENURIGHT_WIDTH_COLLAPSED});
    }
    &--fullwidth {
      width: 100%;
    }
  }

  &__completed {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $billsby-green;
    margin-right: $spacing-small;
    i {
      color: $white;
      font-size: 14px;
    }
  }

  &__error {
    @extend .step-cpanel__completed;
    background-color: $billsby-orange;
  }

  &__locked {
    opacity: 0.4;
  }
}
