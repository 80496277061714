@import '../../../style/variables';

.circle-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: $spacing-small;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $billsby-black;
  color: $white;
}