@import '../../style/variables';
@import '../../style/mixin/panel-bg-img';

.change-password {
  &__titlepanel {
    .cc-text {
      width: 68%;
    }
    @include panel-bg-img("../../images/change-password.svg", right);
    background-size: 208px;
    background-position-x: 105%;
    background-position-y: center;
  }
}