@import '../../../style/variables';
@import '../../../style/mixin/breakpoint';

.registration-step2 {

  @include breakpoint($mobile-width) {
    .cc-form-label {
      margin-bottom: 0;
    }
    .cc-input {
      margin-bottom: 0;
    }
  }

  .company-subdomain {
    min-height: 155px;
    &__error label {
      display: block;
      margin-top: 5px;
      color: $billsby-red;
    }
  }
}