@import '../../../style/variables';

.rounding-block {
  border-radius: 4px;
  box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.5);
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &--selected {
    box-shadow: inset 0px 0px 0px 3px $billsby-black;
  }

  & > div {
    position: absolute;
    width: 80px;
    height: 82px;
    background-color: $billsby-black;
    bottom: 0px;
    right: 0px;
  }
}
