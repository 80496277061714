@import '../../../style/variables.scss';

.menu-table {
  margin-bottom: 0;
  padding: 0;

  &__element {
    $LIST_ELEMENT: &;
    &--hover {
      &:hover {
        background-color: #fde9db;
        border-bottom: 1px solid #d8d8d8;
      }

      &:hover #{$LIST_ELEMENT}__title,
      &:hover i {
        color: $billsby-orange;
      }
    }

    &:last-of-type {
      &:hover {
        border-bottom: 0px;
      }
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &:not(:last-of-type) {
      border-bottom: 1px solid #d8d8d8;
    }

    margin-bottom: 0;
    border-radius: 0;
    //box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0px -1px rgba(0, 0, 0, 0.5);

    &--first {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &--last {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &--locked {
      opacity: 0.4;
    }

    &__title {
      &.cc-text {
        font-size: $font-size-regular;
        color: $billsby-black;
        justify-content: flex-start !important;
      }
    }

    &__subTitle {
      font-size: $font-size-medium;
      color: $billsby-black;
    }

    i {
      font-size: $font-size-regular;
      color: $billsby-black;
      cursor: pointer;
    }

    & > *:nth-child(1),
    & > *:nth-child(2) {
      display: flex;
      align-items: center;
    }
  }
}