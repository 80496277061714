@import '../../../style/variables.scss';

.see-more-text {
  & > * {
    display: inline;
  }
  & > *:nth-child(2) {
    text-decoration: underline;
    cursor: pointer;
    margin-left: $spacing-small;
  }

  .tooltip {
    max-width: 280px;
  }

  &__with-tooltip {
    margin-left: 0px !important;
    text-decoration-style: dashed !important;
  }
}