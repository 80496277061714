@import '../../../../style/variables.scss';

.webex_event-triggers {
  &__contents {

    .content-panel {
      display: flex;
      align-items: center;

      img {
        width: 35px;
        margin-right: $spacing-default;
      }
    }

    &__details {
      .cc-text:nth-child(1) {
        font-size: $font-size-regular;
        color: $billsby-black;
      }
    }
  }
}