@import "../../../../style/variables.scss";
@import "../../../../style/mixin/panel-bg-img.scss";

.brand_customization {

  &__panel {

    &__header {
      @include panel-bg-img("../../../../images/brand-customisation.svg", right);
      background-size: 204px;
      background-position-x: 102%;
      background-position-y: center;
      
      .cc-text {
        max-width: 80%;
      }
    }

    &__logo {
      &__container {
        display: flex;
      }
      
      &__file-uploader {
        width: 100%;
        &:first-of-type {
          margin-right: $spacing-default;
        }

        & .file-upload {
          margin-bottom: 0px;
          margin-top: 2px;

          & .file-upload__container .file-upload__logo {
            &:before {
              content: "\f1c5";
              font-size: 36px;
            }
          }
        }
      }
      
      &__background-color-picker {
        &:not(:last-of-type) {
          margin-right: $spacing-small;
        }
        width: 257px;
      }
    }

    &__favicon {
      & .file-upload {
        margin-bottom: 0;
        & .file-upload__container .file-upload__logo {
          background-color: $white;
          border-width: 1px;
          border-style: dotted;
          background-clip: padding-box;
          border-radius: 4px;
          margin-right: $spacing-default;
          &:before {
            color: $billsby-black;
            content: "\f1c5";
            font-size: 36px;
          }
        }
      }
    }

    &__white-label {
      &__button {
        font-size: $font-size-medium-regular;
        font-family: $billsby-font-family;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0px;
        width: 276px;
      }
    }
  }
}
