@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';

.reports-customers {
  &__heading {
    @include panel-bg-img('../../images/reports/tools_mrr_calculator.svg', right);
    .cc-text {
      max-width: 70%;
    }
  }
}