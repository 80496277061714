@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/box-shadow.scss';

.chart-tooltip {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  @include border-radius();
  @include box-shadow($box-shadow);
  width: 125px;
  min-height: 75px;

  /*
  // tooltip arrow for now removed
  &::before {
    content:'';
    display:block;
    width:0;
    height:0;
    position:absolute;
    //@include box-shadow($box-shadow);
    border-top: 8px solid transparent ;
    border-bottom: 8px solid transparent;
    border-right:8px solid #f2f6fc;
    left:-8px;

    top: 30px;
  }*/
}