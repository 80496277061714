@import '../../style/variables.scss';

.credit-note-details {
  &__table {
    tr {
      th {
        font-family: 'Roboto', 'Roboto Regular';
        text-transform: inherit;
        font-size: $font-size-medium-regular;
        color: $billsby-orange;
        letter-spacing: 0px;
      }

      th,
      td {
        &:last-child {
          justify-content: left;
          flex: 0 0 150px;
        }
      }
    }
  }

  &__notice-panel {
    &.notice-panel  {
      .cc-text {
        font-weight: $font-weight-bold;
        color: $billsby-green;
      }
    }
  }

  &__customer-link {
    font-weight: $font-weight-bold;
    
    &.deleted {
      opacity: .4;
    }
  }

  &__action-bar {
    background-color: $white;
    box-shadow: inset 0px 2px 4px -4px rgba(0, 0, 0, 0.5);

    &__wrapper {
      display: flex;
      max-width: $max-wrapper-width;
      padding: $spacing-medium;
      justify-content: space-between;
    }

    button {
      padding: $spacing-tiny $spacing-small;
      height: auto;
    }

    div {
      button {
        &:first-of-type {
          margin-right: $spacing-medium;
        }
      }
    }
  }


  &__send-button {
    background-color: $white;
  }
}

