@import '../../../../style/variables.scss';
.quick-books-faq {
  &__text {
    &.text-inline {
      display: inline;
    }

    &.text-title {
      color: $billsby-black;
      font-size: $font-size-regular;
    }

    &.text-group {
      margin-bottom: $spacing-default;
    }

    &.text-link {
      color: $billsby-black;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}