@import '../../../style/variables.scss';
.quickbooks-integrations {

  .tabbed-container {
    box-shadow: inset 0px -10px 0px -8px rgb(216, 216, 216);
    margin-bottom: $spacing-default;
    padding-left: 0px;
  }

  &__integration-success {
    background: $billsby-green;

    & > div.navigation-alert__wrapper > #navigation-alert-btn {
      text-transform: initial;
      font-family: $billsby-font-family;
      padding: $spacing-small;
      color: $milk-white;
      letter-spacing: normal;
      background-color:rgba(255, 254, 252, 0.1);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & div:first-of-type {
      display: flex;
      align-items: center;
      & > i:first-of-type {
        font-size: $font-size-huge;
        margin-right: $spacing-default;
      }

      & > div {
        display: flex;
        flex-direction: column;
        & > .cc-text:nth-child(1) {
          font-size: 15px;
          font-family: $billsby-font-family;
          align-self: flex-start;
          font-size: $font-size-large;
        }
        & > .cc-text:nth-child(2) {
          font-size: $font-size-medium;
          font-family: $billsby-font-family;
          align-self: flex-start;
        }
      }
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      margin-right: $spacing-default;
    }

    div {
      display: flex;
      align-items: center;

      #uninstall-btn {
        width: 87px;
      }

      #visit-website {
        width: 120px;
        margin-right: $spacing-small;
      }
    }
  }

  &__title-container.step-cpanel > div > div.step-cpanel__title {
    & > div.step-cpanel__title__text {
      & > div.cc-text.step-cpanel__title__text--fullwidth {
        width: 100%;
      }
    }
  }

  &__connect-btn#integrate-btn {
    width: 207px;
    height: 36px;
    margin-right: $spacing-small;
    border:none;
    cursor: pointer;
  }
  
  &__error {
    & > div.notice-panel__icon > i.fas.fa-exclamation-circle {
      font-size: $font-size-huge;
    }
    & > p {
      font-weight: bold;
      color: $billsby-red;
    }
  }
}