@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';
@import '../../../style/mixin/box-shadow.scss';
@import '../../../style//mixin/scrollbar.scss';

.filter-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  $MAX_HEIGHT: 293px;

  &__options {
    min-width: 163px;
    background-color: $white;
    position: absolute;
    top: calc(100% - #{$spacing-medium});
    padding: $spacing-small $spacing-small;
    @include border-radius();
    @include box-shadow($box-shadow);
    z-index: 100;

    &__item {
      padding: $spacing-tiny $spacing-small $spacing-tiny 0;
      display: flex;
      align-items: center;
      height: calc(#{$MAX_HEIGHT} / 10);

      i {
        margin-right: $spacing-tiny;
      }

      i, span.cc-text {
        color: $billsby-gray !important;
        text-transform: none !important;
        font-weight: $font-weight-regular !important;
        font-size: $font-size-small!important;
        letter-spacing: 0px !important;
        white-space: nowrap;
        max-width: 495px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--selected, &:hover {
        i, span.cc-text {
          // color: $billsby-orange !important;
          font-weight: $font-weight-bold !important;
        }
      }

      &--clear {
        position: absolute;
        width: 100%;
        height: 36px;
        left: 0px;
        padding: $spacing-small;
        margin-top: $spacing-tiny;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: $billsby-super-light-gray;
    @include box-shadow($box-shadow);

      }
    }
    &--scrollable {
      max-height: $MAX_HEIGHT;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
      }
      @include scrollbar();
    }
  }

  i {
    margin-left: $spacing-tiny;
  }
}