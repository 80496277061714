@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.textarea {
  font-family: $billsby-font-family;
  font-size: $font-size-medium-regular;
  display: block;
  width: 100%;
  padding: 15px;
  @include border-radius($input-border);
  color: $billsby-black;
  background-color: $input-bg;
  resize: none;

  &:focus {
    outline: none;
  }

  &::placeholder{
    color: $billsby-placeholder-color;
    
  }
}