@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';

.send-invoice {
  &__titlepanel {    
    @include panel-bg-img("../../images/invoices/billsbot_send.svg", right);
    background-size: 236px;
    background-position-y: -25px;
    background-position-x: 104%;
  }

  &__description {    
    width: 75%;
  }
  
  &__email {
    color: $billsby-black;
  }
}
