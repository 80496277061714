@import '../../../../style/variables.scss';

.custom-date-picker {
  
  &__icon:first-of-type {
    font-size: $font-size-medium-regular;
  }

  &__icon-chevron {
    position: absolute;
    right: 15px;    
  }

  .DateInput {
    width: auto;

    input {
      color: $billsby-black;
      font-size: $font-size-medium-regular;
    }  
  }


  /* layout modifications to the calendar */
  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #B6B6B6;
    border: 1px double #B6B6B6;
  }
  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;  
  }
}