@import "../../../style/variables.scss";
@import "../../../style/mixin/panel-bg-img.scss";

.customers-notes {
  &__add-btn {
    width: 157px;
    height: 36px;
    &__container {
      margin-left: $spacing-default;
    }
  }

  &__form-actions {
    display: flex;
    justify-content: flex-end;
    & > div {
      display: inline;
      cursor: pointer;
      width: 70px;

      &:first-child {
        color: $billsby-red;
        text-decoration: underline;
      }
    }
  }

  &__search {
    display: flex;
    align-items: center;
    padding-top: $spacing-default;
    padding-bottom: $spacing-default;
    max-width: $max-wrapper-width;

    & > .cc-text:first-of-type {
      font-size: 15px;
      white-space: nowrap;
      margin-right: $spacing-default;
    }
    .search {
      i {
        margin-right: $spacing-default;
        top: 10px;
      }
      input,
      input::placeholder {
        font-size: 15px;
        letter-spacing: 0;
      }
      input {
        max-height: 36px;
      }
    }

    &__pagination:first-of-type {
      margin-left: $spacing-default;
    }
  }
  
  &__input.is-empty {
    border: 1px solid $billsby-orange;
  }
}