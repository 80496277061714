@import '../../../style/variables.scss';

.preview {
  .cc-text:first-of-type {
    font-family: 'Helvetica';
    font-size: $font-size-medium-regular;
    font-weight: bold;
    color: $billsby-black;
    margin-bottom: $spacing-small;
  }

  .cc-text:last-of-type {
    font-family: 'Helvetica';
    font-size: 11px;    
    color: #716f6f;
    margin-top: $spacing-small;
  }
}