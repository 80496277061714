@import '../../../style/variables';
@import '../../../style/mixin/breakpoint';
@import '../../../style/mixin/border-radius';
@import '../../../style/mixin/scrollbar';

.billsby-phone-input {


  .intl-tel-input {
    width: 100%;
  }

  .selected-flag {
    width: 100px !important;
    background: $billsby-black !important;
    color: $white;
    &:focus {
      outline: none;
    }
    .iti-arrow {
      border: none;
      &::after {
        content: '\f107';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
        color: $white;
        position: absolute;
        top: -5px;
        right: 0px;
        width: 13px;
        font-size: $font-size-regular;
      }
    }
    .iti-arrow.up {
      border: none;
      &::after {
        content: '\f106';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
        color: $white;
        position: absolute;
        top: -5px;
        right: 0px;
        width: 13px;
        font-size: $font-size-regular;
      }
    }
  }

  .expanded .flag-container{
    &::after {
      content: "";
      position: absolute;
      top: 42px;
      left: 10px;
      z-index: 99999999999;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $billsby-black;
    }
  }

  .country-list {
    background: $billsby-black;
    top: 50px;
    border: none;
    @include border-radius();
    @include scrollbar(1px, transparent);
   
    li {
      &:nth-child(1) {
        margin-top: $spacing-small;
      }
    }
    

    span.country-name, span.dial-code {
      font-size: 13px;
      letter-spacing: 0.4px;
    }
    span.country-name, span.dial-code:not(.highlight) {
      color: $white !important;
    }
    span.dial-code {
      &::before {
        content: "(";
      }
      &::after {
        content: ")";
      }
    }

    .divider {
      border-bottom: 1px solid $billsby-orange;
      margin: 0 $spacing-small $spacing-small $spacing-small;
    }
    .highlight {
      background-color: transparent !important;
      span.country-name, span.dial-code {
        color: $billsby-orange !important;
        letter-spacing: 0.4px;
      }
    }
  }

  input.form-control {
    width: 100%;
    outline: none;
    color: $billsby-black;
    padding: $spacing-tiny $spacing-tiny $spacing-tiny 115px !important;
    border: solid 1px $input-border; 
    background-color: $input-bg;
    height: 41px;
    font-size: $font-size-medium;
    &:hover {
      background-color: lighten($billsby-super-light-gray, 10%);
    }
  }

  &__error {
    position: absolute;
    top: 40px;

    @include breakpoint($mobile-width) {
      top: 13px;
      right: 30px;
      bottom: 12px;
    }

    font-size: $font-size-medium;
    color: $billsby-red;
    z-index: 99;
  }

}
