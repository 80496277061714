@import '../../../style/variables';

.progress-indicator {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding: $spacing-medium;
  pointer-events: none;

  @at-root #{&} {
    &--align {
      &-left {
        justify-content: flex-start;
      }

      &-right {
        justify-content: flex-end;
      }
    }

    &--full-content {
      position: absolute;
      width: calc(100% - #{$NAVBAR_WIDTH});
      height: 100%;
      top: 0;
    }

    &--full-screen {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    &--no-padding {
      padding: 0px;
    }
  }

  &__dotted {
    & > div {
      margin-left: 5px;
      width: 9px;
      height: 9px;
      background-color: $white;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .dot1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .dot2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @at-root #{&} {
      &--white {
        div {
          background-color: $white;
        }
      }

      &--blue {
        div {
          background-color: $billsby-black;
        }
      }

      &--orange {
        div {
          background-color: $billsby-orange;
        }
      }
    }

    @at-root #{&} {
      &--small {
        transform: scale(0.5);
      }

      &--large {
        transform: scale(2);
      }
    }
  }

  &__spinner {
    margin: 60px auto;
    font-size: 4px;
    position: relative;
    text-indent: -9999em;
    border-top: 4px solid rgba(0, 0, 0, 0.05);
    border-right: 4px solid rgba(0, 0, 0, 0.05);
    border-bottom: 4px solid rgba(0, 0, 0, 0.05);
    border-left: 4px solid $billsby-black;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
    border-radius: 50%;
    width: 10em;
    height: 10em;

    &::after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
