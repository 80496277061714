@import '../../../style/variables.scss';

.switch {
  height: 26px;
  display: flex;
  align-items: center;

  &__container {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    margin-right: $spacing-default;
    
    &__text {
      position: absolute;
      top: 6px;
      color: $white;
      letter-spacing: 1.7px;
      font-size: $font-size-tiny;
      &--on {
        left: 11px;
        i {
          font-size: 16px;
          position: absolute;
          left: -3px;
          top: -3px;
        }
      }
      &--off {
        right: 5px;
        i {
          font-size: 16px;
          position: absolute;
          right: 2px;
          top: -3px;
        }
      }
    }
    // Hide default HTML checkbox
    input {
      display:none;

      &:checked + .switch__slider {
        @extend .switch__on;
      }
      &:not(:checked) + .switch__slider {
        @extend .switch__off;
      }
  
      &:focus + .switch__slider {
        box-shadow: 0 0 1px #2196F3;
      }
  
      &:checked + .switch__slider:before {
        -webkit-transform: translateX(22px);
        -ms-transform: translateX(22px);
        transform: translateX(22px);
      }
    }

  }


  /* The slider */
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 22px;
      left: 3px;
      bottom: 3px;
      background-color: $white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    &--round {
      /* Rounded sliders */
      border-radius: 34px;
      &::before {
        border-radius: 50%;
      }
    }
  }

  &__on {
    background-color: $billsby-green;
  }

  &__off {
    background-color: $billsby-red;
  }

}