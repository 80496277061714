@import '../../../style/variables';

.european-union-steps {
  &__label {
    :nth-child(1) {
      color: $billsby-black;
      font-size: $font-size-regular;
    }
    :nth-child(2) {
      font-size: $font-size-tiny;
    }
  }

  &__content {
    margin-left: $spacing-default
  }

  .step-cpanel__title__text--fullwidth {
    width: 100%;
  }
}