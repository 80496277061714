@import '../../style/variables.scss';

.credit-notes {
  &__header {
    background-color: $white;
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-default;
    border-bottom: 1px solid $billsby-super-light-gray;

    .dropdown__container {
      width: 229px;
    }
  }
  
  &__export-btn {
    width: 84px;
    height: 36px;
    background-color: $white;
    &__container {
      margin-left: $spacing-default;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    padding: $spacing-default;
    max-width: $max-wrapper-width;

    & > .cc-text:first-of-type {
      font-size: 15px;
      white-space: nowrap;
      margin-right: $spacing-default;
    }
    .search {
      i {
        margin-right: $spacing-default;
        top: 10px;
      }
      input,
      input::placeholder {
        font-size: 15px;
        letter-spacing: 0;
      }
      input {
        max-height: 36px;
      }
    }

    &__pagination:first-of-type {
      margin-left: $spacing-default;
    }
  }

  &__table {
    &__item {
      cursor: pointer;
    }
    
    &.cc-table {
      tr {
        max-height: 100%;
        min-height: 52px;

        th {
          word-break: normal !important;
        }

        td,
        th {
          padding: $spacing-small $spacing-medium;
          text-align: left;

          .status-label {
            white-space: nowrap;
          }

          &:nth-child(1) {
            flex: 0 1 120px;
          }

          &:nth-child(2), &:nth-child(3) {
            flex: 1 1 15%;
          }

          &:nth-child(4) {
            flex: 1 1 18%;
          }

          &:nth-child(5) {
            flex: 1 1 10%;
          }

          &:nth-child(6) {
            flex: 0 1 130px;
            white-space: nowrap;
          }
        }
      }
    }

    .status-label {
      white-space: nowrap;
    }
  }
}
