@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.tabbed-container {
  display: flex;
  padding: $spacing-small 0 0 $spacing-default;
  z-index: 0;
  position: relative;
  
  @mixin anchorCommonStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 116px;
    height: 30px;
    font-size: $font-size-medium;
  }

  &--default {
    background-color: $white;

    a {
      @include anchorCommonStyle();
      @include border-radius();
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: $spacing-small;
      background-color: $billsby-black;
      text-decoration: none;

      li {
        color: $white;
        list-style-type: none;
      }
    }

    @mixin defaultActiveAnchorStyle {
      background-color: $billsby-super-light-gray;
      font-weight: $font-weight-medium;
      & > * {
        color: $billsby-black;
      }
    }

    a {
      &.active {
        @include defaultActiveAnchorStyle();
      }
      &:hover {
        @include defaultActiveAnchorStyle();
        opacity: .8;
      }
    }
    
  }

  &--submenu {
    a {
      @include anchorCommonStyle();
      color: $billsby-black;
      text-decoration: none;
      border-bottom: 2px solid transparent;
      li { 
        display: block;
      }
      @mixin submenuActiveAnchorStyle {
        border-bottom: 2px solid $billsby-orange;
        font-weight: $font-weight-medium;
        color: $billsby-orange;
      }
      &.active {
        @include submenuActiveAnchorStyle();
      }
      &:hover {
        @include submenuActiveAnchorStyle();
        opacity: .8;
      }
    }
  }
}