@import '../../style/variables.scss';

.customers {
  &__header {
    background-color: $white;
  }

  &__navigation {
    border-bottom: 1px solid #00000029;
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-default;
    max-width: $max-wrapper-width;

    .button {
      margin-left: auto;
    }

    .dropdown__container {
      width: 229px;
    }
  }

  &__notice-panel {
    &.notice-panel {
      .cc-text {
        font-weight: $font-weight-bold;
        color: $billsby-green;
      }
    }
  }

  &__add-btn {
    width: 157px;
    height: 36px;
    &__container {
      margin-left: $spacing-default;
    }
  }

  &__export-btn {
    width: 84px;
    height: 36px;
    background-color: $white;
    &__container {
      margin-left: $spacing-default;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    padding: $spacing-default;
    max-width: $max-wrapper-width;

    & > .cc-text:first-of-type {
      font-size: 15px;
      white-space: nowrap;
      margin-right: $spacing-default;
    }
    .search {
      i {
        margin-right: $spacing-default;
        top: 10px;
      }
      input,
      input::placeholder {
        font-size: 15px;
        letter-spacing: 0;
      }
      input {
        max-height: 36px;
      }
    }

    &__pagination:first-of-type {
      margin-left: $spacing-default;
    }
  }

  &__table {
    &.cc-table {
      tr {
        td,
        th {
          .status-label {
            white-space: nowrap;
          }

          &:nth-child(1),
          &:nth-child(2) {
            flex: 1 1 10%;
          }

          &:nth-child(5) {
            flex: 1 1 9%;
          }


        }
        td:nth-child(5) {
          width: 30px;
        }
      }
    }

    &__item {
      cursor: pointer;

      &__card {
        background-color: $billsby-orange;
        margin-left: $spacing-small;

        &--active {
          background-color: $billsby-green;
        }

        &--expired {
          background-color: $billsby-orange;
        }

        &--no-card {
          background-color: $billsby-red;
        }
      }
    }

    &-tooltip-icon {
      color: $billsby-orange;
      font-size: $font-size-huge;
      margin-right: 30px;
    }
  }
}
