@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';

.pending-invitations {
  &__title {
    color: $billsby-black;
    font-size: $font-size-large;
    
  }

  &__el {
    @include panel-bg-img('../../images/welcome-logo.svg', right);
    & > div {
      display: flex;
      align-items: center;
      & > *:first-child {
        margin-right: $spacing-default;
        height: 40px;
        width: 138px;
      }
      & > *:nth-child(2) {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}