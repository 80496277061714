@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.configuration-main {
  &__panel {
    @include panel-bg-img("../../../images/configuration-welcome.svg", right);
    background-size: 204px;
    background-position-x: 102%;
    background-position-y: center;
    
    .cc-text {
      width: 80%;
    }
  }

  &__cancel_account {
    width: 100%;
    text-align: right;
    text-decoration: underline;
    color: $billsby-red;
    cursor: pointer;

    & > p.cc-text {
      font-size: $font-size-regular;
      color: $billsby-red;
    }
  }
}