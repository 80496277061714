@import "../../../../../style/variables.scss";

.email-footer {
  &__text {
    padding: $spacing-default;
    font-size: $font-size-medium-regular;
  }

  &__text-container {
    margin-top: $spacing-default;
    background: $billsby-grey;
    border-radius: 0px;
    height: 74px;
    width: 100%;
  }

  &__ads {
    max-width: 100%;
  }

  &__ads-container {
    margin-top: $spacing-small;
    text-align: center;
  }
}