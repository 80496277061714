@import '../../../../style/variables';

.billsby-tax-complete-integration {
  &__form {
    margin-bottom: $spacing-default;
  }

  &__info {
    color: $billsby-muted-gray
  }
}