@import '../../../style/variables.scss';

.custom-month-picker {
  .react-datepicker {
    border-radius: 0;
    border: 0px;
    box-shadow: $box-shadow;
    background-color: $billsby-black;

    &-wrapper {
      display: block;
      height: 100%;
    }

    &__input-container {
      display: block;
      height: 100%;

      &:hover {
        input {
          border-color: hsl(0, 0%, 70%);
        }

        &::before {
          color: hsl(0, 0%, 70%);
        }
      }

      &::before {
        content: '\F078';
        display: block;
        font-family: 'Font Awesome 5 Pro';
        position: absolute;
        z-index: 1;
        top: 0px;
        right: 0px;
        padding: 13px;
        color: $billsby-muted-gray;
        transition: color 100ms ease;
        pointer-events: none;
      }

      input {
        cursor: pointer;
        height: 41px;
        border-radius: 0;
        border: 1px solid $billsby-muted-gray;
        box-shadow: none;
        font-family: $billsby-font-family;
        color: $billsby-black;
        font-size: $font-size-medium;
        padding-left: $spacing-default;
        transition: border-color 100ms ease;
        width: 140px;
      }
    }

    &__month-text {
      border-radius: 2px;
      padding: $spacing-tiny;
      color: $white;
    }

    &__month {
      &-text {
        font-size: $font-size-medium;

        &:hover {
          color: $billsby-orange;
          background-color: $billsby-black;
        }
      }

      @at-root #{&}--selected {
        background-color: $billsby-orange;
        color: $white;
        font-weight: $font-weight-bold;

        &:hover {
          background-color: $billsby-orange;
          color: $white;
        }
      }

      @at-root #{&}--disabled {
        opacity: 0.3;
      }
    }

    &__triangle {
      border-bottom-color: $billsby-black !important;
    }

    &-year-header {
      background-color: $billsby-black;
      padding: $spacing-small 0px;
      padding-bottom: 0px;
      color: $white;
      font-size: $font-size-large;
      border-radius: 0;
      border-bottom: 0;
      font-weight: $font-weight-regular;
    }

    &__navigation {
      border: 0.3rem solid transparent;
      top: 13px;

      @at-root #{&}--previous {
        border-right-color: $white;
      }
      @at-root #{&}--next {
        border-left-color: $white;
      }
    }
  }
}
