@import '../../../style/variables.scss';
@import '../../../style/mixin/box-shadow.scss';
@import '../../../style/mixin/scrollbar.scss';

.tooltip {
  color: $billsby-black !important;
  @include box-shadow(0 0 4px 0 rgba(0, 0, 0, 0.5));
  height: auto !important;
  padding: $spacing-default !important;

  &--default {

  }
  &--blue {
    max-width: 200px;
    background-color: $billsby-black !important;

    &.place-top {
      &::after {
        border-top-color: $billsby-black !important;
      }
    }
    &.place-bottom {
      &::after {
        border-bottom-color: $billsby-black !important;
      }
    }
    &.place-right {
      &::after {
        border-right-color: $billsby-black !important;
      }
    }
    &.place-left {
      &::after {
        border-left-color: $billsby-black !important;
      }
    }

    .cc-text:first-of-type {
      color: $white;
      font-size: 12px;
      font-family: $billsby-font-family;
      letter-spacing: 0;
      line-height: 18px;
    }
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
    font-size: 14px;

    li {
      pointer-events: all;
      cursor: pointer;
      margin: $spacing-small 0;
      color: $billsby-black;
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover,
      &:focus {
        color: $billsby-orange;
      }

      i {
        color: $billsby-black;
      }
    }
  }

  /* override library classes */
  &.show {
    opacity: 1 !important;
  }

  &.button-tooltip { 
    & > p {
      text-align: center !important;
      text-transform: none !important;
    }
  }
}

.__react_component_tooltip {
  overflow-y: auto;
  overflow-x: hidden;
  //max height of the tooltip must be 75% of the viewport height
  max-height: 90vh;
  @include scrollbar();
  &.show {
    opacity: 1;
  }
}

.disabled-feature-wrapper {
  position: relative;
  opacity: 0.5;
  a, p, span, .side_navbar__content {
    pointer-events: none;
  }
}

.disabled-feature-tooltip {
  text-align: center !important;
  max-width: 200px;
  text-transform: unset !important;
}