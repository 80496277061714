@import '../../../../style/variables.scss';
@import '../../../../style/mixin/border-radius.scss';

.checkout-textarea {
  font-family: $billsby-font-family;
  font-size: $font-size-small;
  display: block;
  width: 100%;
  padding: $spacing-medium $spacing-tiny $spacing-tiny $spacing-medium;
  box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
  border: solid 1px $billsby-black;
  color: $billsby-black;
  background-color: $white;
  resize: none;

  &:focus {
    outline: none;
  }
}