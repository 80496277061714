@import '../../style/variables';
@import '../../style/mixin/transition';

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  outline: none;
  z-index: 101;
  background: $white;
  opacity: 0;
  width: 0;
  //@include transition(width, 400ms, ease-in-out);
  //@include transition(opacity, 400ms, ease-in-out);

  //transition: width 300ms ease, opacity 1000ms ease-in-out;

  @include transition(all, 400ms, ease-in-out);

  &__header {
    display: flex;
    height: 60px;
    padding: $spacing-default;
    align-items: center;
    justify-content: space-between;
    color: $white;
    background-color: $billsby-black;

    & *:nth-child(1) {
      font-family: $billsby-font-family;
      font-weight: $font-weight-medium;
      color: $white;
      font-size: 17px;
    }

    & *:nth-child(2) {
      cursor: pointer;
      font-size: 17px;
      opacity: .6;
    }
  }

  &__body {
    padding: $spacing-default;
    background-color: $billsby-super-light-gray;
    height: 100%;
    display: none;

    &--visible {
      display: block;
    }
  }
}

.ReactModal__Overlay {
  background-color: $modal-overlay !important;
  z-index: 101;
}

.ReactModal__Content--after-open {
  opacity: 1;
  width: 800px;
}