.invoice-details__table {
  &.cc-table {
    thead {
      pointer-events: none;
    }

    tr {
      th,
      td {
        &:nth-child(1) {
          min-width: 300px;
        }       
      }
    }
  }
}