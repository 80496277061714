@import '../../style/mixin/border-radius.scss';
@import '../../style/variables.scss';
@import '../../style/mixin/scrollbar.scss';

.custom-fields {
  .menu-panel__content {
    height: 420px;
    overflow-y: auto;
    @include scrollbar(1px);
  }

  &__closebtn {
    font-size: $font-size-regular;
    color: $background-grey;
    background-color: $billsby-black;
    border: 2px solid $background-grey;
    position: absolute;
    cursor: pointer;
    right: -22px;
    top: -11px;
    z-index: 100;
  }

  &__menu {
    &__title {
      color: $billsby-orange;
      font-size: $font-size-large;
      margin-bottom: $spacing-small;
    }
    &__item {
      &:first-of-type {
        margin-top: $spacing-default;
      }
      cursor: pointer;
      
      font-size: 15px;
      letter-spacing: normal;
      color: $white;
      margin-left: $spacing-default;
      
      &--selected {
        color: $billsby-orange;
      }
    }
  }

  #select-some-fields {
    position: absolute;
    bottom: 50px;
    left: $spacing-default;
    letter-spacing: normal;
    font-size: 13px;
    width: 189px;
    color: $billsby-black;
    background-color: $white;
  }
  #create-some-fields {
    position: absolute;
    bottom: $spacing-default;
    left: 47px;
    font-size: 13px;
    height: 20px;
    padding: 0;
    text-transform: initial;
  }

  &__search {
    input:first-of-type {
      font-size: $font-size-small;
    }
    
    i {
      right: 32px;
    }
  }

  /*&__optionalfield {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .cc-text:first-of-type {
      font-size: 15px;
      margin-bottom: $spacing-small;
    }

    &__checked {
      cursor: pointer;
      background: $billsby-green;
      color: $white;
    }
    &__unchecked {
      cursor: pointer;
      background: $bg-add-btn;
      color: $billsby-black;
    }
  }*/

  &__company {
    position: relative;
    height: 100%;

    &__title {
      
      font-size: $font-size-large;
      color: $billsby-orange;
      margin-bottom: $spacing-small;
    }

    &__titlecontainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $spacing-default;

      .search {
        max-width: 200px;
        input {
          font-size: 11px;
        }
        i {
          right: 32px;
        }
      }
    }

    img {
      width: 492px;
      height: 311px;
      object-fit: contain;
      display: block;
      margin: 0 auto;      
    }

    &__footer {
      position: absolute;
      background-color: $billsby-super-light-gray;
      bottom: 0;
      margin: -#{$spacing-default};
      padding: $spacing-default;
      width: calc(100% + 2 * #{$spacing-default});
      .cc-text:nth-child(1) {
        
        color: $billsby-black;
        font-size: $font-size-large;
        letter-spacing: -0.5px;
        margin-bottom: $spacing-small;
      }
      
    }
  }

  &__integrations {
    position: relative;
    height: 100%;

    &__title {
      @extend .custom-fields__company__title;
    }
    img {
      position: absolute;
      width: 503px;
      height: 267px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      object-fit: contain;
      display: block;  
    }
  }
}