@import '../../../../style/variables.scss';
@import '../../../../style/mixin/panel-bg-img.scss';
@import '../../../../style/mixin/border-radius.scss';

.payment-gateways {
  &__welcome {
    @include panel-bg-img("../../../../images/payment-gateways-welcome.svg", right);
    background-size: 204px;
    background-position-x: 102%;
    background-position-y: center;
    .cc-text {
      max-width: 80%;
    }
  }

  &__error-panel {
    margin-top: $spacing-default;
  }

  &__go-cardless {
    background-color: $white;
    .error-panel__btn {
      width: 185px;
      margin-left: $spacing-medium;
    }

    .error-panel__titlecontainer {
      img {
        width: 40px;
        margin-right: $spacing-default;
      }

      p:first-of-type {
        color: $billsby-black
      }
    }
  }
  
  &__available-list {
    i {
      color: $billsby-black;
    }

    & > .content-panel.interactive-list__element > div > i {
      margin-right: 0px;
    }
  }

  &__selected, &__added {
    .step-cpanel--fullwidth:last-of-type {
      .cpanel {
        //margin-bottom: 0;
      }
    }

    &--locked {
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }

    .cpanel--collapsed {
      max-height: 77px;
    }
  }

  &__selected-title, &__added-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & div:first-of-type {
      display: flex;
      align-items: center;
      & > i:first-of-type {
        font-size: $font-size-huge;
        margin-right: $spacing-default;
      }

      & > div {
        display: flex;
        flex-direction: column;
        & > .cc-text:nth-child(1) {
          font-size: 15px;
          font-family: $billsby-font-family;
          align-self: flex-start;
        }
        & > .cc-text:nth-child(2) {
          font-size: $font-size-medium;
          font-family: $billsby-font-family;
          align-self: flex-start;
        }
      }
    }

    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      margin-right: $spacing-default;
    }
    

    div {
      display: flex;
      align-items: center;

      #warning-disconnect-tooltip {
        width: 155px;
      }
      #replace-gateway, #visit-website, #disconnect-gateway {
        width: 147px;
      }
      #replace-gateway, #disconnect-gateway {
        font-size: 15px;
        font-family: $billsby-font-family;
        letter-spacing: 0;
        max-height: 40px;
        margin-right: $spacing-small;
        i {
          margin-bottom: 2px;
        }
      }
    }
  }

  &__step-panel {
    position: relative;

    &.cpanel--collapsed {
      &::before {
        content: '\F078';
      }
    }

    &::before {
      content: '\F077';
      font-weight: $font-weight-bold;
      font-family: 'Font Awesome 5 Pro';
      font-size: $font-size-large;
      color: $billsby-black;
      margin-right: $spacing-default;
      cursor: pointer;
      position: absolute;
      right: 0px;
      top: 28px;
    }
  }


  &__connect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    #selected-gateway-cancel {
      letter-spacing: 0;
      min-width: 147px;
    }
  }


  &__update {
    &__form-group {
      margin-bottom: 20px;
      .switch__container input:not(:checked) + .switch__slider {
        background-color: #C1BFBF;
      }

      &.disabled {
        #is-sandbox, .element-modifiers__lock {
          opacity: 0.5;
        }
      }

      div:nth-child(3) {
        text-align: right;
        padding-right: $spacing-medium !important;
      }
    }
    
    &-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;

      #payment-gateway-save-changes {
        width: 83%;
      }

      #payment-gateway-cancel {
        font-family: $billsby-font-family;
        width: 16.1%;
      }
    }


  }

  &__creation-succeed {
    box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
    @include border-radius();

    .content-panel {
      margin-bottom: 0px;
      box-shadow: none;
    }
  }
}