@import '../../style/variables';
@import '../../style/mixin/panel-bg-img.scss';

.error-404 {
  &--auth {
    max-width: $max-wrapper-width;
    padding: $spacing-default;
  }

  &__panel {
    @include panel-bg-img('../../images/error-404.svg', right);
    p, a {
      color: $billsby-black;
    }
    
    p:first-of-type {
      font-size: $font-size-large;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}