@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.change-language {
  &__title {
    &-panel {
      @include panel-bg-img("../../../images/billsbot-globe.svg", right);
      background-size: 255px;
      background-position-y: -25px;
      background-position-x: 107%;
    }

    &-text {
      width: 70%;
    }
  }

  .cc-form-label {
    margin-bottom: 0px;
  }

  &__request-sent {
    background-color: $billsby-green;
  }
}