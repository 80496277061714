@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';

.reports-accounting {
  &__heading {
    @include panel-bg-img('../../images/reports/744730_billsbot_accounting_report_illustration.svg', right);    
    background-size: 185px;
    background-position-y: -17px;
    background-position-x: 100%;

    .cc-text {
      max-width: 70%;
    }
  }
}