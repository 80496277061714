@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.billing-welcome {
  &__greeting {
    height: 370px;
    @include panel-bg-img('../../../images/billing-welcome.png', center);
    background-size: 638px 293px !important;
    background-position: center;
    display: flex;
    flex-direction: column;

    .content-panel-title {
      font-size: 24px;
      color: $billsby-orange;
      margin: 0 0 0 0;
    }    

    &-text {
      font-size: 18px !important;
    }
  }
}