@import "../../../style/variables.scss";
@import "../../../style/mixin/panel-bg-img.scss";

.pre_live_menu_content {
  &_info_panel {
    width: 460px;
    height: 86px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    display: none;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-align-items: center;
    align-items: center;

    &_item {

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: 136px;
        height: 46px;
        margin-top: $spacing-default;
        margin-bottom: $spacing-default;
        margin-right: 0px;
        margin-left: 8px;
        padding: 0px;
        border: none;
        box-shadow: none;
      }

      &:first-child {
        margin-left: 17px;
      }

      &:last-child {
        margin-left: 17px;
      }

      &>.cc-text {
        color: rgb(37, 36, 34);
        font-size: 15px;
        font-family: $billsby-font-family;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0px;
      }

      &_value {
        &.cc-text {
          font-size: $font-size-large;
          font-weight: $font-weight-bold;
          color: $billsby-black;
        }

        &.orange.cc-text {
          color: $billsby-orange;
        }
      }
    }
  }

  &_footer {
    width: 460px;
    margin-top: 11px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;

    &_text {
      &.cc-text {
        color: #707070;
        font-size: $font-size-tiny;
        font-family: $billsby-font-family;
        font-weight: normal;
        display: inline;
      }

      &-bold.cc-text {
        font-weight: $font-weight-bold;
      }
    }

    &_go_live_button {
      background-color: $billsby-light-green;
      font-family: $billsby-font-family;
      width: 115px;
      height: 38px;
      color: $billsby-green;
      border: unset;
      outline-color: $billsby-green;
      background-clip: padding-box;

      &:hover, &:focus, &:active, &:focus:active {
        color: $billsby-green;
        outline-color: $billsby-green;
      }
    }

    &_contact_button {
      margin-left: $spacing-small;
      width: 120px;
      height: 38px;

      &>i {
        line-height: 0;
      }
    }
  }
}