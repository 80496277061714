@import "../../../style/variables.scss";
@import '../../../style/mixin/panel-bg-img.scss';

.update-status {
  &__title-panel {
    @include panel-bg-img("../../../images/subscriptions/billsbot_laptop.svg", right);
    background-size: 270px;
    background-position-y: 8px;
    background-position-x: 103%;

  }

  &__title-text {
      padding-right: 225px;
  }

  &__input-info {
    font-size: $font-size-tiny;
    padding-top: $spacing-small;
  }

  &__notice-panel {
    border-radius: 4px;
    box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
  }

  .progress-indicator {
    padding: $spacing-tiny 0px 0px 0px;
  }
}
