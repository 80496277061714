@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.dashboard__prelive {
  &-heading1 {
    @include panel-bg-img('../../../images/product-manager.svg', left);
    height: 185px;
    max-width: $max-wrapper-width;
    width: 100%;

    a {
      
      font-size: $font-size-large;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 200px;
      .title {
        margin: $spacing-small 0;
        margin-right: $spacing-default;
        
        font-size: $font-size-large;
        letter-spacing: -0.5px;
        color: $billsby-black;
      }
      .product-manager {
        position: relative;
        top: 7px;
        //top: 120px;
        & > *:first-child {
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
    }
  }

  &-heading2 {
    margin-top: -30px;
    @include panel-bg-img('../../../images/embed-code.svg', right);
    background-size: 344px;
    background-position-y: center;
    background-position-x: 103%;
    .cc-text {
      max-width: 70%;
    }
  }

  &-heading3,
  &-heading4 {
    height: 210px;
  }

  &-heading3,
  &-heading4 {
    position: relative;
    .button {
      position: absolute;
      bottom: 20px;
    }
  }

  &-heading5 {
    @include panel-bg-img('../../../images/webinar.svg', left);
    background-size: 300px;
    background-position-y: center;
    background-position-x: -1%;
    & > * {
      margin-left: 324px;
    }
  }

  &-heading6 {
    @include panel-bg-img('../../../images/teammates2.svg', right);
    background-size: 354px;
    background-position-y: center;
    background-position-x: 106%;
    .cc-text {
      max-width: 70%;
    }
  }
}
