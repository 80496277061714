@import '../../../style/variables';
@import '../../../style/mixin/breakpoint.scss';

.setup-account {

  @include breakpoint(0, $mobile-width) {
    padding: 0 $spacing-default;
  }

  &__titlepanel {
    .cc-text:nth-child(1) {
      
      color: $billsby-orange;
      font-size: $font-size-huge;
      margin-bottom: $spacing-small;
    }
    .cc-text:nth-child(2) {
      font-family: $billsby-font-family;
      font-size: $font-size-large;
      letter-spacing: -0.5px;
    }
  }

  &__continueBtn {
    
    font-size: $font-size-regular;

    &-uncompleted {
      opacity: 0.4;
    }

    &-skip {
      text-align: center;
      margin-top: 10px;
      
      a {
        text-decoration: underline;
      }

      a:hover {
        cursor: pointer;
      }
    }
  }
}