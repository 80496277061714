@import '../../style/variables.scss';

.card-details {
  &__panel {
    display: flex;
    position: relative;
  }

  &__button {
    position: absolute;
    top: $spacing-default;
    right: $spacing-default;
    height: auto;
    padding: 8px $spacing-small;
  }

  &__card {
    color: $billsby-black;
    font-size: 20px;
    margin-right: $spacing-medium;
  }

  &__content {
    width: 100%;
    &-title {
      color: $billsby-black;
      font-weight: $font-weight-bold;
      font-size: $font-size-regular;

      span {
        text-transform: capitalize;
      }
    }

    &-details {
      display: flex;
      margin-bottom: $spacing-small;

      $DETAILS: &;

      &--error {
        .cc-text {
          color: $billsby-red;
        }

        #{$DETAILS}-label {
          &::before {
            content: '\F608';
            display: inline-block;
            font-family: 'Font Awesome 5 Pro';
            top: 2px;
            margin-right: $spacing-tiny;
            font-weight: $font-weight-bold;
          }
        }
      }

      &-label {
        flex: 0 0 100px;
        color: $billsby-muted-gray;
      }
    }
  }
}
