@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.cpanel {
  border-radius: 4px;
  cursor: auto;
  
  &__title {
    display: flex;
    align-items: center;
    
    .cc-text {
      
      font-size: $font-size-large;
      letter-spacing: -0.5px;
      color: $billsby-black;
    }
  }

  //transition: max-height 500ms cubic-bezier(0, 1, 0, 1);

  &--collapsed {
    border-radius: 4px;
    max-height: 65px;
    cursor: pointer;
    overflow: hidden;
  }
}