@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.update-subscription {
  &__title {
    &-panel {
      @include panel-bg-img('../../../images/configuration-welcome.svg', right);
      background-size: 225px;
      background-position-y: -38px;
      background-position-x: 110%;
    }

    &-text {
      width: 75%;
    }
  }

  &__form-label {
    margin-top: 10px;
  }
  &__cycle,
  &__plan-change-type {
    & > .cc-checkbox.cc-checkbox--margin-bottom > i.cc-checkbox-general__icon {
      color: $billsby-orange;
    }
    &__description {
      font-size: $font-size-tiny;
    }
  }

  .cc-checkbox.cc-checkbox--margin-bottom > i.cc-checkbox-general__icon {
    color: $billsby-orange;
  }
}
