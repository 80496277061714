@import '../../style/variables.scss';

.integrations {
  .tabbed-container {
    box-shadow: inset 0px -10px 0px -8px rgb(216, 216, 216);
    margin-bottom: $spacing-default;
    padding-left: 0px;
  }

  &__list {
    & > .content-panel > div > div > p:first-child {
      font-size: $font-size-regular;
      color: $billsby-black;
      font-weight: $font-weight-regular;
    }

    &.website-builder {
      img {
        width: auto;
        height: auto;
        object-fit: contain;
        margin-right: $spacing-default;
      }
    }
  }
  
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & div:first-of-type {
      display: flex;
      align-items: center;
      & > i:first-of-type {
        font-size: $font-size-huge;
        margin-right: $spacing-default;
      }

      & > div {
        display: flex;
        flex-direction: column;
        & > .cc-text:nth-child(1) {
          font-size: 15px;
          font-family: $billsby-font-family;
          align-self: flex-start;
          font-size: $font-size-large;
        }
        & > .cc-text:nth-child(2) {
          font-size: $font-size-medium;
          font-family: $billsby-font-family;
          align-self: flex-start;
        }
      }
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      margin-right: $spacing-default;
    }

    div {
      display: flex;
      align-items: center;

      #integrate-btn {
        width: 155px;
        margin-right: $spacing-small;
        letter-spacing: 0;
      }
      #visit-website {
        width: 120px;
      }
    }
  }

  &__free-agent.step-cpanel > div > div.step-cpanel__title {
    & > div.step-cpanel__title__text {
      & > div.cc-text.step-cpanel__title__text--fullwidth {
        width: 100%;
      }
    }
  }
}