@import '../../style/mixin/panel-bg-img.scss';
.update-invoice-status {
  &__titlepanel {
    @include panel-bg-img("../../images/invoices/billsbot_invoice_paid.svg", right);
    background-size: 236px;
    background-position-y: -12px;
    background-position-x: 107%;
  }

  &__text {
    width: 75%;
  }
}