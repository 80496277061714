@import '../../../style/variables';
@import '../../../style/mixin/panel-bg-img';

.edit-profile {
  &__titlepanel {
    .cc-text {
      width: 70%;
    }
    @include panel-bg-img("../../../images/edit-profile.svg", right);
    background-size: 204px;
    background-position-x: 103%;
    background-position-y: top;
  }
}