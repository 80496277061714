@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.registration-from-invitation {
  &__heading {
    @include panel-bg-img('../../../images/welcome-logo.svg', right);
    background-size: 263px;
    background-position-x: 103%;
    background-position-y: -29px;
  }

  #register_btn {
    margin-bottom: $spacing-small;
  }

  &__error {
    @include panel-bg-img('../../../images/welcome-logo.svg', right);
    background-size: 263px;
    background-position-x: 103%;
    background-position-y: -29px;
  }
}