
@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.pagination{
  $COLOR: $billsby-black;

  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 36px;

  @include border-radius($COLOR);
  
  @mixin icon-left-right($iconType) {
    content: $iconType;
    font-weight: bold;
    font-family: "Font Awesome 5 Pro";
    font-size: 15px;
  }

  .next-btn {
    &::after {
      @include icon-left-right("\f0da");
    }
  }
  .previous-btn {
    &::before {
      @include icon-left-right("\f0d9");
    }
  }
  .disable-previous-next-btn {
    pointer-events: none;
  }

  li {
    padding: 0;
    border-left: 1px solid $COLOR;
    cursor: pointer;
    &.active  {
      background: $COLOR;
      outline: 0;
      a{
        color: $white !important;
      }
    }

    &:first-child{
      border-left: none;
    }
    &:last-child{
      border-right: none;
    }

    a, a.break-me, a.break-me:focus {
      display: inline-block;
      width: 100%;
      padding: $spacing-small $spacing-default;
      text-decoration: none;
      color: $COLOR !important;
    }
  }

  svg path{
    fill: $COLOR !important;
  }

  .disabled {
    pointer-events: none !important;
    cursor: none !important;
  }
}