@import '../../style/variables.scss';

.address-lookup {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 0;
    .cc-form-label:nth-child(2) {
      text-decoration: underline;
      cursor: pointer;
      text-align: right;
      position: absolute;
      top: 14px;
      right: 20px;
      z-index: 1;
    }
  }
  &__summary {
    background: $billsby-super-light-gray;
    border-radius: 0px;
    box-shadow: none;
  }

  &__country {
    color: $billsby-orange;
    & > label {
      color: $billsby-orange;
    }
  }
}