@import '../../../style/variables.scss';
@import '../../../style/mixin/breakpoint.scss';

.navigation-bar {
  &__container {
    width: 100%;
    height: 6px;
    background: #555555;
    opacity: 1;
    border-radius: 0px 0px 6px 6px;
    @include breakpoint(0, $mobile-width) {
      border-radius: 0;
    }
  }

  &__filler {
    width: 25%;
    bottom: 1px;
    position: relative;
    height: 7px;
    background: #555555 0% 0% no-repeat padding-box;
    @include breakpoint(0, $mobile-width) {
      border-radius: 0 !important;
    }
    opacity: 1;
  }


  &__text {
    bottom: 14px;
    position: relative;
    width: 25%;
    height: 20px;
    background: #555555 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: center;

    & > P {
      padding-top: 3px;
      font-size: $spacing-small;
      color: $white;
    }
  }
}