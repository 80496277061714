@import '../../../style/variables.scss';

.back-navigation-panel {
  margin-bottom: 20px;
  padding: 0;

  &__element {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid #d8d8d8;
    $LIST_ELEMENT: &;
    &--hover {
      &:hover {
        background-color: #fde9db;
        border-bottom: 1px solid #d8d8d8;
      }

      &:hover #{$LIST_ELEMENT}__title, &:hover i {
        color: $billsby-orange;
      }
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    margin-bottom: 0;
    border-radius: 0;
    //box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0px -1px rgba(0, 0, 0, 0.5);
    
    &__children {
      box-shadow: none;
    }

    &--locked {
      opacity: .4; 
    }

    &--clickable {
      cursor: pointer;
    }

    &--no-bottom-border {
      padding-bottom: 0px;
      border-bottom: none;
    }

    &__title {
      font-size: $font-size-large;
      font-weight: $font-weight-regular;
      color: $billsby-black;
    }

    i {
      font-size: $font-size-huge;
      cursor: pointer;
      color: $billsby-black;
    }

    & > *:nth-child(1), & > *:nth-child(2) {
      display: flex;
      align-items: center;
      & > *:first-child {
        margin-right: $spacing-small;
      }
    }
  }
}