@import "../../../style/variables";
@import "../../../style/mixin/panel-bg-img.scss";
@import "../../../style/mixin/box-shadow.scss";

.create-add-ons {
  &__heading {
    &--addons {
      @include panel-bg-img("../../../images/allowances/add-ons.svg", right);
      background-size: 317px;
      background-position-x: 108%;
      background-position-y: -11px;
    }
    &--allowances {
      @include panel-bg-img("../../../images/allowances/allowances.svg", right);
      background-size: 255px;
      background-position-x: 104%;
      background-position-y: center;
    }

    &-text {
      width: 75%;
    }
  }

  &__error-contianer {
    margin-top: $spacing-default;
  }

  &__pricing-model {
    &-title {
      display: flex;
      align-items: center;
      & > *:nth-child(1) {
        margin-right: $spacing-default;
      }
      & > *:nth-child(2) {
        display: flex;
        align-items: center;
        .border {
          border: 1px solid $billsby-orange;
          height: 30px;
          margin-right: $spacing-small;
        }
        .cc-text {
          font-family: $billsby-font-family;
          font-size: $font-size-medium-regular;
          letter-spacing: 0px;
        }
      }
    }
  }

  &__select_add_ons_plan {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0px;
    max-width: 1360px;

    &__pagination {
      margin-left: $spacing-default;
    }
  }

  &__select-plans {
    &__container {
      padding-left: $spacing-tiny;
      padding-right: $spacing-tiny;
      margin-top: $spacing-default;
    }

    &__attach-later.cc-text {
      cursor: pointer;
      text-decoration: underline;
      color: $billsby-black;
      display: block;
      text-align: center;
      margin-top: $spacing-default;
      margin-bottom: 0px;
    }

    &__list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      i {
        cursor: pointer;
      }

      &__commands {
        display: inline-flex;
        &-force {
          &-label.cc-text {
            padding-top: $spacing-tiny;
            margin-right: $spacing-default;
          }

          &.switch__container input:not(:checked) + span.switch__slider {
            background-color: $billsby-muted-gray;
          }
        }
        & i:nth-child(1) {
          padding-top: $spacing-tiny;
          margin: 0;
          color: $billsby-red;
          cursor: pointer;
        }
      }
    }

    &__listitem {
      display: flex;
      align-items: center;
      flex-grow: 1;
      width: 300px;
      & > *:nth-child(1) {
        margin-right: $spacing-small;
      }
    }

    &__listitem-plan {
      display: flex;
      flex-grow: 5;
      border-left: 1px solid #F87847;
      padding-left: 15px;
      align-items: center;
      & > *:nth-child(1) {
        margin-right: $spacing-small;
      }
    }
  }

  &__add-feature-tags {
    display: block;
    margin-bottom: $spacing-default;
  }

  &__file-uploader {
    &__browse {
      text-decoration: underline;
      cursor: pointer;
    }

    flex-grow: 1;
    margin-bottom: $spacing-default;
    & .file-upload {
      margin-bottom: 0;
      padding: 0;
      & .file-upload__container {
        & > .file-upload__text {
          margin: $spacing-default;
          margin-left: 0;
        }

        & > .file-upload__progress_indicator {
          padding-right: 120px;
          width: calc(100% - 120px);
        }
      }
      & .file-upload__container .file-upload__logo {
        background-color: $white;
        border-width: 1px;
        border-style: dotted;
        background-clip: padding-box;
        border-radius: 0;
        margin-right: $spacing-default;
        width: 100px;
        height: 104px;
        &:before {
          color: $billsby-black;
          content: "\f1c5";
          font-size: 50px;
        }
      }
    }
  }
  
}
