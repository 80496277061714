@import '../../../../style/variables.scss';
@import '../../../../style/mixin/panel-bg-img.scss';
@import '../../../../style/mixin/font-styles.scss';

.invoices-credit-notes-customization {
  &__heading {
    @include panel-bg-img('../../../../images/invoice-customisation.svg');
    background-size: 238px;
    background-position-x: 104%;
    background-position-y: -22px;
    
    .cc-text {
      max-width: 80%;
    }
  }
  .tabbed-container {
    box-shadow: inset 0px -10px 0px -8px rgb(216, 216, 216);
    margin-bottom: $spacing-default;
    padding-left: 0px;

    &--submenu {
      a {
        width: auto;
        padding: 0px $spacing-default;
      }
    }
  }

  &__country-back {
    cursor: pointer;
    display: flex;
    align-items: center;

    h6 {
      margin-bottom: 0px;
      @include heading-font-style();
    }

    i {
      font-size: $font-size-huge;
      margin-right: $spacing-small;
      color: $billsby-black;
    }
  }

  .interactive-list__element {
    padding: $spacing-medium $spacing-default;

    i {
      margin-right: 0px !important;
    }

    &__subTitle {
      font-size: $spacing-small
    }
  }

  .switch__container input:not(:checked) + .switch__slider {
    background-color: $billsby-muted-gray;
  }

}
