@import '../../../style/variables';

.configure-tax-rates {
  position: relative;

  &__content {
    margin-top: 30px;
  }

  .tax-rate-panel {
    height: 60px;

    .divider {
      height: 40px;
    }
  }
}