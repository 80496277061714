@import '../../style/variables.scss';

.tax-validation-table {
  display: table;
  width: 100%;
  border-spacing: 0px;

  thead {
    pointer-events: none;
  }

  thead,
  tbody {
    th,
    td {
      text-align: left;
      padding: $spacing-medium;

    }
  }

  tr {
    td:first-of-type {
      &::before {
        display: none;
      }
    }
  }

  &__status-btn-wrapper {
    width: 100%;

    .invoices-table__btn-wrapper {
      margin-left: auto;

      button {
        margin-left: $spacing-small;
      }
    }
  }

  &__info {
    font-size: 9px;
    color: rgb(120, 117, 110);
  }

  &__view-btn {
    margin-left: auto;
  }

  &__status-btn-wrapper {
    display: flex;
    align-items: center;
  }

  &__collapsible-transactions {
    cursor: pointer;

    &::before {
      content: '\F107';
      font-family: 'Font Awesome 5 Pro';
      font-size: $font-size-regular;
      line-height: 1.19;
      color: $billsby-black;
      margin-right: $spacing-tiny;
      cursor: pointer;
    }
  }
}
