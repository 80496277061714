@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.customer_details {
  min-width: 1050px;
  &:after {
    content: '';
    display: table;
    clear: both;
  }

  &__content {
    float: left;
    padding-right: 18px;
    width: calc(100% - 250px);
  }

  &__nav {
    width: 230px;
    float: left;

    &.left {
      padding-right: 18px;
    }

    & > .side_navbar{
      width: 235px;
    }
  }

  .nav-item-icon-red {
    & > a {
      & > i {
        color: $billsby-red;
      }
    }
  }
}

.cleared-personal-data__notice {
  p {
    font-weight: $font-weight-bold;
    color: $billsby-green;
  }
}

