@import '../../../style/variables.scss';

.dashboard-chart {
  min-height: 494px;
  &__amount {
    color: $billsby-black;
  }

  &__average {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-default;
    & > *:nth-child(1) {
      color: $billsby-black;
      font-size: $font-size-small;
      margin-right: 5px;
    }

    .amount-formatted {
      &__base {
        font-size: $font-size-small;
      }
      &__decimal {
        font-size: $font-size-tiny;
      }
    }
  }

  .amount-formatted {
    * { color: $billsby-black }
    &__base {
      font-weight: bold;
    }
  }

}