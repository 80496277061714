@import '../../../../../style/variables.scss';

$checkout-radius: 6px;

.checkout-preview-new {
  width: 680px;
  height: 500px;
  background-color: #f5f5f5;
  padding: 40px;
  position: relative;
  font-family: 'Open sans';
  overflow: hidden;

  .button {
    font-family: 'Open sans' !important;
    letter-spacing: unset !important;
    pointer-events: none;

    span {
      font-weight: bold !important;
    }
  }

  &__body-text {
    font-size: 14px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $spacing-default;

    &__title {
      font-size: 20px;
    }

    &__content {
      flex: 1 1;
      margin-left: $spacing-medium;

      &.align-center {
        margin-left: 0;
        text-align: center;
      }
    }

    &__icon {
      font-size: $font-size-huge;
    }

    &__subtitle {
      font-size: $font-size-small;
    }
  }

  &__body {
    height: 73%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    &.no-subtitle {
      height: 77%;
    }

    &__gradient {
      position: absolute;
      left: 0px;
      width: 100%;
      height: 150px;
      transition: opacity 0.3s ease;
    }
  }

  &__footer {
    text-align: center;
    .button {
      margin-top: $spacing-small;
    }

    &__link {
      font-size: $font-size-small;
      text-decoration: underline;
      margin-top: $spacing-medium;
    }
  }

  &__panel {
    display: flex;
    align-items: center;
    border-radius: $checkout-radius;
    overflow: hidden;
    background-color: $white;
    margin-bottom: $spacing-default;
    box-shadow: unset;

    &__title {
      font-size: 17px;
    }

    &__subtitle {
      font-size: $font-size-medium-regular;
    }

    &__content {
      margin-left: $spacing-default;
    }

    &__action {
      margin-left: auto;
      margin-right: $spacing-default;
      text-align: center;

      .number-input {
        margin-bottom: $spacing-tiny;
      }

      .button {
        min-width: 125px;
        margin-left: $spacing-medium;
      }

      &__info {
        text-decoration: underline;
      }
    }
  }

  .navigation-bar__container {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
  }

  .cart__button {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: 0px;
    width: 40px;
    height: 92px;
    transform: translate(0%, -50%);

    i {
      color: $white;

      &:nth-child(1) {
        font-size: $font-size-regular;
        margin-bottom: $spacing-small;
      }
      &:nth-child(2) {
        font-size: $font-size-large;
      }
    }
  }
}