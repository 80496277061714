@import '../../../../style/variables.scss';
@import '../../../../style/mixin/panel-bg-img.scss';

.change-company-domain {
  &__header {
    @include panel-bg-img("../../../../images/stop.svg", right);
    background-position-x: 129%;
    background-position-y: top;
    background-size: 388px;
    .cc-text {
      width: 72%;
    }
  }

  &__error label {
    display: block;
    margin-top: 5px;
    color: $billsby-red;
  }

  table {
    margin-bottom: 0;
    tr {
      align-items: baseline;
      max-height: 100%;
      min-height: 52px;
    }
    th {
      flex: .18 1;
    }
    td:last-child {
      justify-content: flex-start;
    }
    th, td {
      margin-bottom: 0px;
    }
  }

  .new-subdomain {
    display: flex;
    align-items: center;
    .cc-form-label {
      width: auto;
      margin-left: $spacing-small;
    }
  }
}