@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';

.view-transaction-log {
  &__content {
    border-radius: 4px;
    border: 1px solid $billsby-light-grey;
    padding: $spacing-default;
    overflow-x: auto;

    &__title {
        color: $billsby-black;
        font-size: $font-size-regular;
    }

    pre {
        margin: 0px;
    }
  }
}