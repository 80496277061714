@import '../../../style/variables';

.override-tax-rate-panel {
  display: flex;
  align-items: stretch;
  min-height: 60px;
  height: 100%;
  padding: $spacing-medium $spacing-default;
  margin-top: $spacing-medium;

  .cc-text {
    color: $billsby-black;
  }

  &__name {
    width: 90px;
    align-self: flex-start;
    flex-shrink: 0;
    margin-top: 12px;

    .cc-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__actions {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;

    i {
      cursor: pointer;
      display: block;

      &:last-child {
        margin-left: $spacing-medium;
      }

      &.edit {
        color: $billsby-black;
      }

      &.delete {
        color: $billsby-red;
      }
    }
  }

  &__rates {
    margin-top: $spacing-medium;
    .tax-rate-panel__input {
      width: auto;
      margin: 0;
    }
  }

  &__content {
    flex-grow: 1 !important;
    padding: 0px !important;
  }

  &__input {
    width: 130px;
    margin: 0 $spacing-small;
  }

  .divider {
    background-color: $billsby-orange;
    margin: 0px 15px;
    height: auto;

  }
}
