@import '../../style/variables.scss';
@import '../../style/mixin/panel-bg-img.scss';
@import '../../style/mixin/box-shadow.scss';
@import '../../style/mixin/breakpoint.scss';

.discount-and-coupons {
  &__welcome-panel {
    @include panel-bg-img('../../images/discounts/discount_giveaway.svg', right);
    background-position-y: -85px;
    background-size: 760px;
    background-position-x: 185%;
    & .cc-text {
      width: 44%;
    }

    @include breakpoint(1400px) {
      background-position-x: 160%;
      background-position-y: -95px;
      & .cc-text {
        width: 55%;
      }
    }

    @include breakpoint(1471px) {
      background-position-x: 142%;
    }
    
    &__btn {
      padding-left: $spacing-default;
      padding-right: $spacing-default;
    }
  }
}