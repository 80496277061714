.email-textarea-input{
  &-submit {
    width: 100%;
    margin-top: 20px;
    height: 36px;
    font-size: 14px;
  }

  &-description.cc-text {
    font-size: 13px;
  }
}
