@import "../../../../../style/variables.scss";
@import '../../../../../style/mixin/border-radius.scss';

.rounding-customization {
  & > div {
    display: flex;
    flex-wrap: wrap;
  }

  .rounding-block {
    margin-right: $spacing-default;
  }
}