@import '../../../style/variables.scss';
@import '../../../style/mixin/border-radius.scss';

.file-upload {

  &__container {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 60px;
    height: 60px;
    @include border-radius();
    background-color: $billsby-black;
    margin-right: $spacing-default;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content: "\f382";
      color: $white;
      font-size: 32px;
      //font-size: 48px;
      //transform: scale(0.6, 1);
      letter-spacing: -1px;
      font-family: 'Font Awesome 5 Pro';
    }
  }

  &__image {
    width: 60px;
    height: 60px;
    margin-right: $spacing-default;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 60px;
      max-height: 60px;
    }
  }
}