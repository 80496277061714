@import '../../style/variables.scss';

.suspended-account {
  padding: 35px;
  max-width: 800px;

  &__title {
    &.cc-text {
      font-size: 36px;
      font-weight: $font-weight-bold;
      color: $billsby-red;
      
      line-height: 36px;
      letter-spacing: -1px;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    .cc-text {
      font-size: $font-size-regular;
    }

    i {
      font-size: $font-size-regular;
      margin-right: $spacing-small;
    }

    .fa-times-circle {
      color: $billsby-red;
    }

    .fa-check-circle {
      color: $billsby-green;
    }
  }

  &__content {
    &.cc-text {
      font-size: $font-size-large;
    }
  }

  &__btn {
    background-color: $billsby-red;
    color: $white;

    &:focus {
      color: $white;
    }
  }
}
