@import '../../../style/variables.scss';

.exit-reasons {
  min-height: 500px;

  &__header {
    background-color: $white;
    box-shadow: $box-shadow;
  }

  &__dropdown {
    width: 230px;
  }

  .chart-tooltip {
    flex-direction: column;
    width: auto;
    height: auto;
    min-height: 0;
    padding: $spacing-medium;

    .cc-text {
      font-size: $font-size-regular;
      margin: 2px 0;
    }
  }

  &__chart-legend {
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;

    .cc-text {
      font-weight: bold;
      margin-top: $spacing-default;
      margin-right: $spacing-medium;
      margin-bottom: 0px;
      text-decoration: underline;
      cursor: pointer;
      transition: opacity 0.3s ease;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    max-width: $max-wrapper-width;
    padding: $spacing-small $spacing-default;

    .switch__container input:not(:checked) + .switch__slider {
      background-color: $billsby-muted-gray;
    }

    .custom-month-picker {
      .dropdown {
        width: 116px;

        &.dropdown--lg {
          width: 150px;
        }
      }
    }

    .divider {
      background-color: #dbdee4;
    }

    .button {
      margin-left: auto;
      font-family: inherit;
    }
  }

  &__pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 80px;
  }

  &__table-container {
    position: relative;
    .cc-table {
      tr {
        th,
        td {
          &:last-child {
            justify-content: start;
            flex: 1 1 25%;
          }

          .exit-reasons__product-name {
            font-size: $font-size-tiny;
            color: #78756e;
          }
        }
      }
    }

    .btn-add {
      position: absolute;
      right: $spacing-default;
      top: $spacing-tiny;
    }
  }

  .navigation-alert-wrapper {
    background-color: $billsby-black;

    .navigation-alert {
      max-width: $max-wrapper-width;
    }

    .navigation-alert__title {
      
      font-size: $font-size-regular;
      font-weight: normal;
    }
  }

  .with-side-margin {
    margin: 0 $spacing-medium;
  }

  .bold {
    font-weight: $font-weight-bold;
  }
}
