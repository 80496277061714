@import '../../style/variables.scss';

.cancel-subscription-table {
  &__row {
    width: 100%;

    &__sub-text {
      font-size: 9px;
      color: $billsby-muted-gray;
    }
  }

  tr.no-max-height {
    max-height: none;
  }
}
