@import '../../style/variables.scss';
@import '../../style/mixin/breakpoint.scss';

.invoice-standalone {
  display: flex;
  max-width: calc(100% - #{$invoice-sidebar-width});
  
  &__error {
    margin: $spacing-default;
  }

  .receipt-details {
    margin-top: $spacing-default;
    height: fit-content;
  }
}