@import "../../../style/variables.scss";
@import "../../../style/mixin/panel-bg-img.scss";

.subscriptions_details {
  min-width: 1050px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  &__content {
    float: left;
    padding-right: 18px;
    width: calc(100% - 250px);
  }

  &__nav {
    float: left;
  }

  &__customer {
    display: inline;
    font-weight: $font-weight-bold; 
    margin-left: 3px;
  }

  .cc-table {
    tbody {
      tr {
        max-height: unset;
        &:first-child {
          max-height: 52px;
        }
        td {
          &.subscription_detail {
            &__label-column {
              flex: 0 0 225px;
              text-transform: uppercase;
            }

            &__value-column {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }

  .nav-item-icon-red {
    & > a {
      & > i {
        color: $billsby-red;
      }
    }
  }

  &__nav--cancelled {
    .sidenavbar__content-body {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &__notice-panel {
    margin-bottom: 0px;
    &.notice-panel  {
      .cc-text {
        font-weight: $font-weight-bold;
        color: $billsby-green;
      }
    }
  }
}
