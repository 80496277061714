@import '../../style/variables';
@import '../../style/mixin/panel-bg-img';

.pro-plan-feature {
  &__titlepanel {
    .cc-text {
      width: 70%;
    }

    @include panel-bg-img("../../images/golive/launch-spaceship.svg", right);
    background-size: 255px;
    background-position-y: -15px;
    background-position-x: 107%;
  }

  &__notice-panel {
    border-radius: 4px;
    box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);

    a {
      color: $billsby-black;
    }
  }

  &__panel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &__title {
      color: $billsby-black;
      font-size: $font-size-regular;
    }

    &>div:nth-child(2) {
      padding-left: $spacing-medium;
      width: 20%;
    }
  }
}