@import '../../../style/variables.scss';
.free-agent-faq {
  &__text {
    &.text-inline {
      display: inline;
    }

    &.text-title {
      color: $billsby-black;
      font-size: $font-size-regular;
    }

    &.text-group {
      margin-bottom: $spacing-default;
    }

    &.text-link {
      color: $billsby-black;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.free-agent-integrations {
  &__integration-success {
    background: $billsby-green;
  }

  &__integration-warning {
    // background: #D3D8DE;
    height: 110px;

    & .navigation-alert__subTitle {
      max-width: 80%;
    }

  }
  
  
  &__error {
    & > div.notice-panel__icon > i.fas.fa-exclamation-circle {
      font-size: $font-size-huge;
    }
    & > p {
      font-weight: bold;
      color: $billsby-red;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & div:first-of-type {
      display: flex;
      align-items: center;
      & > i:first-of-type {
        font-size: $font-size-huge;
        margin-right: $spacing-default;
      }

      & > div {
        display: flex;
        flex-direction: column;
        & > .cc-text:nth-child(1) {
          font-size: 15px;
          font-family: $billsby-font-family;
          align-self: flex-start;
          font-size: $font-size-large;
        }
        & > .cc-text:nth-child(2) {
          font-size: $font-size-medium;
          font-family: $billsby-font-family;
          align-self: flex-start;
        }
      }
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      margin-right: $spacing-default;
    }

    div {
      display: flex;
      align-items: center;

      #uninstall-btn {
        width: 87px;
      }

      #visit-website {
        width: 120px;
        margin-right: $spacing-small;
      }
    }
  }

  &__free-agent.step-cpanel > div > div.step-cpanel__title {
    & > div.step-cpanel__title__text {
      & > div.cc-text.step-cpanel__title__text--fullwidth {
        width: 100%;
      }
    }
  }

  &__faq {
    &__text {
      &.text-inline {
        display: inline;
      }
  
      &.text-bold {
        font-weight: bold;
      }
      
      &.text-title {
        color: $billsby-black;
        font-size: $font-size-regular;
      }
  
      &.text-group {
        margin-bottom: $spacing-default;
      }
  
      &.text-link {
        color: $billsby-black;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}