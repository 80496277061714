@import '../../../style/variables';

.configure-member-states {
  position: relative;

  &__content {
    margin-top: 30px;
  }

  .tax-rate-panel {
    height: 60px;

    .divider {
      height: 40px;
    }
  }

  &__origin {
    .tax-rate-panel {
      margin-bottom: $spacing-default;
    }
  }
}