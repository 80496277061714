@import '../../../style/variables.scss';

.amount-formatted {
  display: flex;
  align-items: baseline;

  &__base {
    font-size: 15px;
  }
  &__decimal {
    font-size: 12px;
  }
}