@import "../../../../style/variables.scss";
@import "../../../../style/mixin/border-radius.scss";
@import "../../../../style/mixin/scrollbar.scss";

.note_panel {
  position: relative;
  width: 100%;

  i {
    font-size: $font-size-regular;
  }

  &__actions {
    font-size: $font-size-regular;
    color: $billsby-black;
    display: flex;
    justify-content: flex-end;

    & > div {
      display: inline;
      cursor: pointer;
      width: 20px;

      &.disabled {
        color: $billsby-muted-gray
      }

      &.loading {
        width: 45px;
      }

      &:nth-child(2) {
        margin-left: $spacing-default;
        color: $billsby-red;
        
        &.disabled {
          color: $billsby-muted-gray
        }
  
      }

    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-items: flex-end;
    margin: 0 0 12px 0;
    width: 100%;

    &__text {
      .cc-text {
        display: inline;
        font-size: 15px;
        align-items: left;
        color: $billsby-black;
        font-weight: $font-weight-bold;

        &.large-icon-text {
          line-height: 25px;
        }

        & > .text-hightlight {
          color: $billsby-black;
        }
      }
    }

  }

  &__footer {
    color: $billsby-muted-gray;
    text-align: left;
    font-size: 13px;

    & > .text-hightlight {
      color: $billsby-black;
    }
  }
}
