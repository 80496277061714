@import '../variables';
@import '../mixin/breakpoint';

@mixin panel-bg-img($url, $position: right){
  // the ~ says that the file path is relative to the root src, without tilde the version 3.4.0 of react-scripts library
  // is not resolving the path correctly, the version 2.1.3 works instead

  background-image: url("~" + $url);
  background-repeat: no-repeat;
  background-position: $position;
  background-position-y: bottom;
  background-size: contain;
}