@import '../../../../../../style/variables.scss';

.bbc-settings-input {
  &__panel {
    
    &__title.mb0 {
      margin-bottom: 0px;
    }

    &__title>.content-panel-title {
      margin-bottom: 5px;
    }
  }

  &__submit {
    width: 100%;
    margin-top: $spacing-default;
    height: 36px;
    font-size: $font-size-medium-regular;
  }

  &__checkbox {
    &__title {
      font-size: $font-size-medium;
    }
  
    &__subtitle {
      font-size: $font-size-medium;
      color:$billsby-muted-gray;
      &-link {
        text-decoration: underline;
      }
    }
  }
}