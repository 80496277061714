@import "../../../style/variables.scss";
@import "../../../style/mixin/panel-bg-img.scss";

.subscription_status_bar {
  width: 100%;
  height: 84px;
  background: $white;
  box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  content: "";
  display: table;
  clear: both;
  text-align: center;
  margin-bottom: $spacing-default;
  &.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &__column {
    .content-panel {
      margin-bottom: 0px;
      border-style: none;
      box-shadow: none;
    }

    &-plan {
      .content-panel {
        background: $billsby-super-light-gray;
        height: 100%;
        border-style: none;
        box-shadow: none;
        border-radius: 0px;
      }
    }
  }

  &__item {
    &__label {
      font-size: 15px;
      font-family: $billsby-font-family;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0px;

      &-plan {
        color: $billsby-orange;
        font-size: 20px;
        font-family: $billsby-font-family;
      }
    }

    &__value {
      margin-top: $spacing-small / 1.5;
      font-size: 20px;
      font-family: $billsby-font-family;
      font-weight: $font-weight-bold;
      text-align: center;
      color: $billsby-black;
      letter-spacing: 0px;
      
      &-product {
        color: $billsby-black;
      }
    }    

    &__status {
      margin-top: $spacing-tiny;
    }
  }

  &-totalrevenue {
    display: flex;
    align-items: center;
    justify-content: center;
    & > *:first-child{
      margin-right: 5px;
    }
    .tooltip--blue {
      max-width: 246px;
    }

    i {
      color: $billsby-black;
    }
  }
}
