@import '../../../style/variables.scss';

.subscription-details-tab {
  width: 100%;

  &__content {
    padding-top: $spacing-default;
  }

  .tabbed-container {
    padding: 0px;
    box-shadow: inset 0px -10px 0px -8px rgb(216, 216, 216);
  }
}
