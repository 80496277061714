@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.update-add-on {
  &__title {
    &-panel {
      @include panel-bg-img('../../../images/embed_codes/sweet_shop.svg', right);
      background-size: 255px;
      background-position-y: -25px;
      background-position-x: 112%;

      &.edit {
        @include panel-bg-img('../../../images/embed_codes/sweet_shop.svg', right);
        background-size: 255px;
        background-position-y: -25px;
        background-position-x: 112%;
      }
    }

    &-text {
      width: 75%;
    }
  }

  &__summary, &__summary-loader {
    margin-top: $spacing-small;
  }

  &__label--disabled {
    opacity: 0.5;
  }

}