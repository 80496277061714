@import '../../../style/variables';

.cc-checkbox {
  margin: $spacing-small 0;
  display: flex;
  align-items: center;

  &-general {
    &__input {
      display: none;
    }

    &__icon {
      font-size: 21px;
      cursor: pointer;
      color: $billsby-light-gray;
    }

    &__input:checked + &__icon {
      color: $billsby-black;
    }

    &__label {
      font-size: 13px;
      font-weight: $font-weight-regular;
      margin: 0 0 0 $spacing-small;
      cursor: pointer;
    }

    &--margin-bottom {
      margin-bottom: $spacing-default;
    }
  }

  &--disabled {
    opacity: 0.5;

    * {
      cursor: not-allowed;
    }
  }

  &__fake-hidden {
    overflow: hidden;
    width: 0;
    height: 0;
  }
}
