@import '../../style/variables.scss';

.invoice-standalone-sidebar {
  .cc-text:not(.mb0) {
    margin-bottom: 5px;
  }

  @mixin outputPanel($backgroundColor) {
    background-color: $backgroundColor;
    .cc-text {
      color: $white;
    }
    & > *:first-child {
      font-size: $font-size-regular;
      font-weight: bold;
    }
  }

  &-unpaid, &-error {
    @include outputPanel($billsby-red);
  }

  &-paid {
    @include outputPanel($billsby-green);
  }

  &-pending {
    @include outputPanel($billsby-orange);
  }

  &-type2 {
    & > *:first-child {
      color: $billsby-orange;
      font-size: $font-size-regular;
      font-weight: bold;
    }
    .warning-replace-card {
      color: $billsby-red !important;
      &:before {
        position: relative;
        margin-right: $spacing-small;
        content: "\f608";
        color: $billsby-red !important;
        font-family: 'Font Awesome 5 Pro';
      }
    }
  }
  &-replace-card {
    .heading > label {
      color: $billsby-orange;
      font-size: 16px;
      font-weight: bold;
    }
  }
  
}