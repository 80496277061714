@import '../../../../style/variables';

.checkout-checkbox {
  margin: $spacing-small 0;
  display: flex;
  align-items: center;

  &-general {
    &__input {
      display: none;
    }

    &__icon:first-of-type {
      font-size: 1.35rem;
      cursor: pointer;
      margin: 0 10px 0 0;
      color: $billsby-orange;
    }

    &__input:checked + &__icon {
      color: $billsby-orange;
    }

    &__label {
      font-size: 13px;
      font-weight: $font-weight-regular;
      cursor: pointer;
    }

    &--margin-bottom {
      margin-bottom: $spacing-default;
    }
  }
}
