@import "../../../style/variables.scss";
@import "../../../style/mixin/panel-bg-img.scss";

.subscription-shipping-address {

  .switch__container input:not(:checked) + .switch__slider {
    background-color: $billsby-muted-gray;
  }

  &__panel {
    position: relative;

    .content-panel-title {
      margin-bottom: 25px;
    }

    &--stretched {
      height: 100%;
      margin-bottom: 0px;
    }
  }

  &__floating-button {
    position: absolute;
    top: $spacing-default;
    right: $spacing-default;
    height: auto;
    padding: 8px $spacing-small;
  }


  &__row {
    &--stretched {
      align-items: stretch;
    }
  }
}
