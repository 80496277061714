@import '../../../style/variables';


.collect-tax-reg-number {
  .switch {
    label {
      margin-right: 0px;
    }

    input {
      &:not(:checked) {
        & + .switch__slider {
          background-color: $billsby-muted-gray;
        }
      }
  
      &:checked {
        & + .switch__slider {
          background-color: $billsby-black;
        }
      }
    }
  }

}