@import "../../../../../style/variables.scss";
@import "../../../../../style/mixin/panel-bg-img.scss";
@import "../../../../../style/mixin/border-radius.scss";

$header-height: 60px;
$navigationbar-height: 28px;
$footer-height: 35px;

.email-preview {
  &__container {
    width: 100%;
  }

  &__content {
    width: 100%;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }

  &__cards-panel {
    margin-top: $spacing-default;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 71px;
    background-color: $billsby-super-light-gray;

    i {
      font-size: 36px;
      margin-right: $spacing-small;
      color: #606161;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: $billsby-black;
    padding: $spacing-default;
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &__logo {
      width: 74px;
    }
  }

  &__details {
    padding-top: $spacing-default;
    
    &__text a {
      display: inline;
    }

    &__text, &__text a {
      display: block;
      margin-bottom: $spacing-default;
      font-family: "Open sans";

      &-small {
        font-size: $font-size-tiny;
        color: $billsby-muted-gray;
        &.underlined {
          text-decoration: underline;
        }
      }

      &-title, &-title a {
        font-family: "Open sans";
        font-size: 20px;
      }
      
      &-title a {
        display: inline;
      }


      &-bold {
        font-family: "Open sans";
        font-weight: bold;
      }

      &-center {
        text-align: center;
      }

      &-underlined {
        text-decoration: underline;
      }

      &-no-margin {
        margin-bottom: 5px;
      }

      &.bar {
        position: relative;
        left: -15px;
        padding-left: $spacing-medium;
        &-red {
          border-left: 2px solid $billsby-red;
        }

        &-blue {
          border-left: 2px solid $billsby-black;
        }

        &-orange {
          border-left: 2px solid $billsby-orange;
        }

        &-green {
          border-left: 2px solid $billsby-green;
        }
      }
    }

    &__button {
      width: 100%;
      margin-top: $spacing-default;
      margin-bottom: $spacing-default;
      text-align: left;

      &.align-left {
        text-align: left;
      }

      &>button.arrow-icon :after {
        font-family: "Font Awesome 5 Pro";
        content: " \F061";
      }

      &-orange {
        background-color: $billsby-orange;
        justify-content: center;
        align-items: center;
        font-size: $font-size-medium-regular;
        font-family: "Roboto";
        color: #fff;
        min-width: 125px;
        min-height: 40px;
        border-radius: 5px;
      }

      span {
        font-weight: $font-weight-bold;
      }
    }

    .cc-checkbox > .cc-checkbox-general__icon {
      color: $billsby-orange;
    }

    &__note-panel {
      background-color: $billsby-super-light-gray;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-radius: 0px;
      box-shadow: none;
    }
  }

  &__terms {
    & .cc-text {
      display: inline;
      &.underlined {
        text-decoration: underline;
      }
    }
  }

  &__footer {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    padding: $spacing-default 0;

    i {
      margin-right: $spacing-tiny;
      font-size: 10px;
      opacity: 0.7;
    }

    &__text {
      font-size: 11px;
      margin-right: 5px;
      opacity: 0.7;
    }

    &__logo {
      height: 14px;
      margin-top: 2px;
    }
  }
}
