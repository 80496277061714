@import '../../../../style/variables.scss';
@import '../../../../style/mixin/breakpoint';

.checkout-input {
  display: flex;
  align-items: center;
  position: relative;

  &__icon {
    position: relative;
    left: 15px;
    top: 0px;
    color: $billsby-black;
    width: 0;
    font-size: $font-size-medium;
  }

  &__field {
    font-family: $billsby-font-family;
    flex: 1;
    outline: none;
    color: $billsby-black;
    padding: $spacing-tiny $spacing-tiny $spacing-tiny $spacing-tiny;
    border: solid 1px $billsby-black;
    background-color: $white;
    height: 30px;
    font-size: $font-size-small;
    max-width: 100%;
    
    &--icon {
      padding-left: $spacing-medium * 3;
    }

    &::placeholder{
      font-family: $billsby-font-family;
      font-size: $font-size-small;
      color: #c7c7c7;
    }
  }

  &__error {
    &-container {
      color: $billsby-red;
      position: absolute;
      top: 6px;
      right: 9px;

      &--warning {
        color: $billsby-orange;
      }
    }
    &-msg {
      font-size: $font-size-small;
    }
    &-icon {
      margin-right: $spacing-tiny;
      cursor: pointer;
    }
  }
  
  &--error {
    input {
      border-color: $billsby-red;
    }

    .checkout-input__icon {
      color: $billsby-red;
    }
  }
  &--warning {
    input {
      border-color: $billsby-orange;
    }

    .checkout-input__icon {
      color: $billsby-orange;
    }
  }
}