@import '../../../style/variables';
@import '../../../style/mixin/transition';

.loading_modal {
  position: relative;
  margin: 0 auto;
  outline: none;
  z-index: 99;
  background: transparent;
  opacity: 0;
  width: 420px;  
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  @include transition(all, 400ms, ease-in-out);

  &.ReactModal__Overlay {
    background-color: $modal-overlay !important;
    z-index: 99;
  }
  
  &.ReactModal__Content--after-open {
    opacity: 1;
    width: 420px;
  }

  &__header {
    display: flex;
    height: 60px;
    padding: $spacing-default;
    align-items: center;
    justify-content: space-between;
    color: $white;
    background-color: $billsby-black;

    & *:nth-child(1) {
      font-family: $billsby-font-family;
      text-transform: capitalize;
      color: $white;
      font-size: 17px;
    }

    & *:nth-child(2) {
      cursor: pointer;
      font-size: 17px;
      opacity: .6;
    }

    &__logo {
      margin: 0 auto;
      height: 27px;
    }
  }

  &__body {
    padding: $spacing-default;
    background-color: $billsby-super-light-gray;
    height: 100%;
    display: none;
    color: $billsby-black;


    &--visible {
      display: block;
    }

    &__title {
      font-weight: $font-weight-bold;
      text-align: center;
    }

    &__sub-title {
      text-align: center;
    }
    &__spinner {
      margin-top: $spacing-default;
      text-align: center;
    }
  }
}