@import '../../../style/variables';

.range-slider {
  margin: 100px 0 50px 0;
}

.rc-slider-tooltip {
  display: block;

  &-inner {
    padding: 12px;
    min-width: 182px;
    height: 50px;
    font-size: 30px;
    background-color: $billsby-black;
    border-radius: 4px;
    box-shadow: none;
  }
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: -15px;
  left: 50%;
  margin-left: -15px;
  border-width: 15px 15px 0;
  border-top-color: $billsby-black;
}

.rc-slider-rail {
  background-color: $billsby-grey;
}

.rc-slider-track { 
  background-color: $billsby-orange;
}

.rc-slider-dot-active,
.rc-slider-handle-click-focused:focus {
  border-color: $billsby-orange;
}

.rc-slider-handle:focus {
  box-shadow: none;
}

.rc-slider-dot {
  bottom: -3px;
  width: 10px;
  height: 10px;
}

.rc-slider-handle {
  top: -20% !important;
  width: 30px;
  height: 30px;
  background-color: $billsby-black;
}

.rc-slider-handle:hover,
.rc-slider-handle:focus,
.rc-slider-handle:active,
.rc-slider-handle {
  border-color: $billsby-black;
}

.rc-slider-handle::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f053";
  color: $white;
  top: 5px;
  position: relative;
  left: 4px;
}

.rc-slider-handle::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f054";
  color: $white;
  top: 5px;
  position: relative;
  left: 8px;
}

.rc-slider-mark {
  font-size: $font-size-medium;
  top: 25px;

  &-text,
  &-text-active {
    color: $billsby-black;
  }
}

.rc-slider-tooltip-content {
  position: relative;
  bottom: 10px;
}
