@import '../../style/variables';
@import '../../style/mixin/panel-bg-img.scss';

.teammates {

  &__title {
    &-panel {
      @include panel-bg-img("../../images/teammates.svg", right);
      background-size: 222px;
      background-position-x: 108%;
      background-position-y: center;
    }

    &-text {
      width: 75%;
    }
  }


  &__existing-teammates-table {
    margin-bottom: 0px;

    thead,
    tbody {

      th,
      td {

        &:nth-child(1) {
          flex: 1 1 10%;
        }

        &:nth-child(2) {
          flex: 1 1 10%;
        }

        &:nth-child(3) {
          flex: 1 1 2%;
        }

        &:nth-child(4) {
          flex: 1 1 1%;
        }

        &:nth-child(5) {
          flex: 1 1 2%;
        }

        i {
          font-size: $font-size-large;
          cursor: pointer;
        }
      }
    }

  }

  &__action-buttons-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      &.button {
        font-size: 11px;
        padding: 3px 0px;
        height: 24px;
        min-width: 136px;
        
        letter-spacing: 0px;

        &:last-of-type {
          margin-left: $spacing-medium;
        }

        .spinner {
          &>div {
            background-color: $billsby-black;
          }
        }

        &.btn {
          &-success {
            color: $white;
            background-color: $billsby-green;
            border: 1px solid $billsby-green;
          }

          &-add {
            background-color: $white;

            &:hover {
              background-color: $billsby-black;
              color: $white;

              .spinner {
                &>div {
                  background-color: $white;
                }
              }
            }
          }

          &-error {
            background-color: $white;

            &.revoked {
              background-color: $billsby-red;
              color: $white !important;
            }

            &.active,
            &:hover {
              color: $white;
              background-color: $billsby-red;

              &:focus {
                color: $white;
              }

              .spinner {
                &>div {
                  background-color: $white;
                }
              }
            }

            &:focus {
              color: $billsby-red;
            }

            .spinner {
              &>div {
                background-color: $billsby-red;
              }
            }
          }
        }
      }
    }
  }
}

.modal {
  &__body {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
}