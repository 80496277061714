@import '../../../../style/variables.scss';
.quick-books-online-integration {
  &__form-item {
    padding-top: $spacing-small;
  }

  &__settings-panel-error > div > div.step-cpanel__title > div.step-cpanel__title__text > div.cc-text {
    color: $billsby-orange;
  }

  &__form-label {
    margin-top: $spacing-small;
    & > label {
      font-size: $font-size-medium-regular;
    }

    &.error {
      color: $billsby-orange;
    }
  }

  &__display-inline {
    display: inline;
  }

  &__error {
    font-size: 15px;
    margin-right: 5px;
    color: $billsby-red;
  }
}