@import '../../../style/variables.scss';
@import '../../../style/mixin/panel-bg-img.scss';

.update-allowance {
  &__heading {
    @include panel-bg-img('../../../images/allowances/allowances.svg', right);
    background-size: 255px;
    background-position-y: -37px;
    background-position-x: 110%;
  }
}