@import '../../style/variables.scss';
@import '../../style/mixin/scrollbar.scss';
@import '../../style/mixin/panel-bg-img.scss';

.navbar {
  height: 100%;

  &__link-icon {
    margin-right: $spacing-tiny;
    &.error {
      color: $billsby-red !important;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    background-color: $billsby-black;
    padding: $spacing-default;
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    justify-content: space-between;

    img {
      width: 91px;
      height: 24px;
      //cursor: pointer;
    }

    i {
      cursor: pointer;
      font-size: $font-size-large;
      align-self: center;
      color: $nav-btn;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px 10px 0px 10px;

    &-body {
      margin-top: 64px; 
      overflow-y: auto;
      height: 100%;
      @include scrollbar();
      
      &__item {
        margin: 0 0 $spacing-tiny 0;
        min-height: 27px;
        width: 100%;
        
        &__error {
          color: $billsby-red !important;
          margin: 0 !important;
        }

        &--minimised {
          width: auto;
        }
        
        &--locked {
          * {
            cursor: default !important;
          }
          opacity: .2;
        }

        a {
          text-decoration: none;
          font-size: $font-size-medium-regular;
          color: $billsby-black;
          display: inline-block;
        }

        &-line {
          padding: 0 $spacing-small $spacing-tiny $spacing-small;
          width: 100%;
          border: none;
          background-color: transparent;
          text-align: left;

          &:hover {
            background-color: $billsby-super-light-gray;
            border-radius: 4px;
            cursor: pointer;
          }

          i {
            color: $billsby-black;
            width: 20px;
            height: 15px;
            margin: 0 $spacing-tiny 0 0;
            text-align: center;
            font-size: $font-size-medium-regular;
            padding-top: 6px;
          }
        }

        &-name {
          font-size: $font-size-medium-regular;
          cursor: pointer;
          padding: $spacing-tiny;
          display: inline;
          // font-weight: $font-weight-regular;
          font-family: $billsby-font-family;

          &.error {
            padding-left: 0px !important;
          }
        }

        &-arrow {
          position: relative;
          width: 14px;
          height: 15px;
          float: right;
          margin-top: 6px;

          &::before,
          &::after {
            display: block;
            position: absolute;
            top: 50%;
            width: 10px;
            height: 2px;
            background-color: currentColor;
            content: '';
            transition: transform 0.25s ease;
          }

          &::before {     
            left: 6px;
            transform: rotate(-45deg);
          }

          &::after {     
            right: 4px;
            transform: rotate(45deg);
          }

          [aria-expanded='true'] &::before {
            transform: rotate(45deg);
          }

          [aria-expanded='true'] &::after {
            transform: rotate(-45deg);
          }
        }          

        &-links {
          margin-top: $spacing-tiny;

          a {
            display: block;
            margin: 0;
            text-decoration: none;
            padding: $spacing-tiny;
            padding-left: 40px;
            color: $billsby-black;
            font-weight: $font-weight-regular; 
            font-size: $font-size-medium;
          }

          a:hover {
            background-color: $billsby-super-light-gray;
            border-radius: 4px;
          }

          &--hidden {
            opacity: 0;
            display: none;
          }
        }

        a.link-locked {
          opacity: .2;
          cursor: default;
        }

        a.link-active, a.link-active i {
          color: $billsby-orange;
          font-weight: $font-weight-medium !important;
        }
      }
    }



    &-footer {
      border-top: 1px solid $billsby-black;
      padding-top: $spacing-default;
      margin-top: auto;
      position: relative;

      .navbar__content-body__item {
        &-dots {
          float: right;
        }
        &--margin {
          margin-bottom: $spacing-default;
        }
      }
      .__react_component_tooltip {
        left: 260px !important;
        width: 230px;
        margin-top: 0px;
        margin-left: 0px;
        position: fixed;

        &:before,
        &:after {
          content: none;
        }
      }

      &__tip {

        li {
          display: flex;
          color: $billsby-black;
          
          button {
            border: none;
            padding: 0;
            background-color: transparent;
            text-decoration: none;
            font-size: 14px;
            cursor: pointer;
          }

          i {
            margin: 0 $spacing-small 0 0;
            text-align: center;
            width: 17px;
            font-size: 14px;
          }
        }

        li:hover {
          color: $billsby-orange;

          i {
            color: $billsby-orange;
          }

          button {
            color: $billsby-orange;
          }
        }
        
        &__sub {
          text-transform: uppercase;
          color: $billsby-black;
          margin-top: $spacing-default;
          font-size: 11px;
          letter-spacing: $letter-spacing-medium;
        }

        &-plaintext {
          color: $billsby-black;
          
          i {
            margin: 0 $spacing-small 0 0;
            text-align: center;
            width: 17px;
            color: $billsby-black;
          }

          &-code {
            font-weight: $font-weight-regular;
          }
        }
      }
    }

    &-hr {
      border: 0;
      border-top: 1px solid $billsby-black;
    }
  }

  &--minimised {
    .navbar {
      &__logo {
        width: 78px;
        padding-bottom: $spacing-small;

        img {
          height: 22px;
          width: 22px;
        }

        i {
          font-size: 20px;
        }
      }
      
      &__content {
        align-items: center;
        
        &-body {
          margin-top: 50px;

          .__react_component_tooltip {
            background-color: $billsby-black;
            left: 80px !important;
            top: initial;
            width: 250px;

            &::before,
            &::after {
              content: none;
            }

            ul li {
              margin: 0;
              color: $white;
            }

            ul li:hover {
              border-radius: 4px; 
              background-color: $nav-tip-hover;
            }

            li a {
              display: flex;
              padding: $spacing-tiny;
              font-size: inherit;
              color: inherit;
              text-decoration: none;
            }

            li a.link-active {
              color: $billsby-orange;
              font-weight: $font-weight-medium !important;
            }
          }

          &__item {
            margin-top: 0; //$spacing-tiny;
            margin-bottom: $spacing-small;
            
            i {
              color: $white;
              font-size: 20px;
              cursor: pointer;
              width: 30px;
              text-align: center;
              margin: 0;
            }

            i:hover {
              color: $billsby-orange;
            }

            i.link-active {
              color: $billsby-orange;
              font-weight: $font-weight-medium !important;
            }

            &-line {
              margin: 0;
              padding: 0;

              &:hover {
                background-color: $billsby-black;
              }
            }

            &-name,
            &-links,
            &-arrow,
            &-dots {
              display: none;
            }
          }
        }

        &-footer {
          .__react_component_tooltip {
            left: 90px !important;            
          }

          .navbar__content-body__item {
            &-name {
              display: none;
            }
          }
        }
      }
    }
  }

  &__currency-mismtached-banner {
    background-color: $billsby-red;
    margin: $spacing-medium $spacing-tiny 0 $spacing-tiny;

    .cc-text {
      color: $white;
      font-size: $font-size-medium-regular;
    }

    button#fix-mismatched {
      background-color: $white;
      color: $billsby-red
    }
  }

  #golive-btn {
    &.golive-btn--active {
      background-color: $billsby-orange;
    }
  }

  &__golive-banner {
    @include panel-bg-img("../../images/billsby-rocket.svg");
    margin: $spacing-default 5px;
    background-position-x: 100px;


    &__title.cc-text {
      font-size: $font-size-medium-regular;
      color: $billsby-orange;
      font-weight: 500;
      max-width: 150px;
      margin-bottom: $spacing-small;
    }
  }

  &__trial-banner {
    @include panel-bg-img("../../images/giving-woman-presents-2.svg", right);
    background-position-x: 108px;
    margin: $spacing-default 5px;

    .remaining-tooltip-trigger {
      cursor: pointer;
    }

    .cc-text {
      font-size: $font-size-medium-regular;
      font-weight: 500;
      max-width: 140px;
      margin-bottom: $spacing-tiny;
    }

    .remaining {
      color: $billsby-orange;
      font-weight: bold;
    }

    i {
      font-weight: 500;
      cursor: pointer;
    }
  }
}

/* ---------------- Animations ------------------ */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}