@import '../../../../../style/variables.scss';

.support-contact-details {

  &__heading {
    position: relative;

    .content-panel-title {
      margin-bottom: 25px;
    }

  }

  &__title {
    margin-bottom: 25px;
  }

  &__floating-button {
    position: absolute;
    top: $spacing-default;
    right: $spacing-default;
  }

  .file-upload__logo {
    background-color: $white;
    border-width: 1px;
    border-style: dotted;

    &::before {
      content: "\F1C5";
      color: $billsby-black;
    }
  }
  

  .switch__container input:not(:checked) + .switch__slider {
    background-color: $billsby-muted-gray;
  }

}
